import * as actionTypes from "./actionTypes";

export const getDataTeacher = (dataTeacher, dataSchool) => {
  return {
    type: actionTypes.TEACHER_SUCCESS_LOGIN,
    dataTeacher: dataTeacher,
    dataSchool: dataSchool,
    // dataSchool: {
    //   api_url: "https://demo.myhomeschool.co.id",
    //   label: "Sekolah Apri Unity",
    //   value: 1,
    // },
  };
};

export const fetchTeacherScheduleSuccess = (listSchedule) => {
  return {
    type: actionTypes.TEACHER_SCHEDULE_SUCCESS,
    listSchedule: listSchedule,
  };
};

export const fetchTeacherFailure = (error) => {
  return {
    type: actionTypes.TEACHER_SCHEDULE_FAILURE,
    payload: error,
  };
};

export const userLogout = () => {
  return {
    type: actionTypes.USER_LOGOUT,
  };
};

export const makeCaseBySchedule = (
  courseName,
  typeCase,
  scheduleId,
  courseId,
  schoolId,
  className,
  date,
  time,
  userId,
  isRemedial = 0
) => {
  return {
    type: actionTypes.TEACHER_MAKE_CASE_BY_SCHEDULE,
    courseName: courseName,
    typeCase: typeCase,
    scheduleId: scheduleId,
    courseId: courseId,
    schoolId: schoolId,
    className: className,
    date: date,
    time: time,
    userId: userId,
    isRemedial: isRemedial,
  };
};

export const deleteScheduleActive = () => {
  return {
    type: actionTypes.TEACHER_REMOVE_SCHEDULE_SELECTED,
  };
};
export const makeNewCase = (userId, schoolId) => {
  return {
    type: actionTypes.TEACHER_MAKE_NEW_CASE,
    schoolId: schoolId,
    userId: userId,
  };
};

export const loadCaseWillEdit = (cases, case_parts) => {
  return {
    type: actionTypes.LOAD_CASE_EDIT,
    cases: cases,
    case_parts: case_parts,
  };
};

export const editNameCase = (text) => {
  return {
    type: actionTypes.SET_CASE_NAME,
    text: text,
  };
};

export const changeStatusEdit = (status) => {
  return {
    type: actionTypes.EDIT_CASE_FINISHED,
    status: status,
  };
};

export const setInfoExam = (courseName, className, caseName, dateSchedule) => {
  return {
    type: actionTypes.TEACHER_SET_INFO_EXAM,
    courseName: courseName,
    className: className,
    caseName: caseName,
    dateSchedule: dateSchedule,
  };
};

export const getStudentAnswer = (studentAnswer) => {
  return {
    type: actionTypes.TEACHER_GET_STUDENT_ANSWER,
    payload: studentAnswer,
  };
};

export const removeStudentAnswer = () => {
  return {
    type: actionTypes.TEACHER_REMOVE_STUDENT_ANSWER,
  };
};

export const removeExamInfo = () => {
  return {
    type: actionTypes.TEACHER_REMOVE_EXAM_INFO,
  };
};

export const setScoreAnswer = (indexPart, indexQues, newScore) => {
  console.log("indexPart, indexQues, newScore", indexPart, indexQues, newScore);
  return {
    type: actionTypes.TEACHER_SET_SCORE_ESSAY,
    indexPart: indexPart,
    indexQues: indexQues,
    newScore: newScore,
  };
};
export const setScoreAnswerShort = (indexPart, indexQues, newScore) => {
  console.log("indexPart, indexQues, newScore", indexPart, indexQues, newScore);
  return {
    type: actionTypes.TEACHER_SET_SCORE_ESSAY_SHORT,
    indexPart: indexPart,
    indexQues: indexQues,
    newScore: newScore,
  };
};

export const showImgAssessment = (indexPart, indexQues, lastStatus) => {
  return {
    type: actionTypes.TEACHER_SHOW_IMG_ASSESSMENT,
    indexPart: indexPart,
    indexQues: indexQues,
    lastStatus: lastStatus,
  };
};
