import React from "react";

function changeDate(tgl) {
	var date = new Date(tgl);
	var day = date.getDate();
	var month = date.getMonth();
	var year = date.getFullYear();
	var listMonth = [
		"Januari",
		"Februari",
		"Maret",
		"April",
		"Mei",
		"Juni",
		"Juli",
		"Agustus",
		"September",
		"Oktober",
		"November",
		"Desember",
	];
	return day + " " + listMonth[month] + " " + year;
}
function Cell(props) {
	return (
		<tr>
			<td style={{overflow: "hidden", maxWidth: "23vw", textOverflow: "ellipsis"}}>{props.case.name}</td>
			<td>{changeDate(props.case.updated_at)}</td>
			<td>{props.case.teacher.username}</td>
			<td>
				{props.cases === null ? (
					<button
						className={
							props.btnSelected === props.index
								? "btn btn-sm btn-gradient-success"
								: "btn btn-sm btn-gradient-primary"
						}
						onClick={props.onClickHandler}
					>
						{props.btnSelected === props.index ? "Terpilih" : "Pilih"}
					</button>
				) : (
					<button
						className={
							props.cases.id === props.case.id
								? "btn btn-sm btn-gradient-dark"
								: props.btnSelected === props.index
								? "btn btn-sm btn-gradient-success"
								: "btn btn-sm btn-gradient-primary"
						}
						onClick={props.onClickHandler}
						disabled={props.cases.id === props.case.id}
					>
						{props.btnSelected === props.index ||
						props.cases.id === props.case.id
							? "Terpilih"
							: "Pilih"}
					</button>
				)}
			</td>
		</tr>
	);
}

export default Cell;
