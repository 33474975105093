import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import "../../../assets/css/Modal.css";
import Cell from "./CaseModal.jsx";
import Spinner from "../../../assets/Spinner.gif";
import Ball from "../../../assets/Ball.svg";
import axiosInstance from "../../../store/services/AxiosService";
import SweetAlert from "react-bootstrap-sweetalert";
import { connect } from "react-redux";
import { changeStatusEdit } from "../../../store/actions/index";
import moment from "moment";

const delay = () => new Promise((res) => setTimeout(res, 2000));


export class AccessExamModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			btnSelected: null,
			itemAccess: [],
			fetching: false,
			caseSelected: "",
			alertSuccess: null,
			taskDone: false,
		};
	}

	componentDidMount = () => {
		// if (prevProps.idCase != this.props.idCase) {
			this.fetchCase(this.props.idCase, this.props.apiScheduleId, this.props.schoolId);
		// }
	};

	fetchCase = (idCase, apiScheduleId, idSchool) => {
		this.setState({ fetching: true });
		delay();
		axiosInstance
			.post(`/access/getAll`, {
                "school_id": idSchool,
                "case_id": idCase,
                "api_schedule_id": apiScheduleId
            })
			.then((res) => {
				this.setState({
					itemAccess: res.data,
					fetching: false,
				});
			})
			.catch((error) => {
				this.setState({
					fetching: false,
				});
			});
	};

	onClickHandler(index) {
		this.setState({ btnSelected: index, caseSelected: index });
	}

	addBtn = () => {
		let btn_class_color = this.state.btnCliked ? "btn btn-sm btn-success" : "btn btn-sm btn-primary";
		return btn_class_color;
	};

	btnCanceled = () => {
		this.setState({
			btnSelected: null,
		});
		this.props.onHide();
	};

    btnGenerateAll = (idschedule, idschool, idcase) => {
		axiosInstance
			.post(`/access/generateAll`, {
                "school_id": idschool,
                "api_schedule_id": idschedule,
                "case_id": idcase
            })
			.then((res) => {
				this.handleSuccessSubmit();
				console.log(res.data);
			})
			.catch((error) => {
				this.handleFailureSubmit(error.message);
			});
	};


	btnDeleteAll = (idschedule, idschool, idcase) => {
		axiosInstance
			.post(`/access/deleteAll`, {
                "school_id": idschool,
                "api_schedule_id": idschedule,
                "case_id": idcase
            })
			.then((res) => {
				this.handleSuccessSubmit();
				console.log(res.data);
			})
			.catch((error) => {
				this.handleFailureSubmit(error.message);
			});
	};

	handleSuccessSubmit() {
		this.props.changestatusedit(true);
		const alertSuccess = () => (
			<SweetAlert success title="Berhasil!" onConfirm={() => this.hideAlertSuccess()} onCancel={() => this.setState({ alertSuccess: null })}>
				Akses Password berhasil dipilih
			</SweetAlert>
		);
		this.setState({
			alertSuccess: alertSuccess(),
		});
	}

	handleFailureSubmit(errorMessage) {
		const alertFailure = () => (
			<SweetAlert warning title="Proses Gagal!" onConfirm={() => this.hideAlertSuccess()} onCancel={() => this.setState({ alertSuccess: null })}>
				{errorMessage}
			</SweetAlert>
		);
		this.setState({
			alertSuccess: alertFailure(),
		});
	}

	hideAlertSuccess() {
		this.setState({
			alertSuccess: null,
			taskDone: true,
		});
        setTimeout(() => {
            // window.location.reload();
			this.fetchCase(this.props.idCase, this.props.apiScheduleId, this.props.schoolId);
        }, 2000);
		this.props.onHide();
	}

	render() {
		return (
			<Modal show={this.props.show} onHide={this.props.onHide} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
				<Modal.Header closeButton></Modal.Header>
				{this.state.fetching === true ? (
					<div className="text-center">
						<img src={Ball} alt=""></img>
					</div>
				) : (
					<Modal.Body>
						{this.state.itemAccess.length > 0 ? (
							<div className="col-lg-12 grid-margin stretch-card ">
								<div className="card custom-card-shadow">
									<div className="card-body">
										<h2 className="card-title">Daftar Password Akses Ujian Peserta</h2>
										<p className="card-description">
											Silakan generate ulang password dengan tombol <b>Generate</b> di bagian bawah.
										</p>
										<div className="table-responsive ">
											<table id="tabelSoal" className="table table-hover text-center modal-list-case">
												<thead>
													<tr>
														<th scope="col">Nama Siswa</th>
														<th scope="col">Status</th>
														<th scope="col">Password</th>
                                                        <th scope="col">Update Terakhir</th>
													</tr>
												</thead>
												<tbody className="tableBody">
													{this.state.itemAccess.map((itemAccesss, index) => (
                                                        <tr key={index}>
                                                            <td>{itemAccesss.student_name}</td>
                                                            <td>{itemAccesss.is_locked ? "Dikunci" : "Tidak Dikunci"}</td>
                                                            <td>{itemAccesss.password}</td>
                                                            <td>{moment(itemAccesss.updated_at).format("DD MMM YYYY HH:mm:ss")}</td>
                                                        </tr>
													))}
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						) : (
							<h4 className="text-center">Data Peserta Ujian Tidak Ditemukan ...</h4>
						)}
					</Modal.Body>
				)}

				<Modal.Footer>
					<Button onClick={this.btnCanceled}>Batal</Button>
					<Button onClick={() => this.btnGenerateAll(this.props.apiScheduleId, this.props.schoolId, this.props.idCase)} disabled={this.state.itemAccess.length < 1}>Generate</Button>
					<Button onClick={() => this.btnDeleteAll(this.props.apiScheduleId, this.props.schoolId, this.props.idCase)} disabled={this.state.itemAccess.filter(e => e.updated_at !== null) < 1}>Lepas Kunci</Button>
					{this.state.alertSuccess}
				</Modal.Footer>
			</Modal>
		);
	}
}
const mapStateToProps = (state) => {
	return {
		schoolId: state.User.dataSchool.value,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		changestatusedit: (status) => dispatch(changeStatusEdit(status)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AccessExamModal);
