import React from "react";
import { connect } from "react-redux";
import {
    inputAnswerShort,
    editCorrectAnswerShort,
    addAnswerShort,
    deleteAnswerShort,
    addImageAns,
    addToListImg,
    removeImageAnswer,
    addImageFileAns,
} from "../../../store/actions/index";
import "react-medium-image-zoom/dist/styles.css";
import axiosInstance from "../../../store/services/AxiosService";
import imageCompression from "browser-image-compression";

const baseImgURL = process.env.REACT_APP_BACKEND_IMAGES2;
class AnswerShort extends React.Component {
    getIndex = (key, stateData) => {
        var index = stateData
            .map(function (item) {
                return item.key;
            })
            .indexOf(key);
        return index;
    };
    handleUpdateAnswer = (keyPart, keyQues, keyAns, data, text) => {
        let indexAns = this.getIndex(keyAns, data);
        // let text = event.target.value;
        this.props.inputAnswerShort(keyPart, keyQues, indexAns, text);
    };

    handleIconAddImg = (indexPart, indexQues, indexAns) => {
        this.setState({ imgToPost: null });
        this.props.addImageAns(indexPart, indexQues, indexAns, null);
    };

    handleUpdateCorrectAnswer = (keyPart, keyQues, keyAns, item) => {
        let indexOld = item.indexOf(item.find((indOld) => indOld.is_correct === 1));
        let index = this.getIndex(keyAns, item);
        this.props.editCorrectAnswerShort(keyPart, keyQues, index, indexOld);
    };

    async handleUploadImg(e, indexPart, indexQues, indexAns, oldFile, fileNew) {
        e.preventDefault();
        if (fileNew !== null || fileNew !== undefined) {
            this.props.addImageAns(indexPart, indexQues, indexAns, "Loading");
            const options = {
                maxSizeMB: 1,
                maxWidthOrHeight: 720,
                useWebWorker: true,
            };
            this.props.addToListImg(oldFile);
            const compressedFile = await imageCompression(fileNew, options);
            var imgCompressed = new File([compressedFile], "uploaded_file.jpg", {
                type: "image/jpeg",
                lastModified: Date.now(),
            });
            let data = new FormData();
            data.append("file_attached", imgCompressed);
            data.append("old_file", null);
            const url = "/files/upload";
            const config = {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            };
            axiosInstance
                .post(url, data, config)
                .then((r) => {
                    this.props.addImageAns(indexPart, indexQues, indexAns, r.data.file);
                    this.props.addImageFileAns(indexPart, indexQues, indexAns, null);
                    this.props.addToListImg(r.data.file);
                })
                .catch((error) => {
                    alert(`Proses upload gagal`);
                });
        }
    }

    async handleDeleteImage(e, fileName, indexPart, indexQues, indexAns) {
        e.preventDefault();
        if (fileName !== null) {
            const data = { file_name: fileName };
            const url = "/files/delete";
            axiosInstance
                .post(url, data)
                .then((r) => {
                    this.props.removeImageAnswer(indexPart, indexQues, indexAns);
                })
                .catch((error) => {
                    alert(`Proses delete gagal => ${error.message}`);
                });
        } else {
            this.props.removeImage(indexPart, indexQues, indexAns);
        }
    }

    onDrop(pictureFiles, pictureDataURLs, indexPart, indexQues, indexAns) {
        this.props.addImageFileAns(indexPart, indexQues, indexAns, pictureFiles[0]);
    }

    render() {
        return (
            <div>
                {this.props.member.map((item, index) => (
                    <div key={index + 1}>
                        <div className="row mb-2">
                            <div className="col-md-1 ">
                                <div className="float-right">
                                    <div className="form-check form-check-primary ">
                                        <input
                                            type="radio"
                                            className="form-check d-none"
                                            name={
                                                "optionsAnswer" + this.props.xPart + this.props.xQues
                                            }
                                            id={
                                                "optionsAnswer" +
                                                this.props.xPart +
                                                this.props.xQues +
                                                item.key
                                            }
                                            value={item.value}
                                            onChange={() =>
                                                this.handleUpdateCorrectAnswer(
                                                    this.props.xPart,
                                                    this.props.xQues,
                                                    item.key,
                                                    this.props.member
                                                )
                                            }
                                            checked={item.is_correct === 1 ? true : false}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-7">
                                {/* <CKEditor
									id={"choiceInput" + item.id}
									editor={ClassicEditor}
									data={
										item.value === null ? undefined : `<div>${item.value}</div>`
									}
									config={config2}
									onBlur={(event, editor) => {
										const data = editor.getData();
										this.handleUpdateAnswer(
											this.props.xPart,
											this.props.xQues,
											item.key,
											this.props.member,
											data
										);
									}}
									required
								/> */}

                                <input id={"choiceInput" + item.id} className="form-control form-control-isian-singkat" value={item.value === null ? "" : item.value} onChange={(e) => this.handleUpdateAnswer(
                                    this.props.xPart,
                                    this.props.xQues,
                                    item.key,
                                    this.props.member,
                                    e.target.value
                                )} required />


                            </div>
                        </div>
                    </div>
                ))}
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        doingEditCase: state.Exam.doingEditCase,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        inputAnswerShort: (xPart, xQues, xAns, newValue) =>
            dispatch(inputAnswerShort(xPart, xQues, xAns, newValue)),
        editCorrectAnswerShort: (xPart, xQues, xAns, xAnsOld) =>
            dispatch(editCorrectAnswerShort(xPart, xQues, xAns, xAnsOld)),
        addAnswerShort: (index, indexQues, indexAns) =>
            dispatch(addAnswerShort(index, indexQues, indexAns)),
        deleteAnswerShort: (index, indexQues, indexAns) =>
            dispatch(deleteAnswerShort(index, indexQues, indexAns)),
        addToListImg: (imageName) => dispatch(addToListImg(imageName)),
        addImageAns: (index, indexQues, indexAns, image) =>
            dispatch(addImageAns(index, indexQues, indexAns, image)),
        removeImageAnswer: (index, indexQues, indexAns) =>
            dispatch(removeImageAnswer(index, indexQues, indexAns)),
        addImageFileAns: (index, indexQues, indChoice, imageFile) =>
            dispatch(addImageFileAns(index, indexQues, indChoice, imageFile)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AnswerShort);
