import React, { Component } from "react";
import { connect } from "react-redux";
import { whenTimerEnds, alertTimer } from "../../../store/actions/index";
import moment from "moment";
import Spinner from "../../../assets/Spinner.gif";
import Ball from "../../../assets/Ball.svg";
import axiosInstance from "../../../store/services/AxiosService";

class Timer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			hours: null,
			minutes: null,
			seconds: null,
		};
		this.whenTimesUp = this.whenTimesUp.bind(this);
	}

	componentDidMount() {
		axiosInstance.get(`/timeserver`).then((res) => {
			var dateNow = moment(res.data.server_time, "YYYY-MM-DD HH:mm:ss");
			var dateEnd = moment(this.props.Timer, "DD-MM-YYYY HH:mm:ss");
			var durasi = dateEnd - dateNow;

			this.myInterval = setInterval(() => {
				if (durasi > 0) {
					durasi = durasi - 1000;
					var hours = Math.floor((durasi % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
					var minutes = Math.floor((durasi % (1000 * 60 * 60)) / (1000 * 60));
					var seconds = Math.floor((durasi % (1000 * 60)) / 1000);
					this.setState({
						hours: hours,
						minutes: minutes,
						seconds: seconds,
					});
					if (hours === 0 && minutes === 3 && seconds === 0) {
						this.props.alertTimer();
					}
				} else {
					clearInterval(this.myInterval);
					this.setState({
						hours: 0,
						minutes: 0,
						seconds: 0,
					});
					this.props.whenTimerEnds();
				}
			}, 1000);
		});
	}

	componentWillUnmount() {
		clearInterval(this.myInterval);
	}

	whenTimesUp() {
		return <h3>Selesai!</h3>;
	}
	render() {
		const { hours, minutes, seconds } = this.state;
		return (
			<div>
				{seconds === null ? (
					<div>
						<img src={Ball} alt="" width="50" />
					</div>
				) : hours === 0 && minutes === 0 && seconds === 0 ? (
					<h3>Times Up !!!</h3>
				) : (
					<div>
						{hours === 0 && minutes === 0 && seconds < 30 ? (
							<h4 className="animate__animated animate__pulse animate__infinite infinite">
								Sisa Waktu:{hours}:{minutes < 10 ? `0${minutes}` : minutes}:{seconds < 10 ? `0${seconds}` : seconds}
							</h4>
						) : (
							<h4 className="animate__animated animate__bounceInLeft">
								Sisa Waktu: {hours}:{minutes < 10 ? `0${minutes}` : minutes}:{seconds < 10 ? `0${seconds}` : seconds}
							</h4>
						)}
					</div>
				)}
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		Timer: state.User.timer.dateEnd,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		whenTimerEnds: () => dispatch(whenTimerEnds()),
		alertTimer: () => dispatch(alertTimer()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Timer);
