import React from "react";
// import Select from "react-select";
import AsyncSelect from "react-select";

class SelectSchool extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedOption: null,
            options: [],       
        }
    }

	componentDidMount() {
		let option = [];
		Object.values(this.props.listSchoolByDegree).map((item) => {
			return option.push({
				label: item.api_name,
				value: item.id,
				api_url: item.api_url,
			});
		});
		this.setState({
			options: option,
		});
	}

    componentWillReceiveProps(nextProps) {
        let option = [];
		Object.values(nextProps.listSchoolByDegree).map((item) => {
			return option.push({
				label: item.api_name,
				value: item.id,
				api_url: item.api_url,
			});
		});
        if (!this.compareTwoObjects(this.state.options, option)) {
            this.setState({
                selectedOption: null,
                options: option,
            });
        }
    }

    compareTwoObjects(data1, data2) {
        return Object.keys(data1).length === Object.keys(data2).length 
        && Object.keys(data1).every(p => data1[p]['value'] === data2[p]['value']);
    }

	handleChange = (selectedOption) => {
		this.setState({ selectedOption: selectedOption });
		this.props.onSelectSchool(selectedOption);
	};

	filterSchool = (inputValue) => {
		return this.state.options.filter((i) => i.label.toLowerCase().includes(inputValue.toLowerCase()));
	};

	loadOptions = (inputValue, callback) => {
        var intervalSchoolFetch = setInterval(() => {
            if (this.state.options.length === 0) return;
            setTimeout(() => {
                callback(this.filterSchool(inputValue));
            }, 500);
            clearInterval(intervalSchoolFetch);
            intervalSchoolFetch = null;
        }, 500)
	};
	render() {
        const { selectedOption, options } = this.state;
		return <AsyncSelect value={selectedOption} onChange={this.handleChange} options={options} defaultOptions loadOptions={this.loadOptions} isLoading={selectedOption === null} placeholder="Pilih Sekolah" />;
	}
}

export default SelectSchool;
