import React, {Fragment} from "react";
import Footer from "../../footer";
import {Prompt, Redirect} from "react-router-dom";
import Question from "./QuestionsEditExam.jsx";
import {connect} from "react-redux";
import Spinner from "../../../assets/Spinner.gif";
import Ball from "../../../assets/Ball.svg";
import SweetAlert from "react-bootstrap-sweetalert";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
import axiosInstance from "../../../store/services/AxiosService";

import {loadCaseWillEdit, editNameCase, setNameCasePart, setScoreWeight, editRandomQuestion, editRandomAnswer, handleSelectTypeCasePilgan, handleSelectTypeCaseEssay, addCasePart, deleteItem, changeStatusEdit,editCaseSensitife,handleSelectTypeCaseMenjodohkan} from "../../../store/actions/index";

class EditCase extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			alertSubmit: null,
			taskDone: false,
            caseStillEmpty: false,
		};
	}
	fetchDataCase = () => {
		axiosInstance.get(`/cases/${this.props.match.params.case}?type_user=teacher`).then((res) => {
			const data = res.data;
			const case_parts = Object.values(data.case_parts).map((element, index) => {
				return {
					...element,
					key: index + 1,
					case_questions: Object.values(element.case_questions).map((item, index) => {
						return {
							...item,
							key: index + 1,
							status_img: item.file_attached === null ? 0 : 1,

							case_choices: Object.values(item.case_choices).map((member, index) => {
								return {
									...member,
									key: index + 1,
									status_img: member.file_attached === null ? 0 : 1,
								};
							}),
						};
					}),
				};
			});

			const cases = {
				id: data.id,
				name: data.name,
				school_id: data.school_id,
				course_id: data.api_course_id,
				teacher_id: data.api_teacher_id,
			};
			this.props.loadCaseWillEdit(cases, case_parts);
			localStorage.setItem("caseWillEdit", JSON.stringify(res.data));
		});
	};

	async componentDidMount () {

		// const fromLocalStorage = JSON.parse(localStorage.getItem("reduxState"));
		const soalFromLocalStorage = await JSON.parse(localStorage.getItem("caseWillEdit"));
		if (soalFromLocalStorage === null) {
			await this.fetchDataCase();
		}
	}

	addItem = (e) => {

		console.log("this.props.currentItemTypeCase edit",this.props.currentItemTypeCase)

		e.preventDefault();
		if (this.props.currentItemTypeCase.text === "") {
			return false;
		} else {
			this.props.addCasePart(this.props.currentItemTypeCase, this.props.cases.name);
		}
		this.setState({
			isBtnDisable: true,
		});
	};

	deleteItem = (e, key) => {
		e.preventDefault();
		var removeIndex = this.getIndex(key, this.props.caseWillEdit);
		this.props.deleteCasePart(removeIndex);
	};

	handleSubmit(e) {
		e.preventDefault();
		const getAlert = () => (
			<SweetAlert custom showCancel showCloseButton confirmBtnText="Ya" cancelBtnText="Tidak" confirmBtnBsStyle="primary" cancelBtnBsStyle="light" title="Simpan Soal Ini?" onConfirm={() => this.submitExam()} onCancel={() => this.setState({alertSubmit: null})}>
				Klik Ya untuk menyimpan soal ini
			</SweetAlert>
		);
		const alertScoreWeightError = () => (
			<SweetAlert warning title="Proses Gagal!" onConfirm={() => this.setState({alertSubmit: null})} onCancel={() => this.setState({alertSubmit: null})}>
				Total Nilai Harus Sama Dengan 100
			</SweetAlert>
		);
		let totalScore = 0;
		Object.values(this.props.caseWillEdit).map((element) => {
			return (totalScore += Number(element.score_weight));
		});
		if (totalScore === 100) {
			this.setState({
				alertSubmit: getAlert(),
			});
		} else {
			this.setState({
				alertSubmit: alertScoreWeightError(),
			});
		}
	}

	handleDeleteImage(fileToDelete) {
		const url = "/files/delete";
		if (fileToDelete.length === 0) {
			fileToDelete.push("");
		}
		const dataToDel = {file_name: fileToDelete};
		axiosInstance
			.post(url, dataToDel)
			.then((r) => {})
			.catch((error) => {
				alert(`Proses delete gagal => ${error.message}`);
			});
	}

	deleteImageToSave(arrContent, arrAllFile) {
		// console.log(arrContent, arrAllFile);
		let arrLama = arrAllFile;
		arrContent.map((item) => {
			// console.log(item);
			return (arrLama = arrLama.filter((i) => i !== item));
		});
		// console.log(arrLama);
		this.handleDeleteImage(arrLama);
	}

	submitExam() {
		let arrImgContent = [];
		Object.values(this.props.caseWillEdit).map((part) => {
			Object.values(part.case_questions).map((question) => {
				if (question.file_attached !== null) {
					arrImgContent.push(question.file_attached);
				}
				if (question.case_choices.length > 0) {
					Object.values(question.case_choices).map((choice) => {
						return arrImgContent.push(choice.file_attached);
					});
				}
				return arrImgContent;
			});
			return arrImgContent;
		});
		this.deleteImageToSave(arrImgContent, this.props.listImgAll);
		const dataToPost = {
			case_parts: this.props.caseWillEdit,
			cases: this.props.cases,
		};
		axiosInstance
			.post(`/cases/updateExam`, dataToPost)
			.then((response) => {
				// console.log(response);
				this.handleSuccessEdit();
				//
			})
			.catch((error) => {
				if (error.response.status === 500) {
					this.handleFailureEdit("Terdapat data soal/jawaban kosong" , true);
				} else {
					this.handleFailureEdit(error.message);
				}
			});
	}

	handleSuccessEdit() {
		const alertSuccess = () => (
			<SweetAlert success title="Berhasil!" onConfirm={() => this.handleAlertSuccess()}>
				Soal berhasil diubah !
			</SweetAlert>
		);
		this.setState({
			alertSubmit: alertSuccess(),
		});
	}

	handleAlertSuccess() {
		this.setState({alertSubmit: null, taskDone: true});
		this.props.changeStatusEdit(true);
	}

	handleFailureEdit(errorMessage, isRequired = false) {
        if (isRequired) {
            this.setState({
                caseStillEmpty: true
            })
        }
		const alertFailure = () => (
			<SweetAlert warning title="Proses Gagal!" onConfirm={() => this.setState({alertSubmit: null})} onCancel={() => this.setState({alertSubmit: null})}>
				{errorMessage}
			</SweetAlert>
		);
		this.setState({
			alertSubmit: alertFailure(),
		});
	}

	getIndex(key, stateData) {
		var index = stateData
			.map(function (item) {
				return item.key;
			})
			.indexOf(key);
		return index;
	}

	handleUpdateCasePart(keyPart, event) {
		var index = this.getIndex(keyPart, this.props.caseWillEdit);
		let text = event.target.value;
		this.props.setNameCasePart(index, text);
	}

	handleUpdateScoreWeight(keyPart, value) {
		let index = this.getIndex(keyPart, this.props.caseWillEdit);
		this.props.setScoreWeight(index, value);
	}

	handleUpdateRandomQuestion(keyPart) {
		let index = this.getIndex(keyPart, this.props.caseWillEdit);
		const newValue = parseInt(this.props.caseWillEdit[index].random_question_order) + 1 === 2 ? "0" : "1";
		this.props.editRandomQuestion(index, newValue);
	}

	handleUpdateRandomAnswer(keyPart) {
		let index = this.getIndex(keyPart, this.props.caseWillEdit);
		const newValue = parseInt(this.props.caseWillEdit[index].random_choice_order) + 1 === 2 ? "0" : "1";
		this.props.editRandomAnswer(index, newValue);
	}
	handleUpdateCaseSensitife(keyPart) {
		let index = this.getIndex(keyPart, this.props.caseWillEdit);
		const newValue = parseInt(this.props.caseWillEdit[index].is_case_sensitive) + 1 === 2 ? 0 : 1;
		this.props.editCaseSensitife(index, newValue);
	}
	handleSelectTypeCase(text) {
		if (text === "Uraian") {
			this.props.handleSelectTypeCaseEssay(text, this.props.cases.id);
		} else if (text === "menjodohkan"){
			this.props.handleSelectTypeCaseMenjodohkan(text, this.props.cases.id);
		} else {
			this.props.handleSelectTypeCasePilgan(text, this.props.cases.id);
		}
	}
	render() {
		const caseWillEdit = this.props.caseWillEdit;
		const cases = this.props.cases;
		if (this.state.taskDone === true) {
			localStorage.removeItem("caseWillEdit");
			return <Redirect to="/list-soal" />;
		}

		return (
			<Fragment>
				<Prompt
					message={JSON.stringify({
						confirmText: "Ya",
						messageText: "Apakah Anda yakin keluar? Perubahan Anda akan hilang!",
						cancelText: "Batal",
					})}
				/>
				<div className="main-panel">
					<div className="content-wrapper">
						{/* <div className="page-header">
							<h3 className="page-title">
								<span className="page-title-icon bg-gradient-primary text-white mr-2">
									<i className="mdi mdi-lead-pencil"></i>
								</span>
								Edit Soal
							</h3>
						</div> */}
						{caseWillEdit === undefined ? (
							<div className="d-flex justify-content-center">
								<img src={Ball} className="img-fluid" alt="" />
							</div>
						) : (
							<div className="make-soal">
								<div className="page-header">
							<h3 className="page-title">
								<span className="page-title-icon bg-gradient-primary text-white mr-2">
									<i className="mdi mdi-lead-pencil"></i>
								</span>
								Edit Soal
							</h3>
						</div>
								<div className="info-panel d-flex">
									<div className="mr-auto p-2">
										<h1>{cases.name}</h1>
									</div>
									{this.props.doingEditCase === false && (
										<div className="p-2 d-flex align-self-center" style={{color: "red"}}>
                                            <div className="alert alert-danger" role="alert">
                                                <i className="mdi mdi-alert-circle mr-1"></i>
												Soal ini <b>sudah dikerjakan</b>. Anda <b>tidak dapat Hapus/Tambah</b>
                                            </div>
										</div>
									)}
								</div>
								<div className="row">
									<div className="col-12 grid-margin">
										<div className="card custom-card-shadow">
											<div className="card-body">
													{/* get value from case name soal */}
												<div className="form-group">
													<p className="h4">Nama Soal:</p>
													<input id="questionName" type="text" className="form-control inputQuestion darkLine" placeholder="Masukan Nama Soal Terlebih Dahulu" value={cases.name} onChange={(e) => this.props.editNameCase(e.target.value)} required></input>
												</div>
												<div className="dropdown-divider"></div>
												{/* form soal */}
												<form onSubmit={(e) => this.handleSubmit(e)} className="mb-4">
												{/* tipe soal head */}
												<div className="form-group form-group-tipe-soal mt-2">
													<p className="h4">Tipe Soal</p>
													<div className="d-flex flex-wrap">
													<select value={this.props.currentItemTypeCase.typeName} className="custom-select mr-sm-2 darkLine" id="chooseTypeQuestion" style={{width: "15%"}} onChange={(e) => this.handleSelectTypeCase(e.target.value)} disabled={!this.props.doingEditCase}>
														<option value="">Pilih</option>
														<option value="pilihan ganda">Pilihan Ganda</option>
																<option value="uraian">Uraian</option>
																<option value="pilihan ganda kompleks">Pilihan Ganda Kompleks</option>
																<option value="isian singkat">Isian Singkat</option>
																<option value="pilihan ganda benar salah">
                                    Pilihan Ganda Benar Salah
                                  </option>
																<option value="menjodohkan">
                                    Menjodohkan
                                  </option>
													</select>

													<button type="submit" className="btn btn-gradient-primary mr-2 " onClick={this.addItem} disabled={this.props.currentItemTypeCase.type_name === "" ? true : false}>
														+ Tambah
													</button>
													<div>
														<button type="submit" disabled={this.props.doingEditCase === false} className="btn btn-gradient-primary mr-2 ">
															Selesai
														</button>
														{this.state.alertSubmit}
													</div>
													</div>
												</div>
												
													{/* here check view edit part */}
													{Object.values(caseWillEdit).map((member, index) => (
														
														<div key={member.key}>
															<div className="mb-3">
																<h3 className="font-weight-bolder section-title d-inline">
																	Bagian {index + 1}.
																	{/* {member.case_questions[0].case_choices
																		.length === 0
																		? " Uraian"
																		: " Pilihan Ganda"} */}
																		{member.type_name === "uraian" ? "Uraian" : member.type_name === "pilihan ganda" ? "Pilihan Ganda" : member.type_name === "pilihan ganda kompleks" ? "Pilihan Ganda Kompleks" :member.type_name === "pilihan ganda benar salah" ? "Pilihan Ganda Benar Salah" :member.type_name === "menjodohkan" ? "Menjodohkan":"Isian Singkat"}
																</h3>
																<button type="submit" className="btn btn-gradient-danger btn-sm float-right" onClick={(e) => this.deleteItem(e, member.key)} disabled={!this.props.doingEditCase}>
																	Hapus
																</button>
															</div>
															{/* head nama BAB */}
															<div className="form-group row">
																<h3 className="col-sm-2 col-form-label h4 ">Nama Bab :</h3>
																<div className="col-sm-10">
																	<textarea
																		className="form-control darkLine"
																		id={"inputDesc" + member.id}
																		rows="3"
																		placeholder="Tulis Nama Bab. Contoh: Bab Perkalian"
																		value={member.name}
																		onChange={(event) => {
																			this.handleUpdateCasePart(member.key, event);
																		}}
																		required
																	></textarea>
																</div>
																<h3 className="col-sm-2 col-form-label h4 mt-1">Bobot Nilai :</h3>
																<div className="col-sm-10 row">
																	<div className="col-sm-auto mt-4">
																		<div style={{ minWidth: "300px" }}>
																			<InputRange maxValue={100} minValue={0} step={5} value={Number(member.score_weight)} onChange={(value) => this.handleUpdateScoreWeight(member.key, value)} />
																		</div>
																	</div>
																	<div className="col-sm-6 d-flex align-items-center">
																		<i>
																			*Total Nilai Maksimal adalah <b>100</b>
																		</i>
																	</div>
																</div>
																<h3 className="col-sm-2 col-form-label h4 ">PIlih Opsi:</h3>
																<div className="col-sm-10 form-inline mb-4">
																	<div className="form-check  mr-5">
																		<input
																			type="checkbox"
                                                                            id={`is-random-question-${member.key}`}
																			className="form-check-input"
																			onChange={() => {
																				this.handleUpdateRandomQuestion(member.key);
																			}}
																			checked={member.random_question_order === "1" ? true : false}
																		/>
																		<label className="h5" for ={`is-random-question-${member.key}`}><b>Acak Soal</b></label>
																	</div>
																	
																	{(member.type_name === "pilihan ganda benar salah" || member.type_name === "menjodohkan" || member.type_name === "pilihan ganda kompleks" || member.type_name === "pilihan ganda") && (
																		<div className="form-check mx-sm-2">
																			<input
																				type="checkbox"
                                                                                id={`is-random-choice-${member.key}`}
																				className="form-check-input"
																				onChange={() => {
																					this.handleUpdateRandomAnswer(member.key);
																				}}
																				checked={member.random_choice_order === "1" ? true : false}
																			/>
																			<label className="h5" for ={`is-random-choice-${member.key}`}><b>Acak Jawaban</b></label>
																		</div>
																	)}
																	{member.type_name === "isian singkat" && (
																		<div className="form-check mx-sm-2">
																			<input
																				type="checkbox"
                                                                                id={`is-case-sensitive-${member.key}`}
																				className="form-check-input"
																				onChange={() => {
																					this.handleUpdateCaseSensitife(member.key);
																				}}
																				checked={member.is_case_sensitive === 1 ? true : false}
																			/>
																			<label for={`is-case-sensitive-${member.key}`}><b>Case Sensitive</b></label>
																		</div>
																	)}
																</div>
															</div>

															<Question
																member={member}
																indexCasePart={this.getIndex(
																	member.key,
																	this.props.caseWillEdit
																)}
                                                                stillEmpty={this.state.caseStillEmpty}
																typeCase={
																	// member.case_questions[0].case_choices.length
																	member.type_name
																}
															/>
														</div>
													))}
													<div className="dropdown-divider mt-5 mb-5 "></div>
													{/* btn submit */}
													{/* <div className="text-center">
														<button type="submit" className="btn btn-gradient-primary mr-2 ">
															Selesai
														</button>
														{this.state.alertSubmit}
													</div> */}
												</form>
								<Footer />
											</div>
										</div>
									</div>
								</div>
							</div>
						)}
					</div>
				</div>
			</Fragment>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		cases: state.Exam.cases,
		currentItemTypeCase: state.Exam.currentItemCase,
		caseWillEdit: state.Exam.case_part,
		doingEditCase: state.Exam.doingEditCase,
		listImgAll: state.Exam.listImg,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		loadCaseWillEdit: (cases, case_parts) => dispatch(loadCaseWillEdit(cases, case_parts)),
		editNameCase: (newName) => dispatch(editNameCase(newName)),
		setNameCasePart: (xPart, newPartName) => dispatch(setNameCasePart(xPart, newPartName)),
		setScoreWeight: (xPart, newScore) => dispatch(setScoreWeight(xPart, newScore)),
		editRandomQuestion: (xPart, newValue) => dispatch(editRandomQuestion(xPart, newValue)),
		editRandomAnswer: (xPart, newValue) => dispatch(editRandomAnswer(xPart, newValue)),
		editCaseSensitife: (xPart, newValue) => dispatch(editCaseSensitife(xPart, newValue)),
		handleSelectTypeCaseMenjodohkan: (text,case_id) =>
      dispatch(handleSelectTypeCaseMenjodohkan(text, case_id)),

		handleSelectTypeCaseEssay: (text, case_id) => dispatch(handleSelectTypeCaseEssay(text, case_id)),
		handleSelectTypeCasePilgan: (text, case_id) => dispatch(handleSelectTypeCasePilgan(text, case_id)),
		addCasePart: (casePart, nameExam) => dispatch(addCasePart(casePart, nameExam)),
		deleteCasePart: (part) => dispatch(deleteItem(part)),
		changeStatusEdit: (status) => dispatch(changeStatusEdit(status)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(EditCase);
