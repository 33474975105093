import * as actionTypes from "./actionTypes";

// Apri
const paleteColor = [
  "#3DB2FF",
  "#FFAEC0",
  "#FF6B6B",
  "#7FC8A9",
  "#28FFBF",
  "#B980F0",
  "#BBBBBB",
  "#19D3DA",
  "#FDE49C",
  "#926E6F",
];

export const getDataStudent = (dataStudent, dataSchool) => {
  return {
    type: actionTypes.STUDENT_SUCCESS_LOGIN,
    dataStudent: dataStudent,
    dataSchool: dataSchool,
  };
};

export const fetchUserSuccess = (listCases) => {
  return {
    type: actionTypes.STUDENT_REQUEST_CASES_SUCCESS,
    payload: listCases,
  };
};

export const fetchUserFailure = (error) => {
  return {
    type: actionTypes.STUDENT_REQUEST_CASES_FAILURE,
    payload: error,
  };
};

export const getServerTime = (time) => {
  return {
    type: actionTypes.STUDENT_GET_SERVER_TIME,
    time: time,
  };
};

export const chooseCase = (
  case_id,
  course_name,
  course_id,
  school_id,
  dateEnd,
  schedule_id,
  is_remedial
) => {
    console.log(is_remedial, "remedial")
  return {
    type: actionTypes.STUDENT_CHOOSE_CASE,
    case_id: case_id,
    course_name: course_name,
    course_id: course_id,
    school_id: school_id,
    dateEnd: dateEnd,
    schedule_id: schedule_id,
    is_remedial: is_remedial,
  };
};

export const loadCaseSuccess = (listCases) => {
  console.log("listCases", listCases);
  // console.log(object);
  return {
    type: actionTypes.STUDENT_LOAD_CASE,
    payload: listCases,
  };
};

export const chooseAnswer = (event, answerId, caseId, partId) => {
  console.log(event, answerId, caseId, partId);
  return {
    type: actionTypes.STUDENT_CHOOSE_ANSWER,
    value: event,
    idAnswer: answerId,
    idCase: caseId,
    idPartCase: partId,
  };
};
export const chooseAnswerTrueFalse = (event, answerId, caseId, partId) => {
  console.log(event, answerId, caseId, partId);
  return {
    type: actionTypes.STUDENT_CHOOSE_ANSWER_TRUE_FALSE,
    value: event,
    idAnswer: answerId,
    idCase: caseId,
    idPartCase: partId,
  };
};
// choice menjodohkan
export const chooseCaseChoice = (event, answerId, caseId, partId) => {
  console.log(event, answerId, caseId, partId);
  return (dispatch, getState) => {
    // console.log(
    //   "tes",
    //   getState().User.caseStudent.case_parts[partId].case_questions[caseId]
    // );
    let casePart = getState().User.caseStudent.case_parts.filter(
      (casePart, i) => {
        if (casePart.id === partId) {
          return casePart;
        }
      }
    );

    console.log("data filter", casePart);
  };
};

// start of APRI
export const chooseAnswerMultiple = (
  event,
  answerId,
  questionId,
  partId,
  choiceAnswerId = null
) => {
  console.log(
    "event, answerId, questionId, partId, choiceAnswerId",
    event,
    answerId,
    questionId,
    partId,
    choiceAnswerId
  );

  return (dispatch, getState) => {
    let data = getState().User;
    let dataStart = data.caseStudent;
    let dataSelectedItem = {
      ...dataStart,
      case_parts: dataStart.case_parts.map((part, i) =>
        part.id === partId
          ? {
              ...part,
              case_questions: part.case_questions.map((item, j) =>
                item.id === questionId
                  ? {
                      ...item,
                      // select berdasarkan jumlah correct answer,
                      case_choices:
                        part.type_name === "menjodohkan" ||
                        parseInt(item.correct_answers) !== item.answer.length
                          ? item.case_choices.map((itemAns, k) =>
                              itemAns.id === answerId &&
                              itemAns.selected === true &&
                              choiceAnswerId === null
                                ? { ...itemAns, selected: false, color: "" }
                                : itemAns.id === answerId &&
                                  itemAns.selected === false &&
                                  choiceAnswerId === null
                                ? { ...itemAns, selected: true }
                                : { ...itemAns }
                            )
                          : item.case_choices.map((itemAns, k) =>
                              itemAns.id === answerId &&
                              itemAns.selected === true &&
                              choiceAnswerId === null
                                ? { ...itemAns, selected: false }
                                : itemAns.id === answerId &&
                                  itemAns.selected === false &&
                                  choiceAnswerId === null
                                ? { ...itemAns, selected: false }
                                : { ...itemAns }
                            ),

                      case_choices_answers: item.case_choices_answers.map(
                        (itemCAns, idx) =>
                          itemCAns.id === choiceAnswerId
                            ? itemCAns.selected === false ||
                              !itemCAns.hasOwnProperty("selected")
                              ? {
                                  ...itemCAns,
                                  case_choice_id: answerId,
                                  selected: true,
                                }
                              : {
                                  ...itemCAns,
                                  case_choice_id: null,
                                  selected: false,
                                  color: "",
                                }
                            : { ...itemCAns }
                      ),
                    }
                  : item
              ),
            }
          : part
      ),
    };

    let dataEnd = {
      ...dataSelectedItem,
      case_parts: dataSelectedItem.case_parts.map((part, i) =>
        part.id === partId
          ? {
              ...part,
              case_questions: part.case_questions.map((item, j) =>
                item.id === questionId
                  ? {
                      ...item,

                      // data length dari answer_students ketika tidak sesuai dengan correct_answer bug id null/undifined
                      answer:
                        part.type_name === "menjodohkan"
                          ? utilsAnswerMenjodohkan(item)
                          : utilsAnswerKompleks(item),
                    }
                  : //
                    item
              ),
            }
          : part
      ),
    };
    dispatch(setAnswerMultiple(dataEnd));
  };
};

function getColorRandom(case_question, case_choice_id, case_choices_answer_id) {
  const answerExists = case_question.answer.filter(
    (value) =>
      value.case_choice_id === case_choice_id &&
      value.case_choices_answer_id === case_choices_answer_id
  );
  if (answerExists.length > 0) {
    return answerExists[0]["color"];
  }
  // filter, exclude color already used in answer
  const listOfSelectedColor = case_question.answer.map((value) => value.color);
  const listOfSelectedColorSecondary = case_question.case_choices.map(
    (value) => value.color
  );
  const filteredPalete = paleteColor.filter((value) => {
    return (
      !listOfSelectedColor.includes(value) &&
      !listOfSelectedColorSecondary.includes(value)
    );
  });
  return filteredPalete[Math.floor(Math.random() * filteredPalete.length)];
}

/**
 *
 * @param {object after manipulate selected} caseQuestion
 * @returns
 */

function utilsAnswerMenjodohkan(caseQuestion) {
  return caseQuestion.case_choices_answers.filter(
    (items, k) => items.selected === true
  ).length === 0
    ? utilsDefaultValueMultiChoice()
    : caseQuestion.case_choices_answers
        .filter((items, k) => items.selected === true)
        .map((itemx, p) => {

          /**
           * dengan asumsi tidak ada pernah duplikat jawaban case_choices_answer_id
           */
          let getFromAnswer = caseQuestion.answer.filter((value) => value.case_choices_answer_id === itemx.id)[0]
          let getFromStudentAnswer = getFromAnswer !== undefined 
                    ? caseQuestion.student_answers.filter((value) => value.case_choice_id === getFromAnswer['case_choice_id'])[0]
                    : getFromAnswer;

          let data = {
            // utk mengurutkan berdasarkan waktu kejadian yang pertama kali
            // dia ada di dalam answer, kalau tidak ada id nya, maka buatkan baru
            // kalau sudah ada, pakai yg lama
            ids: getFromAnswer !== undefined ? getFromAnswer['ids'] : Date.now(),
            case_choice_id: itemx.case_choice_id !== undefined ? itemx.case_choice_id : getFromAnswer['case_choice_id'],
            case_choices_answer_id: itemx.id,
            value: itemx.value,
            color: getColorRandom(caseQuestion, itemx.case_choice_id, itemx.id), // register color here
            student_answer_id: getFromStudentAnswer !== undefined ? getFromStudentAnswer['id'] : null,
            status_img: getFromStudentAnswer !== undefined ? (getFromStudentAnswer['file_attached'] !== null ? 1 : 0) : null,
            file_attached: getFromStudentAnswer !== undefined ? getFromStudentAnswer['file_attached'] : null,
          };
          return data;
        }).sort((a, b) => a.ids - b.ids);
}

/**
 *
 * @param {object after manipulate selected} caseQuestion
 * @returns
 */
function utilsAnswerKompleks(caseQuestion) {
  return caseQuestion.case_choices.filter((items, k) => items.selected === true)
    .length === 0
    ? utilsDefaultValueMultiChoice()
    : caseQuestion.case_choices
        .filter((items, k) => items.selected === true)
        .map((itemx, p) => {
          let data = {
            ...itemx,
            case_choice_id: itemx.id,
            value: itemx.value,
            student_answer_id:
              caseQuestion.student_answers.length === 0 ||
              caseQuestion.student_answers[p] === undefined
                ? null
                : caseQuestion.student_answers[p].id,
            status_img:
              caseQuestion.student_answers.length === 0
                ? 0
                : caseQuestion.student_answers[p] === undefined
                ? null
                : caseQuestion.student_answers[p].file_attached !== null
                ? 1
                : 0,
            file_attached:
              caseQuestion.student_answers.length === 0 ||
              caseQuestion.student_answers[p] === undefined
                ? null
                : caseQuestion.student_answers[p].file_attached,
          };
          return data;
        });
}

function utilsDefaultValueMultiChoice() {
  return [
    {
      case_choice_id: null,
      value: null,
      student_answer_id: null,
      status_img: 0,
      file_attached: null,
    },
  ];
} // end of APRI

//
export const setAnswerMultiple = (datar) => {
  return {
    type: actionTypes.STUDENT_CHOOSE_ANSWER_MULTIPLE,
    data: datar,
  };
};

export const setAnswer = (event, answerId, caseId, partId) => {
  return {
    type: actionTypes.STUDENT_SET_ANSWER,
    value: event,
    idAnswer: answerId,
    idCase: caseId,
    idPartCase: partId,
  };
};
export const setAnswerShort = (event, answerId, caseId, partId) => {
  return {
    type: actionTypes.STUDENT_SET_ANSWER_SHORT,
    value: event,
    idAnswer: answerId,
    idCase: caseId,
    idPartCase: partId,
  };
};
export const submitAnswer = () => {
  return {
    type: actionTypes.STUDENT_SUMBIT_ANSWER,
    payload: true,
  };
};

export const saveAnswer = () => {
  return {
    type: actionTypes.STUDENT_SAVE_ANSWER,
    payload: true,
  };
};

export const whenTimerEnds = () => {
  return {
    type: actionTypes.STUDENT_WHEN_TIMER_ENDS,
  };
};
export const alertTimer = () => {
  return {
    type: actionTypes.STUDENT_ALERT_TIME_1,
  };
};

export const deleteCaseActive = () => {
  return {
    type: actionTypes.STUDENT_REMOVE_CASE_ACTIVE,
  };
};

export const addImageAnsStu = (indexPart, indexQues, image) => {
  return {
    type: actionTypes.STUDENT_ADD_IMAGE_ANSWER,
    indexPart: indexPart,
    indexQues: indexQues,
    image: image,
  };
};

export const addImageFileAnsStu = (indexPart, indexQues, imageFile) => {
  return {
    type: actionTypes.STUDENT_ADD_IMAGE_FILE_ANSWER,
    indexPart: indexPart,
    indexQues: indexQues,
    imageFile: imageFile,
  };
};

export const removeImageStu = (indexPart, indexQues) => {
  return {
    type: actionTypes.STUDENT_REMOVE_IMAGE,
    indexPart: indexPart,
    indexQues: indexQues,
  };
};

export const addListImgStu = (imageName) => {
  return {
    type: actionTypes.STUDENT_ADD_LIST_IMG,
    imageName: imageName,
  };
};
