import * as actionType from "../actions/actionTypes";
import update from "react-addons-update";

const initialState = {
  status: false,
  role: null,
};

// search paleteColor to know where use this palete , APRI
const paleteColor = [
  "#3DB2FF",
  "#FFAEC0",
  "#FF6B6B",
  "#7FC8A9",
  "#28FFBF",
  "#B980F0",
  "#BBBBBB",
  "#19D3DA",
  "#FDE49C",
  "#926E6F",
];

function getColorRandom(listOfSelectedColor) {
  const filteredPalete = paleteColor.filter(
    (value) => !listOfSelectedColor.includes(value)
  );
  return filteredPalete[Math.floor(Math.random() * filteredPalete.length)];
} // end of APRI

const UserReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.TEACHER_SUCCESS_LOGIN:
      return {
        dataTeacher: action.dataTeacher,
        dataSchool: action.dataSchool,
        status: true,
        // listSchedule: [],
        role: "TEACHER",
      };
    case actionType.TEACHER_SCHEDULE_SUCCESS:
      return {
        ...state,
        listSchedule: action.listSchedule,
      };

    case actionType.TEACHER_SET_INFO_EXAM:
      return {
        ...state,
        examInfo: {
          courseName: action.courseName,
          className: action.className,
          caseName: action.caseName,
          dateSchedule: action.dateSchedule,
        },
      };

    case actionType.USER_LOGOUT:
      return {
        status: false,
        role: null,
      };

    case actionType.TEACHER_GET_STUDENT_ANSWER:
      return {
        ...state,
        studentAnswer: action.payload,
      };

    case actionType.TEACHER_REMOVE_STUDENT_ANSWER:
      return {
        ...state,
        studentAnswer: null,
      };

    case actionType.TEACHER_REMOVE_EXAM_INFO:
      return {
        ...state,
        examInfo: null,
        studentAnswer: null,
      };

    case actionType.TEACHER_SET_SCORE_ESSAY:
      return update(state, {
        studentAnswer: {
          case_parts: {
            [action.indexPart]: {
              case_questions: {
                [action.indexQues]: {
                  student_answers: {
                    [0]: {
                      is_correct: { $set: action.newScore },
                    },
                  },
                },
              },
            },
          },
        },
      });
    case actionType.TEACHER_SET_SCORE_ESSAY_SHORT:
      return update(state, {
        studentAnswer: {
          case_parts: {
            [action.indexPart]: {
              case_questions: {
                [action.indexQues]: {
                  case_choices: {
                    [0]: {
                      is_correct: { $set: action.newScore },
                    },
                  },

                  student_answers: {
                    [0]: {
                      is_correct: { $set: action.newScore },
                    },
                  },
                },
              },
            },
          },
        },
      });

    case actionType.TEACHER_SHOW_IMG_ASSESSMENT:
      return update(state, {
        studentAnswer: {
          case_parts: {
            [action.indexPart]: {
              case_questions: {
                [action.indexQues]: {
                  student_answers: {
                    [0]: {
                      showImg: { $set: !action.lastStatus },
                    },
                  },
                },
              },
            },
          },
        },
      });
    // ----------------------------------------------------------------------------

    case actionType.STUDENT_SUCCESS_LOGIN:
      return {
        caseStudent: {
          listImg: [],
          statusSubmit: false,
          saveTemporar: false,
        },
        dataStudent: action.dataStudent,
        dataSchool: action.dataSchool,
        status: true,
        listSchedule: null,
        role: "STUDENT",
      };

    case actionType.STUDENT_REQUEST_CASES_SUCCESS:
      return {
        ...state,
        listSchedule: action.payload,
      };

    case actionType.STUDENT_CHOOSE_CASE:
      return {
        ...state,
        currentCase: {
          course_name: action.course_name,
          course_id: action.course_id,
          case_id: action.case_id,
          school_id: action.school_id,
          schedule_id: action.schedule_id,
          is_remedial: action.is_remedial
        },
        timer: {
          ...state.timer,
          dateEnd: action.dateEnd,
          status: true,
        },
      };
    // load after simpan sementara
    case actionType.STUDENT_LOAD_CASE:
      let tempColorUsed = [];
      let listCaseChoicesColor = []; // array object
      return {
        ...state,
        caseStudent: {
          ...action.payload,
          listImg: [],
          statusSubmit: false,
          saveTemporar: false,
          api_schedule_id: action.payload.schedules.api_schedule_id,
          is_remedial: action.payload.schedules.is_remedial,
          // ganti dari state reduce case part
          // masalahnya skrng default nya .
          case_parts: action.payload.case_parts.map((part, i) => {
            return {
              ...part,
              case_questions: part.case_questions.map((item, j) => {
                return {
                  ...item,
                  answer:
                    item.student_answers.length === 0
                      ? part.type_name !== "pilihan ganda kompleks" &&
                        part.type_name !== "menjodohkan"
                        ? {
                            case_choice_id: null,
                            value: null,
                            student_answer_id: null,
                            status_img: 0,
                            file_attached: null,
                          }
                        : [
                            {
                              case_choice_id: null,
                              value: null,
                              student_answer_id: null,
                              status_img: 0,
                              file_attached: null,
                            },
                          ]
                      : part.type_name === "pilihan ganda kompleks" ||
                        part.type_name === "menjodohkan"
                      ? [...item.student_answers].map((itemSA, p) => {
                          return {
                            ...itemSA,
                            value: itemSA.answer_text,
                            student_answer_id: itemSA.id,
                            status_img: itemSA.file_attached !== null ? 1 : 0,
                            file_attached: itemSA.file_attached,
                          };
                        })
                      : part.type_name === "isian singkat"
                      ? {
                          ...item.answer,
                          case_choice_id: item.case_choices[0].id,
                          value: item.student_answers[0].answer_text,
                        }
                      : {
                          case_choice_id:
                            item.student_answers[0].case_choice_id,
                          value: item.student_answers[0].answer_text,
                          student_answer_id: item.student_answers[0].id,
                          status_img:
                            item.student_answers[0].file_attached !== null
                              ? 1
                              : 0,
                          file_attached: item.student_answers[0].file_attached,
                        },
                  // START of Apri
                  case_choices: item.case_choices.map((itemAns, k) => {
                    if (k === 0) tempColorUsed = [];
                    let isSelected = item.student_answers.some(
                      (itemss) => itemss.case_choice_id === itemAns.id
                    );
                    let color = "";
                    if (isSelected && part.type_name === "menjodohkan") {
                      color = getColorRandom(tempColorUsed);
                      tempColorUsed.push(color);
                      listCaseChoicesColor.push({
                        id: itemAns.id,
                        color: color,
                      });
                    }
                    return item.student_answers.length !== 0
                      ? part.type_name === "pilihan ganda kompleks" ||
                        part.type_name === "menjodohkan"
                        ? {
                            ...itemAns,
                            selected: isSelected,
                            color: color,
                          }
                        : item.student_answers[0].case_choice_id === itemAns.id
                        ? { ...itemAns, selected: true }
                        : { ...itemAns, selected: false }
                      : { ...itemAns, selected: false };
                  }),

                  case_choices_answers: item.case_choices_answers.map(
                    (itemAns, k) => {
                      if (k === 0) tempColorUsed = [];
                      let idCaseChoice = null;
                      let color = "";
                      let isSelected = item.student_answers.some((itemss) => {
                        idCaseChoice = itemss.case_choice_id;
                        return itemss.case_choices_answer_id === itemAns.id;
                      });
                      if (isSelected && part.type_name === "menjodohkan") {
                        color = listCaseChoicesColor.filter((value) => {
                          return value.id === idCaseChoice;
                        })[0]["color"];
                      }
                      return item.student_answers.length !== 0 &&
                        part.type_name === "menjodohkan"
                        ? {
                            ...itemAns,
                            selected: isSelected,
                            color: color,
                            case_choice_id: idCaseChoice,
                          }
                        : { ...itemAns, selected: false };
                    }
                  ),
                }; // end of APRI
              }),
            };
          }),
        },
      };

    // set value pilgan
    case actionType.STUDENT_CHOOSE_ANSWER:
      return {
        ...state,
        caseStudent: {
          ...state.caseStudent,
          saveTemporar: false,
          case_parts: state.caseStudent.case_parts.map((part, i) =>
            part.id === action.idPartCase
              ? {
                  ...part,
                  case_questions: part.case_questions.map((item, j) =>
                    item.id === action.idCase
                      ? {
                          ...item,
                          // error karena dia hanya sortir yang sama kalo gak sama gak dapet valuenya
                          answer: {
                            ...item.answer,
                            value: action.value,
                            case_choice_id: action.idAnswer,
                            student_answer_id:
                              item.student_answers.length !== 0
                                ? item.student_answers[0].id
                                : null,
                            status_img:
                              item.student_answers.length !== 0
                                ? item.student_answers[0].file_attached !== null
                                  ? 1
                                  : 0
                                : 0,
                            file_attached:
                              item.student_answers.length === 0
                                ? null
                                : item.student_answers[0].file_attached,
                          },
                          case_choices: item.case_choices.map((itemAns, k) =>
                            itemAns.id === action.idAnswer
                              ? {
                                  ...itemAns,
                                  selected: true,
                                }
                              : {
                                  ...itemAns,
                                  selected: false,
                                }
                          ),
                        }
                      : item
                  ),
                }
              : part
          ),
        },
      };
    // set value pilgan
    case actionType.STUDENT_CHOOSE_ANSWER_TRUE_FALSE:
      return {
        ...state,
        caseStudent: {
          ...state.caseStudent,
          saveTemporar: false,
          case_parts: state.caseStudent.case_parts.map((part, i) =>
            part.id === action.idPartCase
              ? {
                  ...part,
                  case_questions: part.case_questions.map((item, j) =>
                    item.id === action.idCase
                      ? {
                          ...item,
                          // error karena dia hanya sortir yang sama kalo gak sama gak dapet valuenya
                          answer: {
                            ...item.answer,
                            value: action.value,
                            case_choice_id: action.idAnswer,
                            student_answer_id:
                              item.student_answers.length !== 0
                                ? item.student_answers[0].id
                                : null,
                            status_img:
                              item.student_answers.length !== 0
                                ? item.student_answers[0].file_attached !== null
                                  ? 1
                                  : 0
                                : 0,
                            file_attached:
                              item.student_answers.length === 0
                                ? null
                                : item.student_answers[0].file_attached,
                          },
                          case_choices: item.case_choices.map((itemAns, k) =>
                            itemAns.id === action.idAnswer
                              ? {
                                  ...itemAns,
                                  selected: true,
                                }
                              : {
                                  ...itemAns,
                                  selected: false,
                                }
                          ),
                        }
                      : item
                  ),
                }
              : part
          ),
        },
      };

    case actionType.STUDENT_CHOOSE_ANSWER_MULTIPLE:
      return {
        ...state,
        caseStudent: action.data,
      };
    // set value easy
    case actionType.STUDENT_SET_ANSWER:
      return {
        ...state,
        caseStudent: {
          ...state.caseStudent,
          saveTemporar: false,
          case_parts: state.caseStudent.case_parts.map((part, i) =>
            part.id === action.idPartCase
              ? {
                  ...part,
                  case_questions: part.case_questions.map((item, j) =>
                    item.id === action.idCase
                      ? {
                          ...item,
                          answer: {
                            ...item.answer,
                            value: action.value,
                            case_choice_id: action.idAnswer,
                            student_answer_id:
                              item.student_answers.length === 0
                                ? null
                                : item.student_answers[0].case_choice_id,
                            status_img:
                              item.student_answers.length === 0
                                ? 0
                                : item.student_answers[0].file_attached !== null
                                ? 1
                                : 0,
                            // null,
                            file_attached:
                              item.student_answers.length === 0
                                ? null
                                : item.student_answers[0].file_attached,
                          },
                          case_choices: [],
                        }
                      : item
                  ),
                }
              : part
          ),
        },
      };
    // set value easy singkat
    case actionType.STUDENT_SET_ANSWER_SHORT:
      return {
        ...state,
        caseStudent: {
          ...state.caseStudent,
          saveTemporar: false,
          case_parts: state.caseStudent.case_parts.map((part, i) =>
            part.id === action.idPartCase
              ? {
                  ...part,
                  case_questions: part.case_questions.map((item, j) =>
                    item.id === action.idCase
                      ? {
                          ...item,
                          answer: {
                            ...item.answer,
                            value: action.value,
                            case_choice_id: item.case_choices[0].id,
                            student_answer_id:
                              item.student_answers.length === 0
                                ? null
                                : item.student_answers[0].id,
                            // : item.student_answers.length === 0
                            // ? null
                            // : item.student_answers
                            //     .filter(
                            //       (itemp, x) =>
                            //         itemp.case_choice_id === action.idAnswer
                            //     )
                            //     .map((itemo, p) => itemo.id)[0],
                            status_img:
                              item.student_answers.length === 0
                                ? 0
                                : item.student_answers[0].file_attached !== null
                                ? 1
                                : 0,
                            // null,
                            file_attached:
                              item.student_answers.length === 0
                                ? null
                                : item.student_answers[0].file_attached,
                          },
                          case_choices: item.case_choices.map((itemAns, k) =>
                            itemAns.id === action.idAnswer
                              ? {
                                  ...itemAns,
                                  selected: true,
                                }
                              : {
                                  ...itemAns,
                                  selected: true,
                                }
                          ),
                        }
                      : item
                  ),
                }
              : part
          ),
        },
      };

    case actionType.STUDENT_SUMBIT_ANSWER:
      return update(state, {
        caseStudent: {
          statusSubmit: { $set: action.payload },
        },
      });

    case actionType.STUDENT_SAVE_ANSWER:
      return update(state, {
        caseStudent: {
          saveTemporar: { $set: action.payload },
          listImg: { $set: [] },
        },
      });

    case actionType.STUDENT_ALERT_TIME_1:
      return {
        ...state,
        timer: {
          ...state.timer,
          alertShow: true,
        },
      };
    case actionType.STUDENT_WHEN_TIMER_ENDS:
      return {
        ...state,
        timer: {
          alertShow: false,
          status: false,
        },
      };
    case actionType.STUDENT_REMOVE_CASE_ACTIVE:
      return {
        listSchedule: state.listSchedule,
        role: state.role,
        status: state.status,
        dataSchool: state.dataSchool,
        dataStudent: state.dataStudent,
        time: state.time,
        caseStudent: {
          listImg: [],
          statusSubmit: false,
          saveTemporar: false,
        },
      };

    case actionType.STUDENT_GET_SERVER_TIME:
      return {
        ...state,
        time: action.time,
      };

    case actionType.STUDENT_ADD_LIST_IMG:
      return update(state, {
        caseStudent: {
          listImg: { $push: [action.imageName] },
        },
      });

    case actionType.STUDENT_ADD_IMAGE_ANSWER:
      return update(state, {
        caseStudent: {
          case_parts: {
            [action.indexPart]: {
              case_questions: {
                [action.indexQues]: {
                  answer: {
                    status_img: { $set: 1 },
                    file_attached: { $set: action.image },
                  },
                },
              },
            },
          },
          saveTemporar: { $set: false },
        },
      });

    case actionType.STUDENT_ADD_IMAGE_FILE_ANSWER:
      return update(state, {
        caseStudent: {
          case_parts: {
            [action.indexPart]: {
              case_questions: {
                [action.indexQues]: {
                  answer: {
                    img_file: { $set: action.imageFile },
                  },
                },
              },
            },
          },
          saveTemporar: { $set: false },
        },
      });

    case actionType.STUDENT_REMOVE_IMAGE:
      return update(state, {
        caseStudent: {
          case_parts: {
            [action.indexPart]: {
              case_questions: {
                [action.indexQues]: {
                  answer: {
                    file_attached: { $set: null },
                    status_img: { $set: 0 },
                  },
                },
              },
            },
          },
        },
      });

    default:
      return state;
  }
};

export default UserReducer;
