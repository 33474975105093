//action teacher
export const USER_LOGOUT = "USER_LOGOUT";
export const TEACHER_SUCCESS_LOGIN = "TEACHER_SUCCESS_LOGIN";
export const TEACHER_SCHEDULE_SUCCESS = "TEACHER_SCHEDULE_SUCCESS";
export const TEACHER_REQUEST_SCHEDULE = "TEACHER_REQUEST_SCHEDULE";
export const TEACHER_SCHEDULE_FAILURE = "TEACHER_SCHEDULE_FAILURE";
export const TEACHER_REMOVE_SCHEDULE_SELECTED =
  "TEACHER_REMOVE_SCHEDULE_SELECTED";
export const TEACHER_MAKE_CASE_BY_SCHEDULE = "TEACHER_MAKE_CASE_BY_SCHEDULE";
export const TEACHER_MAKE_NEW_CASE = "TEACHER_MAKE_NEW_CASE";
export const TEACHER_EDIT_CASES = "TEACHER_EDIT_CASES";
export const TEACHER_SET_INFO_EXAM = "TEACHER_SET_INFO_EXAM";

// SCORING TEACHER
export const TEACHER_GET_STUDENT_ANSWER = "TEACHER_GET_STUDENT_ANSWER";
export const TEACHER_REMOVE_STUDENT_ANSWER = "TEACHER_REMOVE_STUDENT_ANSWER";
export const TEACHER_REMOVE_EXAM_INFO = "TEACHER_REMOVE_EXAM_INFO";
export const TEACHER_SET_SCORE_ESSAY = "TEACHER_SET_SCORE_ESSAY";
export const TEACHER_SET_SCORE_ESSAY_SHORT = "TEACHER_SET_SCORE_ESSAY_SHORT";
export const TEACHER_SUBMIT_ASSESSMENT = "TEACHER_SUBMIT_ASSESSMENT";
export const TEACHER_SHOW_IMG_ASSESSMENT = "TEACHER_SHOW_IMG_ASSESSMENT";
//Edit Case
export const LOAD_CASE_EDIT = "LOAD_CASE_EDIT";
export const EDIT_RANDOM_QUESTION = "EDIT_RANDOM_QUESTION";
export const EDIT_RANDOM_ANSWER = "EDIT_RANDOM_ANSWER";
export const EDIT_CASE_SENSITIFE = "EDIT_CASE_SENSITIFE";
export const EDIT_ANSWER_CORRECT = "EDIT_ANSWER_CORRECT";
export const EDIT_ANSWER_CORRECT_TRUE_FALSE = "EDIT_ANSWER_CORRECT_TRUE_FALSE";
export const EDIT_ANSWER_CORRECT_MULTIPLE = "EDIT_ANSWER_CORRECT_MULTIPLE";
export const EDIT_CASE_FINISHED = "EDIT_CASE_FINISHED";

//Action tambah soal
export const SET_CASE_NAME = "SET_CASE_NAME";
export const CHOOSE_CASE_PART_ESSAY = "CHOOSE_CASE_PART_ESSAY";
export const CHOOSE_CASE_PART_PILGAN = "CHOOSE_CASE_PART_PILGAN";
export const CHOOSE_CASE_PART_MENJODOHKAN = "CHOOSE_CASE_PART_MENJODOHKAN";
export const ADD_CASE_PART = "ADD_CASE_PART";
export const DELETE_CASE_PART = "DELETE_CASE_PART";
export const SET_RANDOM_QUESTION = "SET_RANDOM_QUESTION";
export const SET_RANDOM_ANSWER = "SET_RANDOM_ANSWER";
export const SET_CASE_SENSITIVE_ANSWER = "SET_CASE_SENSITIVE_ANSWER";
export const SET_CASE_PART_NAME = "SET_CASE_PART_NAME";
export const SET_SCORE_WEIGHT = "SET_SCORE_WEIGHT";
export const ADD_QUESTION = "ADD_QUESTION";
export const ADD_QUESTION_MENJODOHKAN = "ADD_QUESTION_MENJODOHKAN";
export const ADD_QUESTION_TRUE_FALSE = "ADD_QUESTION_TRUE_FALSE";
export const SET_QUESTION = "SET_QUESTION";
export const DELETE_QUESTION = "DELETE_QUESTION";
//
export const SET_VIDEO_EMBED = "SET_VIDEO_EMBED";

export const ADD_ANSWEAR = "ADD_ANSWEAR";
export const ADD_CHOICE_MENJODOHKAN = "ADD_CHOICE_MENJODOHKAN";
export const ADD_CHOICE_ANSWER_MENJODOHKAN = "ADD_CHOICE_ANSWER_MENJODOHKAN";
export const SET_ANSWEAR = "SET_ANSWEAR";
export const SET_ANSWEAR_CASE_CHOICES = "SET_ANSWEAR_CASE_CHOICES";
export const SET_ANSWEAR_CHOICES = "SET_ANSWEAR_CHOICES";
export const DELETE_ANSWEAR = "DELETE_ANSWEAR";
export const DELETE_ANSWEAR_MENJODOHKAN = "DELETE_ANSWEAR_MENJODOHKAN";
export const DELETE_CASE_ANSWEAR_MENJODOHKAN =
  "DELETE_CASE_ANSWEAR_MENJODOHKAN";
export const SELECT_CORRECT_ANSWER = "SELECT_CORRECT_ANSWER";
//
export const ADD_ANSWEAR_TRUE_FALSE = "ADD_ANSWEAR_TRUE_FALSE";
export const SET_ANSWEAR_TRUE_FALSE = "SET_ANSWEAR_TRUE_FALSE";
export const DELETE_ANSWEAR_TRUE_FALSE = "DELETE_ANSWEAR_TRUE_FALSE";
export const SELECT_CORRECT_ANSWER_TRUE_FALSE =
  "SELECT_CORRECT_ANSWER_TRUE_FALSE";
//
export const ADD_ANSWEAR_SHORT = "ADD_ANSWEAR_SHORT";
export const SET_ANSWEAR_SHORT = "SET_ANSWEAR_SHORT";
export const DELETE_ANSWEAR_SHORT = "DELETE_ANSWEAR_SHORT";
export const SELECT_CORRECT_ANSWER_SHORT = "SELECT_CORRECT_ANSWER_SHORT";
//
export const ADD_ANSWEAR_MULTIPLE = "ADD_ANSWEAR_MULTIPLE";
export const SET_ANSWEAR_MULTIPLE = "SET_ANSWEAR_MULTIPLE";
export const DELETE_ANSWEAR_MULTIPLE = "DELETE_ANSWEAR_MULTIPLE";
export const SELECT_CORRECT_ANSWER_MULTIPLE = "SELECT_CORRECT_ANSWER_MULTIPLE";

export const REQUEST_SUBMIT_EXAM_SUCCESS = "REQUEST_SUBMIT_EXAM_SUCCESS";
export const ADD_IMAGE_QUESTION = "ADD_IMAGE_QUESTION";
export const ADD_IMAGE_FILE_QUESTION = "ADD_IMAGE_FILE_QUESTION";
export const REMOVE_IMAGE = "REMOVE_IMAGE";
export const ADD_TO_LIST_IMG = "ADD_TO_LIST_IMG";
export const ADD_IMAGE_ANSWER = "ADD_IMAGE_ANSWER";
export const ADD_IMAGE_FILE_ANSWER = "ADD_IMAGE_FILE_ANSWER";
export const REMOVE_IMAGE_ANSWER = "REMOVE_IMAGE_ANSWER";
// Action new case
export const SELECT_COURSE_NEW_CASE = "SELECT_COURSE_NEW_CASE";

//Action student
export const STUDENT_SUCCESS_LOGIN = "STUDENT_SUCCESS_LOGIN";
export const STUDENT_REQUEST_CASES = "STUDENT_REQUEST_CASES";
export const STUDENT_REQUEST_CASES_SUCCESS = "STUDENT_REQUEST_CASES_SUCCESS";
export const STUDENT_REQUEST_CASES_FAILURE = "STUDENT_REQUEST_CASES_FAILURE";
export const STUDENT_CHOOSE_CASE = "STUDENT_CHOOSE_CASE";
export const STUDENT_LOAD_CASE = "STUDENT_LOAD_CASE";
export const STUDENT_CHOOSE_ANSWER = "STUDENT_CHOOSE_ANSWER";
export const STUDENT_CHOOSE_ANSWER_TRUE_FALSE =
  "STUDENT_CHOOSE_ANSWER_TRUE_FALSE";
export const STUDENT_CHOOSE_ANSWER_MULTIPLE = "STUDENT_CHOOSE_ANSWER_MULTIPLE";
export const STUDENT_SET_ANSWER = "STUDENT_SET_ANSWER";
export const STUDENT_SET_ANSWER_SHORT = "STUDENT_SET_ANSWER_SHORT";
export const STUDENT_SUMBIT_ANSWER = "STUDENT_SUMBIT_ANSWER";
export const STUDENT_SAVE_ANSWER = "STUDENT_SAVE_ANSWER";
export const STUDENT_ALERT_TIME_1 = "STUDENT_ALERT_TIME_1";
export const STUDENT_WHEN_TIMER_ENDS = "STUDENT_WHEN_TIMER_ENDS";
export const STUDENT_REMOVE_CASE_ACTIVE = "STUDENT_REMOVE_CASE_ACTIVE";
export const STUDENT_GET_SERVER_TIME = "STUDENT_GET_SERVER_TIME";
export const STUDENT_ADD_LIST_IMG = "STUDENT_ADD_LIST_IMG";
export const STUDENT_ADD_IMAGE_ANSWER = "STUDENT_ADD_IMAGE_ANSWER";
export const STUDENT_REMOVE_IMAGE = "STUDENT_REMOVE_IMAGE";
export const STUDENT_ADD_IMAGE_FILE_ANSWER = "STUDENT_ADD_IMAGE_FILE_ANSWER";
