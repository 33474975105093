import React, { Component } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import { setInfoExam } from "../../../store/actions";
class DataTable extends Component {
	constructor(props) {
		super(props);
		this._isMounted = false;
		this.state = {
			alertSuccess: null,
		};
	}

	componentDidMount() {
		this._isMounted = true;
		if (this._isMounted) {
			const script = document.createElement("script");
			script.src = "assets/js/dataTable-content.js";
			script.async = true;
			document.body.appendChild(script);
		}
	}
	changeDateFormat = (date) => {
		let dateNew = moment(date, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY");
		return dateNew;
	};

	componentWillUnmount() {
		this._isMounted = false;
	}

	render() {
		return (
			<table
				className="table table-bordered text-center nowrap table-hover "
				id="dataTable_ScheduleScoring"
				style={{ width: "100%" }}
			>
				<thead>
					<tr>
						<th>Mata Pelajaran</th>
						<th>Kelas</th>
						<th>Nama Soal</th>
						<th>Tanggal Ujian</th>
						<th>Status</th>
						<th>Aksi</th>
					</tr>
				</thead>
				<tbody>
					{Object.values(this.props.listSchedule).map((member) => (
						<tr key={member.exam_schedule_id}>
							<td>
								<b>{member.courses.course_name}</b>
							</td>
							<td>{member.classes.class_label}</td>
							<td>{member.cases.name}</td>
							<td>{this.changeDateFormat(member.start_date)}</td>
							<td>
								{member.answered_status === true ? (
									"Sudah dikerjakan"
								) : (
									<b>
										<i>Belum dikerjakan</i>
									</b>
								)}
							</td>
							<td>
								<Link
									to={`/assessment/${member.course_id}/${member.exam_schedule_id}`}
								>
									<button
										className="btn btn-sm btn-outline-success mr-3"
										onClick={() =>
											this.props.setInfoExam(
												member.courses.course_name,
												member.classes.class_label,
												member.cases.name,
												this.changeDateFormat(member.start_date)
											)
										}
									>
										<i className="mdi mdi-tooltip-edit"></i> Koreksi
									</button>
								</Link>
							</td>
						</tr>
					))}
				</tbody>
			</table>
		);
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		setInfoExam: (courseName, className, caseName, dateSchedule) =>
			dispatch(setInfoExam(courseName, className, caseName, dateSchedule)),
	};
};

export default connect(null, mapDispatchToProps)(DataTable);
