import React, { Component } from "react";
import { connect } from "react-redux";
import "../../assets/css/login.css";
import axiosInstance from "../../store/services/AxiosService";
import { getDataTeacher } from "../../store/actions/index";
import SelectJenjang from "./components/SelectJenjang";
import SelectSchool from "./components/SelectSchool";
import { FailureMessageTeacher, FailureMessageTeacherBypass } from "./components/ErrorMessage.jsx";
import { withRouter } from "react-router-dom";
import PicTeacher from "../../assets/img/teacher.jpg";
import * as loadingAnimation from '../../assets/lottie/loading.json'
import lottie from 'lottie-web'
import queryString from 'query-string';
import CryptoJS from 'crypto-js';

class Login extends Component {
	constructor(props) {
		super(props);
		this.state = {
            bypass: false,
			email: "",
			password: "",
			listSchool: [],
			listSchoolByDegree: [],
			degreeSelected: null,
			schoolSelected: null,
			loadingAuth: false,
			responseLoginFailed: null,
		};
	}

    encrypt = (text) => {
        return CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(text));
    };

    decrypt = (data) => {
        return CryptoJS.enc.Base64.parse(data).toString(CryptoJS.enc.Utf8);
    };

    actionStateSuccess(data) {
        this.props.getDataTeacher(data, this.state.schoolSelected);
        this.setState({ loadingAuth: false });
        this.props.history.push("/teacher");
    }

    actionStateFailed() {
        if (this.state.bypass) {
            this.setState({
                loadingAuth: false,
                responseLoginFailed: FailureMessageTeacherBypass(),
            });
        } else {
            this.setState({
                loadingAuth: false,
                responseLoginFailed: FailureMessageTeacher(),
            });
        }
    }

    handleBypassLogin(teacher_id, api_url) {
        const url = `/schools/get/url?api_url=${api_url}`;
        this.setState({ bypass: true })
        axiosInstance
            .get(url)
            .then((response) => {
                const { api_url, api_name, id } = response.data
                const data = {
                    api_url: api_url,
                    label: api_name,
                    value: id
                }
                this.handleSelectSchool(data)
                this.login(teacher_id);
            }).catch(err => {
                alert("Data sekolah tidak ditemukan")
            });
    }

    login(teacher_id = null) {
        const isBypass = this.state.bypass;
        const url = isBypass ? `/users/teacher/${teacher_id}/school/${this.state.schoolSelected.value}` : `/users/teacher/school/${this.state.schoolSelected.value}/login`;

        // disini perlu dapatkan id berdasarkan api_url ke backend
        if (!isBypass) {
            const dataToPost = {
                email: this.state.email,
                password: this.state.password,
            };
            axiosInstance
                .post(url, dataToPost)
                .then((response) => {
                    if (response.data.status === true) { this.actionStateSuccess(response.data) } 
                    else { this.actionStateFailed() }
                })
                .catch(err => console.log(err));
        } else {
            axiosInstance
                .get(url)
                .then((response) => {
                    if (response.data) { 

                        
                        const {username,employee_id,name=false} = response.data
                        let data = 
                        {
                            user : 
                            {...response.data, 
                                data_employee:
                                {
                                    name:name ? name : username ,
                                    employee_id:employee_id
                                } 
                            }
                        }
                        

                        
                        // data['data_employee']['name'] = username
                        // data['data_employee']['employee_id'] = employee_id

                        this.actionStateSuccess(data) 
                    } 
                    else { this.actionStateFailed() }
                })
                .catch(err => console.log(err));
        }
    }

	componentDidMount() {
        const params = queryString.parse(window.location.search);
        if (params.t) {
            // this.decrypt(params.t) // ini adalah id teacher
            this.handleBypassLogin(this.decrypt(params.t), params.url)
        } else {
            let arrListDegree = [];
            axiosInstance.get(`schools/get/degree`).then((res) => {
                Object.values(res.data).map((item) => arrListDegree.push(item));
            });
            this.setState({ listSchool: arrListDegree });
        }
	}

	handleSubmit = (e) => {
		e.preventDefault();
		this.setState({ responseLoginFailed: null, loadingAuth: true });
		this.login();
	};

	handleInputEmail = (e) => {
		e.preventDefault();
		this.setState({
			email: e.target.value,
			responseLoginFailed: null,
		});
	};

	handleInputPassword = (e) => {
		e.preventDefault();
		this.setState({
			password: e.target.value,
			responseLoginFailed: null,
		});
	};

	handleSelectDegree = (degree) => {
		this.state.listSchool.forEach((item) => {
			if (item.value === degree) {
				this.setState({
					degreeSelected: degree,
					listSchoolByDegree: item.schools,
					schoolSelected: null,
				});
			}
		});
	};

	handleSelectSchool = (school) => {
		this.setState({ schoolSelected: school });
	};

	render() {
        const isBypass = this.state.bypass;

        if (isBypass && this.state.responseLoginFailed === null) {
            lottie.loadAnimation({
                container:  document.getElementById('animation'), // the dom element that will contain the animation
                renderer: 'svg',
                loop: true,
                autoplay: true,
                animationData: loadingAnimation.default,
            })
        }

		return (
			<div className="container" style={{ minHeight: "100vh" }}>
				<div className="d-flex align-items-center justify-content-center " style={{ minHeight: "100vh" }}>
					<div className="card-login align-self-center w-100" style={{ minHeight: "450px" }}>
						<div className="row" style={{ height: "100%" }}>
							<div className="col-sm d-flex align-items-center justify-content-center box-img-login">
								<img src={PicTeacher} className="img-landing-login align-self-center mx-auto" alt="" />
							</div>
							<div className="col-sm pt-2">
								<div className="auth-form-light text-center p-4">
                                {
                                    !isBypass ? (
                                        <div>
                                            <h4>Hai Guru, Apa kabar?</h4>
                                            <h6 className="font-weight-light">Masuk untuk lanjut</h6>
                                            <div className="mt-3">
                                                <SelectJenjang onSelectDegree={this.handleSelectDegree} listSchool={this.state.listSchool} />
                                            </div>
                                            {this.state.degreeSelected !== null ? (
                                                <div className="mt-3">
                                                    <SelectSchool onSelectSchool={this.handleSelectSchool} listSchoolByDegree={this.state.listSchoolByDegree} />
                                                </div>
                                            ) : null}

                                            {this.state.schoolSelected !== null ? (
                                                <form className="pt-3" onSubmit={this.handleSubmit}>
                                                    <div className="form-group">
                                                        <input type="email" className="form-control" id="emaiUser" placeholder="Email" style={{ fontSize: "0.9rem" }} required onChange={this.handleInputEmail} />
                                                    </div>
                                                    <div className="form-group">
                                                        <input type="password" className="form-control" id="passwordUser" placeholder="Password" style={{ fontSize: "0.9rem" }} required onChange={this.handleInputPassword} />
                                                    </div>
                                                    <div className="mt-3">
                                                        <button type="submit " className="btn btn-lg btn-gradient-primary" style={{ width: "100%" }}>
                                                            {this.state.loadingAuth === true ? (
                                                                <div>
                                                                    <i className="mdi mdi-loading mdi-spin"></i> Masuk{" "}
                                                                </div>
                                                            ) : (
                                                                "Masuk"
                                                            )}
                                                        </button>
                                                        {this.state.responseLoginFailed}
                                                    </div>
                                                </form>
                                            ) : null}
                                        </div>
                                    ) : 
                                    (<div>
                                        <h4>Mendeteksi credential otomatis</h4>
                                        { this.state.responseLoginFailed }
                                        <div id="animation" style={{width: "85%", display: "inline-flex"}}></div>
                                    </div>)
                                }
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		getDataTeacher: (dataTeacher, dataSchool) => dispatch(getDataTeacher(dataTeacher, dataSchool)),
	};
};

export default withRouter(connect(null, mapDispatchToProps)(Login));
