import React, { Component } from "react";
import Schedules from "./Schedules.jsx";

export default class DataTable extends Component {
	componentDidMount() {
		const script = document.createElement("script");
		script.src = "assets/js/dataTable-content.js";
		script.async = true;
		document.body.appendChild(script);
	}
	componentWillUnmount() {}
	render() {
		return (
			<div className="table-responsive">
				<table className="table display table-bordered text-center table-hover nowrap" id="dataTable">
					<thead>
						<tr>
							<th style={{ padding: "15px" }}>Mata Pelajaran</th>
							<th style={{ padding: "15px" }}>Jadwal</th>
							<th style={{ padding: "15px" }}>Keterangan</th>
							<th style={{ padding: "15px" }}>Aksi</th>
						</tr>
					</thead>
					<tbody>
						{Object.values(this.props.listSchedule).map((member) => (
							<Schedules member={member} key={member.exam_schedule_id} />
						))}
					</tbody>
				</table>
			</div>
		);
	}
}
