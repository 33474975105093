import React, { Fragment } from "react";
import Footer from "../../footer";
import { Prompt, Redirect } from "react-router-dom";
import Question from "./Question.jsx";
import { connect } from "react-redux";
import Spinner from "../../../assets/Spinner.gif";
import {Howl, Howler} from 'howler';
import Ball from "../../../assets/Ball.svg";
import SweetAlert from "react-bootstrap-sweetalert";
import axiosInstance from "../../../store/services/AxiosService";
import { deleteCaseActive, loadCaseSuccess, submitAnswer, saveAnswer } from "../../../store/actions/index";


var that;
var playAudio = function () {
	var sound = new Howl({
		src: ['https://soundbible.com/mp3/Air%20Horn-SoundBible.com-964603082.mp3'],
		volume: 1,
	});

	setTimeout(() => {
		sound.play()
	}, 1000);
}

window.onBlurHandler = function (e) {
	console.log('has blur');
	if (that.state.isFocus && !that.state.isLocked) {
		that.setState({isFocus: false})
	}
}

window.onFocusHandler = function (e) {
	console.log("has focus")
	if (!that.state.isFocus) {
		that.setState({isFocus: true})
	}
};

class FormQuestion extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			alertSave: null,
			taskDone: false,
			alertSubmit: null,
			submitCases: false,
            countDown: 5,
            initCountDown: 5,
			isFocus: true,
			isLocked: false,
		};
	}
	fetchDataCase = async () => {
		let res = await axiosInstance.get(`/cases/${this.props.match.params.case}?schedule_id=${this.props.currentCase.schedule_id}&type_user=student&student_id=${this.props.idStudent}`);
        this.props.loadCaseSuccess(res.data);
        localStorage.setItem("dataSoal", JSON.stringify(res.data));
	};

	async componentDidMount() {
		const fromLocalStorage = JSON.parse(localStorage.getItem("reduxState"));
		const soalFromLocalStorage = JSON.parse(localStorage.getItem("dataSoal"));
		if (fromLocalStorage.User.caseStudent.case_parts === undefined) {
			await this.fetchDataCase();
		} else if (soalFromLocalStorage === undefined) {
			await this.fetchDataCase();
		}
		
		if (window.MathJax) {
			window.MathJax.Hub.Queue(["Typeset", window.MathJax.Hub, document.getElementsByTagName("body")]);
		}

		that = this;
		this.getAccess()
		window.addEventListener('blur', window.onBlurHandler);
		window.addEventListener('focus', window.onFocusHandler);
	}

	handleSave(e, status) {
		e.preventDefault();
		const getAlert = () => (
			<SweetAlert custom showCancel showCloseButton confirmBtnText="Ya" cancelBtnText="Tidak" confirmBtnBsStyle="primary" cancelBtnBsStyle="light" title="Simpan Sementara Jawaban Kamu?" 
			// saveTemperorary in reduce caseStudent change = true
			onConfirm={() => this.saveExam(this.props.idStudent)} 
			onCancel={() => this.setState({ alertSave: null })}>
				Klik 'Ya' untuk menyimpan jawaban kamu
			</SweetAlert>
		);
		const alertTimesUp = () => (
			<SweetAlert warning title="Kamu Tidak Dapat Simpan Sementara" onConfirm={() => this.setState({ alertSave: null })} onCancel={() => this.setState({ alertSave: null })}>
				Serahkan Jawaban Untuk Menyimpan Jawaban Kamu !
			</SweetAlert>
		);

		if (status === true) {
			this.setState({
				alertSave: getAlert(),
			});
		} else {
			this.setState({
				alertSave: alertTimesUp(),
			});
		}
	}

	handleDeleteImage(fileToDelete) {
		const url = "/files/delete";
		if (fileToDelete.length === 0) {
			fileToDelete.push("");
		}
		const dataToDel = { file_name: fileToDelete };
		axiosInstance
			.post(url, dataToDel)
			.then((r) => {})
			.catch((error) => {
				alert(`Proses delete gagal => ${error.message}`);
			});
	}

	deleteImageToSave(arrContent, arrAllFile) {
		console.log(arrContent, arrAllFile);
		let arrLama = [];
		if (arrAllFile !== null) {
			arrLama = arrAllFile;
		}

		arrContent.map((item) => {
			console.log(item);
			return (arrLama = arrLama.filter((i) => i !== item));
		});
		console.log(arrLama);
		this.handleDeleteImage(arrLama);
	}

	saveExam(id_student) {
		this.setState({ alertSave: null });
		let arrImgContent = [];
		Object.values(this.props.caseStudent.case_parts).map((part) => {
			Object.values(part.case_questions).map((question) => {
				if (question.answer.file_attached !== null) {
					arrImgContent.push(question.answer.file_attached);
				}
				return arrImgContent;
			});
			return arrImgContent;
		});
		this.deleteImageToSave(arrImgContent, this.props.listImgAll);
		const url = `/student_answers/create/student/${id_student}?save=true`;
		const dataToPost = { studentAnswer: this.props.caseStudent };
		axiosInstance
			.post(url, dataToPost)
			.then(() => {
				// saveTemperorary in reduce caseStudent change = true
				this.props.saveAnswer();
				this.handleSuccessSave();
			})
			.catch((error) => {
				this.handleFailureSave(error);
			});
	}

	handleSuccessSave() {
		const alertSuccess = () => (
			<SweetAlert success title="Berhasil !" onConfirm={() => this.refreshAfterSave()}>
				Jawabanmu berhasil disimpan !
			</SweetAlert>
		);
		this.setState({
			alertSave: alertSuccess(),
		});
	}

	refreshAfterSave() {
		this.setState({
			alertSave: null,
		});
		window.location.reload(false);
	}

	handleFailureSave(errorMessage) {
		const alertFailure = () => (
			<SweetAlert warning title="Proses Gagal!" onConfirm={() => this.setState({ alertSave: null })} onCancel={() => this.setState({ alertSave: null })}>
				{errorMessage}
			</SweetAlert>
		);
		this.setState({
			alertSave: alertFailure(),
		});
	}

	handleSubmit(e) {
		e.preventDefault();
		this.setState({
			alertSubmit: "submit",
            countDown: this.state.initCountDown,
		});
	}

	submitExam(id_student) {
		this.setState({ alertSave: null, alertSubmit: null });
		let arrImgContent = [];
		Object.values(this.props.caseStudent.case_parts).map((part) => {
			Object.values(part.case_questions).map((question) => {
				if (question.answer.file_attached !== null) {
					arrImgContent.push(question.answer.file_attached);
				}
				return arrImgContent;
			});
			return arrImgContent;
		});	
		this.deleteImageToSave(arrImgContent, this.props.listImgAll);
		const url = `/student_answers/create/student/${id_student}`;
		const dataToPost = { studentAnswer: this.props.caseStudent };
		axiosInstance
			.post(url, dataToPost)
			.then((response) => {
				this.props.submitAnswer();
				this.handleSuccessSubmit();
				this.setState({ submitCases: true });
			})
			.catch((error) => {
				this.handleFailureSubmit(error);
			});
	}

	handleSuccessSubmit() {
		const alertSuccess = () => (
			<SweetAlert
				success
				title="Berhasil !"
				onConfirm={() =>
					this.setState({
						taskDone: true,
					})
				}
			>
				Jawabanmu berhasil disimpan !
			</SweetAlert>
		);
		this.setState({
			alertSubmit: alertSuccess(),
		});
	}

	handleFailureSubmit(errorMessage) {
		const alertFailure = () => (
			<SweetAlert warning title="Proses Gagal!" onConfirm={() => this.setState({ alertSubmit: null })} onCancel={() => this.setState({ alertSubmit: null })}>
				{errorMessage}
			</SweetAlert>
		);
		this.setState({
			alertSubmit: alertFailure(),
		});
	}

	getAccess() {
		axiosInstance
			.post('/access/get', {
				"case_id": this.props.currentCase.case_id,
				"api_student_id": this.props.idStudent,
				"student_name": this.props.studentName,
				"schedule_id": this.props.caseStudent.schedules.id,
				"school_id": this.props.currentCase.school_id,
			})
			.then((r) => {
				this.setState({ isLocked: r.data.is_locked })
			})
			.catch((error) => {
				// not found
				console.log(`ERROR from Server => ${error.message}`);
			});
	}

	unlockAccess(password) {
		axiosInstance
			.post('/access/unlock', {
				"case_id": this.props.currentCase.case_id,
				"api_student_id": this.props.idStudent,
				"student_name": this.props.studentName,
				"schedule_id": this.props.caseStudent.schedules.id,
				"school_id": this.props.currentCase.school_id,
				"password": password
			})
			.then((r) => {
				this.setState({ isLocked: false })
			})
			.catch((error) => {
				// not found
				console.log(`ERROR from Server => ${error.message}`);
			});
	}

	componentWillUnmount() {
		if (this.state.submitCases === true) {
			localStorage.removeItem("reduxState");
			localStorage.removeItem("dataSoal");
			this.props.deleteCaseActive();
		}
	}

	componentWillUpdate(prevProps, prevState) {
		console.log(prevState)
		if (!this.state.isFocus && !this.state.isLocked) {
			axiosInstance
				.post('/access/set', {
					"case_id": prevProps.currentCase.case_id,
					"api_student_id": prevProps.idStudent,
					"student_name": prevProps.studentName,
					"schedule_id": this.props.caseStudent.schedules.id,
					"is_generate": true,
					'school_id': prevProps.currentCase.school_id,
					"is_locked": true
				})
				.then((r) => {
					this.setState({ isLocked: true }, playAudio)
				})
				.catch((error) => {
					console.log(`ERROR from Server => ${error.message}`);
				});
		}
	}

	render() {
		if (this.state.taskDone === true) {
			return <Redirect to="/" />;
		}

		if (this.state.isLocked) {
			return <div>
				<SweetAlert custom 
					input
					required
					inputType="password"
					title="Akses dikunci!"
					placeholder="Masukkan PASSWORD"
					validationMsg="You must enter your password!"
					onConfirm={(response) => this.unlockAccess(response)}>
				</SweetAlert>
			</div>
		}

		return (
			<Fragment>
				<Prompt
					message={JSON.stringify({
						confirmText: "Ya",
						messageText: "Apakah kamu yakin keluar?",
						cancelText: "Batal",
					})}
				/>
				<div className="main-panel">
					<div className="content-wrapper">
						{this.props.caseStudent.case_parts === undefined ? (
							<div className="d-flex justify-content-center">
								<img src={Ball} className="img-fluid" alt="" />
							</div>
						) : (
							<div>
								<div className="page-header">
									<div className="page-title d-flex">
										<span className="page-title-icon bg-gradient-primary text-white mr-2">
											<i className="mdi mdi-lead-pencil"></i>
										</span>
										<h1 style={{ marginRight: "20px" }}>{this.props.caseStudent.name}</h1>
										<label className="badge badge-gradient-danger align-self-center">{this.props.currentCase.course_name}</label>
									</div>
								</div>

								<div className="row">
									<div className="col-12 grid-margin">
                                        <div className="alert alert-warning" role="alert">
                                            Jika ada yang salah pada data soal, silakan refresh halaman ini!
                                        </div>
										<div className="card custom-card-shadow">
											<div className="card-body">
												<div className="">
													<form>
														{Object.values(this.props.caseStudent.case_parts).map((member, indexPart) => (
															<div className="form-group" key={member.id}>
																<h3 className="font-weight-bolder section-title d-inline">{"Bagian " + (indexPart + 1) + ". " + member.name}</h3>
																<h6 className="card-subtitle mb-2 text-muted">
																	<i>Kerjakan dengan baik.</i>
																</h6>
																<div>
																	{Object.values(member.case_questions).map((item, index) => (
																		<Question typeSoal={member.type_name} itemQuestion={item} indexQuestion={index} casePartId={item.case_part_id} key={index} indexPart={indexPart} />
																	))}
																</div>
															</div>
														))}

														<div className="dropdown-divider mt-5 mb-5 "></div>

														<div className="text-center">
															<button disabled={this.props.isRemedial} type="submit" className="btn btn-gradient-primary mr-2 " onClick={(e) => this.handleSave(e, this.props.timer.status)}>
																Simpan Sementara
															</button>
															<button type="submit" className="btn btn-gradient-danger mr-2 " onClick={(e) => this.handleSubmit(e)}>
																Serahkan Jawaban
															</button>
															{this.state.alertSave}
															{this.state.alertSubmit === 'submit' && !this.state.submitCases ? 
                                                                <SweetAlert custom 
                                                                    showCancel 
                                                                    disabled={this.state.countDown !== 0}
                                                                    showCloseButton 
                                                                    confirmBtnText="Ya" 
                                                                    cancelBtnText="Tidak" 
                                                                    confirmBtnBsStyle="primary" 
                                                                    cancelBtnBsStyle="light" 
                                                                    title="Serahkan Jawaban Kamu?" 
                                                                    onConfirm={() => this.submitExam(this.props.idStudent)} 
                                                                    onCancel={() => this.setState({ alertSubmit: null, countDown: this.state.initCountDown })}
                                                                    afterMount={() => {
                                                                        let currCountDown = this.state.countDown;
                                                                        var countDownInterval = setInterval(() => {
                                                                            this.setState({ countDown: currCountDown-- })
                                                                            if (this.state.countDown === 0 || this.state.alertSubmit !== 'submit') {
                                                                                clearInterval(countDownInterval);
                                                                            }
                                                                        }, 1000)
                                                                    }}>
                                                                    <div style={{marginTop: "1rem", marginBottom: "1rem", lineHeight: "1.5"}}>Dengan Menyerahkan Jawaban, Maka Kamu Tidak Bisa Mengerjakan Kembali</div>
                                                                    {this.state.countDown !== 0 ? <p style={{color: "red", marginTop: "2rem", fontSize: "1", fontWeight: "400", lineHeight: "1.5"}}>Tombol akan aktif dalam {this.state.countDown} detik</p> : ''}
                                                                </SweetAlert> : this.state.alertSubmit }
														</div>
													</form>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						)}
					</div>
					<Footer />
				</div>
			</Fragment>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		caseStudent: state.User.caseStudent,
		timer: state.User.timer,
		currentCase: state.User.currentCase,
		idStudent: state.User.dataStudent.student.api_student_id,
		listImgAll: state.User.caseStudent.listImg,
        isRemedial: state.User.currentCase.is_remedial,
		studentName: state.User.dataStudent.student.name
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		loadCaseSuccess: (list) => dispatch(loadCaseSuccess(list)),
		deleteCaseActive: () => dispatch(deleteCaseActive()),
		submitAnswer: () => dispatch(submitAnswer()),
		saveAnswer: () => dispatch(saveAnswer()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(FormQuestion);