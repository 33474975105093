import React, { Component } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { setEditable } from "../../../store/actions/index";

class TableListCase extends Component {
	constructor(props) {
		super(props);
		this._isMounted = false;
		this.state = {
			alertDelete: null,
			alertSuccess: null,
		};
	}

	componentDidMount() {
		this._isMounted = true;
		if (this._isMounted) {
			const script = document.createElement("script");
			script.src = "assets/js/dataTable-content.js";
			script.async = true;
			document.body.appendChild(script);
		}
	}

	changeDateFormat = (date) => {
		let dateNew = moment(date, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY");
		return dateNew;
	};

	componentWillUnmount() {
		this._isMounted = false;
	}

	render() {
		console.log(this.props.listSoal);
		return (
			<table className="table table-bordered text-center responsive nowrap table-hover " id="dataTable_ListCase" style={{ width: "100%" }}>
				<thead>
					<tr>
						<th>Mata Pelajaran</th>
						<th>Nama Soal</th>
						<th style={{width: "8rem"}}>Terakhir diubah</th>
                        <th style={{width: "5rem"}}>Soal</th>
						<th style={{width: "9rem"}}>Status</th>
						<th>Aksi</th>
					</tr>
				</thead>
				<tbody>
					{Object.values(this.props.listSoal).map((member) => (
						<tr key={member.id}>
							<td style={{overflow: "hidden", maxWidth: "24vw", textOverflow: "ellipsis"}}>
								<b>{member.course_name}</b>
							</td>
							<td style={{overflow: "hidden", maxWidth: "24vw", textOverflow: "ellipsis"}}>{member.name}</td>
							<td style={{width: "8rem"}}>{this.changeDateFormat(member.updated_at)}</td>
                            <td style={{width: "5rem"}}>{member.total_cases}</td>
							<td style={{width: "9rem"}}>
								{member.editable === true ? (
									"Belum Digunakan"
								) : (
									<b>
										<i>Digunakan</i>
									</b>
								)}
							</td>
							<td className="p-2">
								<Link to={`/edit-exam/${member.api_course_id}/${member.id}`} className="w-50">
									<button className="btn btn-sm btn-gradient-success mr-3" onClick={() => this.props.setEditable(member.editable)}>
										<i className="mdi mdi-tooltip-edit"></i> Ubah
									</button>
								</Link>
								<button disabled={!member.editable} className="btn btn-sm btn-danger px-2" onClick={() => this.props.onClickDeleteCase(member.id, member.editable)}>
									<i className="mdi mdi-delete"></i> Hapus
								</button>
							</td>
						</tr>
					))}
				</tbody>
			</table>
		);
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		setEditable: (status) => dispatch(setEditable(status)),
	};
};

export default connect(null, mapDispatchToProps)(TableListCase);
