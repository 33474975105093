import React from "react";
import Footer from "../../footer";
import {connect} from "react-redux";
import CasesBox from "./CasesBox.jsx";
import Spinner from "../../../assets/Spinner.gif";
import Ball from "../../../assets/Ball.svg";
import {fetchUserFailure, deleteCaseActive} from "../../../store/actions/index";
import axiosInstance from "../../../store/services/AxiosService";
import moment from "moment";

class HomeStudent extends React.Component {
	constructor(props) {
		super(props);
		this._isMounted = false;
		this.state = {
			scheduleGrouped: {
				activeSchedule: [],
				nonActiveSchedule: [],
			},
			listScheduleStatus: false,
		};
		

	}
	// get list data jadwal by siswa and scholl
	componentDidMount() {
		// cek state
		
		this._isMounted = true;
		if (this._isMounted) {
			//this.props.fetchUserSuccess(null);

			axiosInstance
				.get(`/schedules/student/${this.props.idStudent}/school/${this.props.dataSchool.value}`) 
				.then((response) => {

					// load data from api (list data schedule card box)
                    // kalau mau debug, tinggal di set jadi false
					const listSchedule = (true) ? response.data : response.data.map(element => {
                        element['others']['end_time'] = new moment().add(2, "days").format("YYYY-MM-DD HH:mm:ss")
                        return element;
                    });

					const sortedArray = listSchedule.sort((a, b) => new moment(a.others.start_time, "YYYY-MM-DD HH:mm:ss").format("YYYYMMDD") - new moment(b.others.start_time, "YYYY-MM-DD HH:mm:ss").format("YYYYMMDD"));

					// sortir data scchedule card box by answer true or false
				  // by key answered_status = false or true
					// change key answered_status after klik btn serahkan jawaban soal
					Object.values(sortedArray).map((item) => {
						if (item.answered_status === true) {
							this.setState({
								scheduleGrouped: {
									...this.state.scheduleGrouped,
									nonActiveSchedule: [...this.state.scheduleGrouped.nonActiveSchedule, item],
								},
							});
						} else if (item.answered_status === false) {
							this.setState({
								scheduleGrouped: {
									...this.state.scheduleGrouped,
									activeSchedule: [...this.state.scheduleGrouped.activeSchedule, item],
								},
							});
						}
						return null;
					});
					this.setState({
						listScheduleStatus: true,
					});
					// this.props.fetchUserSuccess(this.state.scheduleGrouped);
				})
				.catch((error) => {
					this.setState({
						listScheduleStatus: true,
					});
					const errMsg = error.message;
					this.props.fetchUserFailure(errMsg);
				});
		}
	}
	
	componentWillUnmount() {
		this._isMounted = false;
	}

	changeDateFormat = (date) => {
		let dateNew = moment(date, "YYYY-MM-DD HH:mm:ss");
		return dateNew;
	};
	render() {
		return (
			<div className="main-panel">
				<div className="content-wrapper">
					<div className="page-header">
						<h3 className="page-title">
							<span className="page-title-icon bg-gradient-primary text-white mr-2">
								<i className="mdi mdi-home"></i>
							</span>
							Daftar Mata Pelajaran
						</h3>
					</div>
					<div>
						{this.state.listScheduleStatus === false || this.props.serverTime === undefined ? (
							<div className="d-flex justify-content-center">
								<img src={Ball} alt="" />
							</div>
						) : (
							<div className="ml-4 mr-2" style={{width: "100%"}}>
								<ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
									<li className="nav-item" role="presentation">
										<a className="nav-link active text-black" id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">
											Jadwal Aktif
										</a>
									</li>
									<li className="nav-item" role="presentation">
										<a className="nav-link text-black" id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false">
											Riwayat Ujian
										</a>
									</li>
								</ul>
								<div className="tab-content" id="pills-tabContent">
									<div className="tab-pane fade show active " id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
										<div className="row">{this.state.scheduleGrouped.activeSchedule.length === 0 ? <i>Tidak ada Jadwal yang aktif</i> : Object.values(this.state.scheduleGrouped.activeSchedule).map((member) => <CasesBox member={member} key={member.id} schoolId={member.school_id} caseId={member.case_id} />)}</div>
									</div>
									<div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
										<div className="row">{this.state.scheduleGrouped.nonActiveSchedule.length === 0 ? <i className="ml-5">Riwayat Tidak Ditemukan</i> : Object.values(this.state.scheduleGrouped.nonActiveSchedule).map((member) => <CasesBox member={member} key={member.id} schoolId={member.school_id} caseId={member.case_id} />)}</div>
									</div>
								</div>
							</div>
						)}
					</div>
				</div>
				<Footer />
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		idStudent: state.User.dataStudent.student.api_student_id,
		dataSchool: state.User.dataSchool,
		// listSchedule: state.User.listSchedule,
		serverTime: state.User.time,
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		// fetchUserSuccess: (ListSchedule) => dispatch(fetchUserSuccess(ListSchedule)),
		fetchUserFailure: (error) => dispatch(fetchUserFailure(error)),
		deleteCaseActive: () => dispatch(deleteCaseActive()),
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(HomeStudent);
