import React from "react";
import { connect } from "react-redux";
import {
  chooseAnswer,
  chooseAnswerTrueFalse,
  chooseAnswerMultiple,
  setAnswer,
  setAnswerShort,
  addListImgStu,
  addImageAnsStu,
  removeImageStu,
  addImageFileAnsStu,
  chooseCaseChoice,
} from "../../../store/actions/index";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import IconButton from "@material-ui/core/IconButton";
import ImageIcon from "@material-ui/icons/Image";
import axiosInstance from "../../../store/services/AxiosService";
import imageCompression from "browser-image-compression";
import ImageUploader from "react-images-upload";
import { config1 } from "../component/ConfigEditor";
import Plyr from 'plyr';
const baseImgURL = process.env.REACT_APP_BACKEND_IMAGES2;

class QuestionStudent extends React.Component {
  // START of APRI
  constructor(props) {
    super(props);
    this.state = {
      tempCaseMenjodohkan: this.inisiateNullCaseMenjodohkan(),
    };
  }

  inisiateNullCaseMenjodohkan() {
    const tempCaseMenjodohkan = {
      case_part_id: null,
      case_question_id: null,
      case_choice_id: null,
    };
    return tempCaseMenjodohkan;
  }

  isPairedChoicesAnswer(idCaseChoicesAnswer) {
    return this.props.itemQuestion.answer.some(
      (value) => value.case_choices_answer_id === idCaseChoicesAnswer
    );
  }

  /**
   *
   * @param {hardcode for menjodohkan} type_case
   * @param {object} item
   */
  getColorFromAnswered(type_case, item) {
    if (
      (item.color != "" || item.color !== "" || item.color.length > 0) &&
      item.color !== undefined
    )
      return item.color; // str as array, if not "", return the color that set in User Reducer Load Cases, else return from logic below
    const colorFromAnswer = this.props.itemQuestion.answer.filter(
      (value) =>
        (type_case === "choice" && value.case_choice_id === item.id) ||
        (type_case === "answer" && value.case_choices_answer_id === item.id)
    );
    return colorFromAnswer.length > 0 ? colorFromAnswer[0]["color"] : "";
  }

  /**
   *
   * @param {hardcode for menjodohkan} type_case
   * @param {object} item
   * @param {integer} part_id
   */
  handleCaseMenjodohkan(type_case, item, part_id) {
    switch (type_case) {
      case "choice":
        // check if case choices already paired with case choices answer
        const filteredAnswer = this.props.itemQuestion.answer.filter(
          (value) => value.case_choice_id === item.id
        );

        if (
          filteredAnswer.length === 1 ||
          this.state.tempCaseMenjodohkan.case_choice_id === item.id
        ) {
          // must 1 -> unique id

          
          this.setState({
            tempCaseMenjodohkan: this.inisiateNullCaseMenjodohkan(),
          });
          if (filteredAnswer.length === 1) {
              // proses masukin angka
            const case_choices_answer_id =
            filteredAnswer[0]["case_choices_answer_id"];
            // prores toggling 
            this.props.chooseAnswerMultiple(
              null,
              item.id,
              item.case_question_id,
              part_id,
              case_choices_answer_id
              );
            }
        } else {
          this.setState({
            tempCaseMenjodohkan: {
              case_part_id: part_id,
              case_question_id: item.case_question_id,
              case_choice_id: item.id,
            },
          });
        }
        this.props.chooseAnswerMultiple(
          null,
          item.id,
          item.case_question_id,
          part_id
        ); // always call -> why? cause this function remove case_choice like toggle
        break;
      case "answer":
        const { case_question_id, case_choice_id, case_part_id } =
          this.state.tempCaseMenjodohkan;
        this.setState({
          tempCaseMenjodohkan: this.inisiateNullCaseMenjodohkan(),
        });
        this.props.chooseAnswerMultiple(
          null,
          case_choice_id,
          case_question_id,
          case_part_id,
          item.id
        );
        break;
      default:
        break;
    }
  }
  // end of APRI

  handleIconAddImg = (indexPart, indexQues) => {
    this.props.addImageAnsStu(indexPart, indexQues, null);
  };

  async handleUploadImg(e, indexPart, indexQues, fileOld, fileNew) {
    e.preventDefault();
    if (fileNew !== null || fileNew !== undefined) {
      this.props.addImageAnsStu(indexPart, indexQues, "Loading");
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 720,
        useWebWorker: true,
      };
      this.props.addListImgStu(fileOld);
      const compressedFile = await imageCompression(fileNew, options);

      var imgCompressed = new File([compressedFile], "uploaded_file.jpg", {
        type: "image/jpeg",
        lastModified: Date.now(),
      });

      let data = new FormData();
      data.append("file_attached", imgCompressed);
      data.append("old_file", null);
      const url = "/files/upload";
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      axiosInstance
        .post(url, data, config)
        .then((r) => {
          this.props.addImageAnsStu(indexPart, indexQues, r.data.file);
          this.props.addImageFileAnsStu(indexPart, indexQues, null);
          this.props.addListImgStu(r.data.file);
        })
        .catch((error) => {
          alert(`Proses upload gagal`);
        });
    }
  }
  async handleDeleteImage(e, fileName, indexPart, indexQues) {
    e.preventDefault();
    if (fileName !== null) {
      const data = { file_name: fileName };
      const url = "/files/delete";
      axiosInstance
        .post(url, data)
        .then((r) => {
          this.props.removeImageStu(indexPart, indexQues);
        })
        .catch((error) => {
          alert(`Proses delete gagal => ${error.message}`);
        });
    } else {
      this.props.removeImageStu(indexPart, indexQues);
    }
  }

  onDrop(pictureFiles, pictureDataURLs, indexPart, indexQues) {
    this.props.addImageFileAnsStu(indexPart, indexQues, pictureFiles[0]);
  }

  getIdVideoFromURL(url) {
      try {  
        var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
        var match = url.match(regExp);
        return (match&&match[7].length==11)? match[7] : false;
      } catch (error) {
        return ''
      }
  }

  componentDidMount(){
    // if(JSON.parse(localStorage.getItem('reload'))){
    //   localStorage.setItem("reload",false);
    //   setTimeout(() => {
    //     window.location.reload(true)
    //   }, 100);
    // }
    // const player = new Plyr(`#player_${Number(this.props.indexQuestion) + 1}_${this.getIdVideoFromURL(this.props.itemQuestion.video_embed)}`);
    Plyr.setup('.js-player');
    
  }

  render() {
    const itemQuestion = this.props.itemQuestion;
    const typeSoal = this.props.typeSoal;
    const { tempCaseMenjodohkan } = this.state;

    let playerVideo = '';
    if (itemQuestion.video_type !== 'unknown') {
        playerVideo = <div className="plyr__video-embed js-player" 
                        id={`player_${Number(this.props.indexQuestion) + 1}_${this.getIdVideoFromURL(itemQuestion.video_embed)}`} 
                        data-plyr-provider={itemQuestion.video_type} 
                        data-plyr-embed-id={this.getIdVideoFromURL(itemQuestion.video_embed)}>
                    </div>;
    }

    return (
      <div>
        <div className="form-group card-question">
          <div className="mb-4 border-bottom-1">
            <div className="d-flex">
              <div className="align-self-start title-exam mr-2">
                {Number(this.props.indexQuestion) + 1 + ". "}
              </div>
              <div className="enter-text align-self-start ml-2">
                {playerVideo}
                <div
                  className="descript-exam"
                  dangerouslySetInnerHTML={{
                    __html: itemQuestion.question,
                  }}
                ></div>
              </div>
            </div>
            {itemQuestion.file_attached !== null ? (
              <Zoom isZoomed={true}>
                <div className="stu-img-preview ml-3">
                  <img src={baseImgURL + itemQuestion.file_attached} alt="" />
                </div>
              </Zoom>
            ) : null}
          </div>

          {/* {itemQuestion.case_choices.length > 0 ? ( */}
          {typeSoal === "pilihan ganda" ? (
            <div className="form-check ml-3 bounderyChoices">
              <div className="row">
                {Object.values(itemQuestion.case_choices).map((item) => (
                  <div
                    key={item.id}
                    className={`col-4 pl-0 mb-3 ${
                      item.file_attached !== null ? "border" : ""
                    }`}
                  >
                    <div
                      key={item.id}
                      className={`p-2 ${
                        item.file_attached !== null ? "space-wrap" : ""
                      }`}
                    >
                      {item.file_attached !== null ? (
                        <Zoom isZoomed={true}>
                          <div className="stu-img-preview-ans">
                            <img src={baseImgURL + item.file_attached} alt="" />
                          </div>
                        </Zoom>
                      ) : null}
                      <div
                        className={`choices ${
                          this.props.timer.status === true ||
                          item.file_attached !== null
                            ? "btn-choicess"
                            : ""
                        }`}
                      >
                        <input
                          className={`form-check-input ${
                            item.file_attached !== null
                              ? "form-check-input-answer"
                              : ""
                          }`}
                          id={`choices_${this.props.casePartId}_${item.case_question_id}_${item.id}`}
                          type="radio"
                          name={"choices" + item.case_question_id}
                          value={item.value}
                          onChange={(e) =>
                            this.props.timer.status === true
                              ? this.props.chooseAnswer(
                                  e,
                                  item.id,
                                  item.case_question_id,
                                  this.props.casePartId
                                )
                              : null
                          }
                          // onChange={(e) => (this.props.chooseAnswer(e, item.id, item.case_question_id, this.props.casePartId) )}
                          checked={item.selected === true ? true : false}
                          // disabled={this.props.timer.status === false ? true : false}
                          disabled={
                            this.props.timer.status === false || 
                            (process.env.REACT_APP_REMEDIAL_FLAG_FALSE !== 'true' && itemQuestion.student_answers[0] != null && itemQuestion.student_answers[0]['is_correct'] && this.props.isRemedial) ? true : false
                          }
                        ></input>
                        <label className="form-check-label " for={`choices_${this.props.casePartId}_${item.case_question_id}_${item.id}`}>
                          {/* {item.value} */}
                          <div
                            dangerouslySetInnerHTML={{
                              __html: item.value,
                            }}
                          ></div>
                        </label>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : typeSoal === "pilihan ganda benar salah" ? (
            <div className="form-check ml-3 bounderyChoices">
              <div className="row">
                {Object.values(itemQuestion.case_choices).map((item) => (
                  <div
                    key={item.id}
                    className={`col-4 pl-0 mb-3 ${
                      item.file_attached !== null ? "border" : ""
                    }`}
                  >
                    <div
                      key={item.id}
                      className={`p-2 ${
                        item.file_attached !== null ? "space-wrap" : ""
                      }`}
                    >
                      {item.file_attached !== null ? (
                        <Zoom isZoomed={true}>
                          <div className="stu-img-preview-ans">
                            <img src={baseImgURL + item.file_attached} alt="" />
                          </div>
                        </Zoom>
                      ) : null}
                      <div
                        className={`choices ${
                          this.props.timer.status === true ||
                          item.file_attached !== null
                            ? "btn-choicess"
                            : ""
                        }`}
                      >
                        <input
                          className={`form-check-input ${
                            item.file_attached !== null
                              ? "form-check-input-answer"
                              : ""
                          }`}
                          type="radio"
                          id={`choices_ab_${this.props.casePartId}_${item.case_question_id}_${item.id}`}
                          name={"choices" + item.case_question_id}
                          value={item.value}
                          onChange={(e) =>
                            this.props.timer.status === true
                              ? this.props.chooseAnswerTrueFalse(
                                  e,
                                  item.id,
                                  item.case_question_id,
                                  this.props.casePartId
                                )
                              : null
                          }
                          // onChange={(e) => (this.props.chooseAnswer(e, item.id, item.case_question_id, this.props.casePartId) )}
                          checked={item.selected === true ? true : false}
                          // disabled={this.props.timer.status === false ? true : false}
                          disabled={
                            this.props.timer.status === false || 
                            (process.env.REACT_APP_REMEDIAL_FLAG_FALSE !== 'true' && itemQuestion.student_answers[0] != null && itemQuestion.student_answers[0]['is_correct'] && this.props.isRemedial) ? true : false
                          }
                        ></input>
                        <label className="form-check-label " for={`choices_ab_${this.props.casePartId}_${item.case_question_id}_${item.id}`}>
                          {/* {item.value} */}
                          <div
                            dangerouslySetInnerHTML={{
                              __html: item.value,
                            }}
                          ></div>
                        </label>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : typeSoal === "menjodohkan" ? (
            <div className="form-check ml-3 bounderyChoices">
              <div className="row">
                <div className="col-6">
                  {Object.values(itemQuestion.case_choices).map((item,index) => (
                    <div key={item.id} className={`col-6 pl-0 mb-3`}>
                      <div
                        key={item.id}
                        className={`p-2 d-flex justify-content-between align-items-center`}
                        style={{
                          "backgroundColor": this.getColorFromAnswered(
                            "choice",
                            item
                          ),
                        }}
                      >
                        <div className={`choices`}>
                          <input
                            className={`form-check-input ${
                              item.file_attached !== null
                                ? "form-check-input-answer"
                                : ""
                            }`}
                            id={`${item.case_question_id}-${item.id}-choice`}
                            type="checkbox"
                            name={"choices" + item.case_question_id}
                            value={item.value}
                            onChange={(e) =>
                              this.props.timer.status === true
                                ? this.handleCaseMenjodohkan(
                                    "choice",
                                    item,
                                    this.props.casePartId
                                  )
                                : null
                            }
                            checked={item.selected}
                            // 1. ketika klik salah satu case_choices maka add attrb
                            //    status_case_choices_answer === true maka (disable in case_choices_answer = false)

                            // if else
                            // 2. ketika salah satu
                            // checked={item.selected === true ? true : false}
                            disabled={
                              this.props.timer.status === false ||
                              (tempCaseMenjodohkan.case_choice_id !== null &&
                                tempCaseMenjodohkan.case_choice_id !== item.id)
                                ? true
                                : false
                            }
                          ></input>
                          <label className="form-check-label" htmlFor={`${item.case_question_id}-${item.id}-choice`}>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: item.value,
                              }}
                            ></div>
                          </label>
                        </div>
                           <h3 className="font-weight-bold mb-0" style={{color:"white"}}>
                              {item.selected === true ? itemQuestion.answer.map(value => value.case_choice_id).indexOf(item.id) + 1 : ""}
                            </h3>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="col-6">
                  {Object.values(itemQuestion.case_choices_answers).map(
                    (item,index) => (
                      <div key={item.order} className={`col-6 pl-0 mb-3`}>
                        <div
                          key={item.order}
                          className={`p-2 d-flex justify-content-between align-items-center`}
                          style={{
                            "backgroundColor": this.getColorFromAnswered(
                              "answer",
                              item
                            ),
                          }}
                        >
                          <div className={`choices`}>
                            <input
                              className={`form-check-input`}
                              id={`${item.case_question_id}-${item.id}-answer`}
                              type="checkbox"
                              name={"choices" + item.case_question_id}
                              value={item.value}
                              onChange={(e) =>
                                this.props.timer.status === true
                                  ? this.handleCaseMenjodohkan(
                                      "answer",
                                      item,
                                      this.props.casePartId
                                    )
                                  : null
                              }
                              checked={item.selected === true ? true : false}
                              // 1. first load absolute undifine karena status_case_choices_answer
                              //    tidak ada
                              // 2. ketika klik salah satu case_choices maka tambahkan attrb
                              //    status_case_choices_answer === true
                              // 3. jika status_case_choices_answer === true makan disable=false
                              disabled={
                                tempCaseMenjodohkan.case_choice_id === null ||
                                this.isPairedChoicesAnswer(item.id)
                                  ? true
                                  : false
                              }
                            ></input>
                            <label className="form-check-label" htmlFor={`${item.case_question_id}-${item.id}-answer`}>
                                {/* {item.value} */}
                                <div
                                dangerouslySetInnerHTML={{
                                  __html: item.value,
                                }}
                              ></div>
                              {/* {itemQuestion.case_choices_answers[index].case_choice_id === undefined ? "" : itemQuestion.case_choices.map(items => items.id === item.id )  } */}
                              
                            </label>
                          </div>
                          <h3 className="font-weight-bold mb-0" style={{color:"white"}}>
                            {item.case_choice_id === undefined ? "" : itemQuestion.answer.map(value => value.case_choice_id).indexOf(item.case_choice_id) + 1 }
                          </h3>
                        </div>
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          ) : typeSoal === "pilihan ganda kompleks" ? (
            <div className="form-check ml-3 bounderyChoices">
              <div className="row">
                {Object.values(itemQuestion.case_choices).map((item) => (
                  <div
                    key={item.id}
                    className={`col-4 pl-0 mb-3 ${
                      item.file_attached !== null ? "border" : ""
                    }`}
                  >
                    <div
                      key={item.id}
                      className={`p-2 ${
                        item.file_attached !== null ? "space-wrap" : ""
                      }`}
                    >
                      {item.file_attached !== null ? (
                        <Zoom isZoomed={true}>
                          <div className="stu-img-preview-ans">
                            <img src={baseImgURL + item.file_attached} alt="" />
                          </div>
                        </Zoom>
                      ) : null}
                      <div
                        className={`choices ${
                          this.props.timer.status === true ||
                          item.file_attached !== null
                            ? "btn-choicess"
                            : ""
                        }`}
                      >
                        <input
                          className={`form-check-input ${
                            item.file_attached !== null
                              ? "form-check-input-answer"
                              : ""
                          }`}
                          id={`checkbox_${this.props.casePartId}_${item.case_question_id}_${item.id}`}
                          type="checkbox"
                          name={"choices" + item.case_question_id}
                          value={item.value}
                          onChange={(e) =>
                            this.props.timer.status === true
                              ? this.props.chooseAnswerMultiple(
                                  e,
                                  item.id,
                                  item.case_question_id,
                                  this.props.casePartId
                                )
                              : null
                          }
                          // onChange={(e) => (this.props.chooseAnswerMultiple(e, item.id, item.case_question_id, this.props.casePartId) )}

                          checked={item.selected === true ? true : false}
                          // disabled={this.props.timer.status === false ? true : false}
                          disabled={
                            this.props.timer.status === false ? true : false
                          }
                        ></input>
                        <label className="form-check-label " for={`checkbox_${this.props.casePartId}_${item.case_question_id}_${item.id}`}>
                          {/* {item.value} */}
                          <div
                            dangerouslySetInnerHTML={{
                              __html: item.value,
                            }}
                          ></div>
                          
                          
                        </label>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : typeSoal === "isian singkat" ? (
            <div className="row">
              <div className="col-md-11">
                {/* <CKEditor
									id={"choicesEssaySingkat" + this.props.indexQuestion}
									editor={ClassicEditor}
									data={itemQuestion.answer.value === null ? undefined : `<div>${itemQuestion.answer.value}</div>`}
									config={config1}
									onBlur={(event, editor) => {
										const data = editor.getData();
										if (this.props.timer.status === true) {
											// ---------
											this.props.setAnswerShort(data, null, itemQuestion.id, itemQuestion.case_part_id);
										}
									}}
									disabled={this.props.timer.status === false ? true : false}
								/> */}

                <input
                  id={"choicesEssaySingkat" + this.props.indexQuestion}
                  type="text"
                  className="form-control form-control-isian-singkat"
                  value={
                    itemQuestion.answer.value === null
                      ? undefined
                      : itemQuestion.answer.value
                  }
                  onChange={(e) =>
                    this.props.timer.status === true
                      ? this.props.setAnswerShort(
                          e.target.value,
                          null,
                          itemQuestion.id,
                          itemQuestion.case_part_id
                        )
                      : ""
                  }
                  disabled={this.props.timer.status === false || 
                        (process.env.REACT_APP_REMEDIAL_FLAG_FALSE !== 'true' && itemQuestion.student_answers[0] != null && itemQuestion.student_answers[0]['is_correct'] && this.props.isRemedial) ? true : false}
                  placeholder="Tulis Jawaban"
                />
              </div>
            </div>
          ) : (
            // this easay
            <div className="row">
              <div className="col-md-11">
                <CKEditor
                  id={"choicesEssay" + this.props.indexQuestion}
                  editor={ClassicEditor}
                  data={
                    itemQuestion.answer.value === null
                      ? undefined
                      : `<div>${itemQuestion.answer.value}</div>`
                  }
                  config={config1}
                  onBlur={(event, editor) => {
                    const data = editor.getData();
                    if (this.props.timer.status === true) {
                      // ---------
                      this.props.setAnswer(
                        data,
                        null,
                        itemQuestion.id,
                        itemQuestion.case_part_id
                      );
                    }
                  }}
                  disabled={this.props.timer.status === false ? true : false}
                />

                {itemQuestion.answer.status_img === 1 ? (
                  <div className="d-flex align-items-start m-3">
                    <div className="box-imageAnswerPreview ">
                      <Zoom isZoomed={true}>
                        <div className="imageAnswerPreview ">
                          {itemQuestion.answer.file_attached === null ||
                          itemQuestion.answer.file_attached === "Loading" ? (
                            <div>
                              <span>Preview Gambar</span>
                            </div>
                          ) : (
                            <img
                              src={
                                baseImgURL + itemQuestion.answer.file_attached
                              }
                              alt=""
                              id="my-canvas"
                            />
                          )}
                        </div>
                      </Zoom>
                    </div>
                    {this.props.timer.status !== false ? ( // ---------
                      <div className="imgUploader ml-3">
                        <ImageUploader
                          className="imgUploader d-inline"
                          name="quesImg"
                          withIcon={true}
                          singleImage={true}
                          withPreview={true}
                          buttonText="Pilih Gambar"
                          onChange={(pictureFiles, pictureDataURLs) =>
                            this.onDrop(
                              pictureFiles,
                              pictureDataURLs,
                              this.props.indexPart,
                              this.props.indexQuestion
                            )
                          }
                          imgExtension={[".jpg", ".jpeg", ".png"]}
                          maxFileSize={2242880}
                          fileSizeError="Ukuran File Terlalu Besar"
                          label="Ukuran Maks: 2mb dengan format file .jpeg/.jpg/.png"
                        />

                        <div>
                          <button
                            className="btn btn-gradient-danger btn-sm mr-1 "
                            onClick={(e) =>
                              this.handleDeleteImage(
                                e,
                                itemQuestion.answer.file_attached,
                                this.props.indexPart,
                                this.props.indexQuestion
                              )
                            }
                          >
                            Hapus Gambar
                          </button>
                          <button
                            className="btn btn-gradient-primary btn-sm"
                            onClick={(e) =>
                              this.handleUploadImg(
                                e,
                                this.props.indexPart,
                                this.props.indexQuestion,
                                itemQuestion.answer.file_attached,
                                itemQuestion.answer.img_file
                              )
                            }
                            disabled={
                              itemQuestion.answer.img_file === null ||
                              itemQuestion.answer.img_file === undefined
                                ? true
                                : false
                            }
                          >
                            {itemQuestion.answer.file_attached === "Loading" ? (
                              <div>
                                <i className="mdi mdi-loading mdi-spin"></i>{" "}
                                Upload Gambar{" "}
                              </div>
                            ) : (
                              "Upload Gambar"
                            )}
                          </button>
                        </div>
                      </div>
                    ) : null}
                  </div>
                ) : null}
              </div>
              <div className="col-sm-auto">
                <IconButton
                  onClick={() => {
                    if (this.props.timer.status === true) {
                      //--------------
                      this.handleIconAddImg(
                        this.props.indexPart,
                        this.props.indexQuestion
                      );
                    }
                  }}
                >
                  <ImageIcon />
                </IconButton>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    timer: state.User.timer,
    isRemedial: state.User.currentCase.is_remedial,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    chooseAnswer: (event, answerId, caseId, partId) =>
      dispatch(chooseAnswer(event.target.value, answerId, caseId, partId)),
    chooseAnswerTrueFalse: (event, answerId, caseId, partId) =>
      dispatch(
        chooseAnswerTrueFalse(event.target.value, answerId, caseId, partId)
      ),
    chooseCaseChoice: (event, answerId, caseId, partId) =>
      dispatch(chooseCaseChoice(event.target.value, answerId, caseId, partId)),

    // NOTED APRI -> event.target.value
    chooseAnswerMultiple: (event, answerId, caseId, partId, choiceAnswerId) =>
      dispatch(
        chooseAnswerMultiple(event, answerId, caseId, partId, choiceAnswerId)
      ),
    setAnswerShort: (text, answerId, caseId, partId) =>
      dispatch(setAnswerShort(text, answerId, caseId, partId)),
    addListImgStu: (imageName) => dispatch(addListImgStu(imageName)),
    setAnswer: (text, answerId, caseId, partId) =>
      dispatch(setAnswer(text, answerId, caseId, partId)),
    addImageAnsStu: (index, indexQues, image) =>
      dispatch(addImageAnsStu(index, indexQues, image)),
    removeImageStu: (index, indexQues) =>
      dispatch(removeImageStu(index, indexQues)),
    addImageFileAnsStu: (index, indexQues, imageFile) =>
      dispatch(addImageFileAnsStu(index, indexQues, imageFile)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(QuestionStudent);
