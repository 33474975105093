import React from "react";
import Async from "react-select/async";

class SelectJenjang extends React.Component {
	state = {
		selectedOption: null,
	};

	handleChange = (selectedOption) => {
		this.setState({ selectedOption });
		this.props.onSelectDegree(selectedOption.value);
	};

	filterColors = (inputValue) => {
		return this.props.listSchool.filter((i) => i.label.toLowerCase().includes(inputValue.toLowerCase()));
	};
	loadOptions = (inputValue, callback) => {
        var intervalJenjangFetch = setInterval(() => {
            if (this.props.listSchool.length === 0) return;
            setTimeout(() => {
                callback(this.filterColors(inputValue));
            }, 500);
            clearInterval(intervalJenjangFetch);
            intervalJenjangFetch = null;
        }, 500)
	};
	render() {
		const { selectedOption } = this.state;
		return <Async cacheOptions value={selectedOption} onChange={this.handleChange} options={this.props.listSchool} defaultOptions loadOptions={this.loadOptions} placeholder="Pilih Jenjang" />;
	}
}

export default SelectJenjang;
