import React from "react";
import { Route, Redirect } from "react-router-dom";

const GoHome = (props) => {
	return (
		<Redirect
			to={{
				pathname: "/",
				state: {
					from: props.location,
				},
			}}
		/>
	);
};

const ProtectedRoute = ({ component: Component, isAuthenticated, roleUser, isRoleTrue, ...rest }) => {
	return (
		<Route
			{...rest}
			render={(props) => {
				if (roleUser === isRoleTrue) {
					if (isAuthenticated) {
						return <Component {...props} />;
					} else {
						return <GoHome {...props} />;
					}
				} else {
					return <GoHome {...props} />;
				}
			}}
		/>
	);
};

export default ProtectedRoute;
