import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { chooseCase } from "../../../store/actions/index";
import Circle from "../../../assets/img/circle.png";
import moment from "moment";

const listMonth = ["Januari", "Februari", "Maret", "April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", "Desember"];
class CasesBox extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isBtnDisable: false,
			dateNow: new Date(),
			messageCase: "",
		};
	}

	changeDateFormat = (date) => {
		let dateNew = moment(date, "YYYY-MM-DD HH:mm:ss").format("DD MM YYYY");
		let fields = String(dateNew).split(" ");
		return " " + fields[0] + " " + listMonth[parseInt(fields[1]) - 1] + " " + fields[2];
	};

	changeTimeFormat = (time) => {
		let dateNew = moment(time, "YYYY-MM-DD HH:mm:ss").format("HH mm");
		let fields = String(dateNew).split(" ");

		return fields[0] + "." + fields[1];
	};

	checkAvailable = (student_answer, timeExamStart, timeExamEnd) => {
		let dateExam = moment(timeExamStart, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD");
		let dateEnd = moment(timeExamEnd, "YYYY-MM-DD HH:mm:ss").format("DD-MM-YYYY HH:mm:ss");
		let dateNow = moment(new Date()).format("YYYY-MM-DD");
		let timeNow = moment(new Date().getTime()).format("HH:mm:ss");
		let timeStart = moment(timeExamStart, "YYYY-MM-DD HH:mm:ss").format("HH:mm:ss");

		if (student_answer === false) {
			if (dateNow < dateExam) {
				return <h4>Belum Dapat Diakses</h4>;
			} else if (dateNow === dateExam) {
				if (timeNow <= timeStart) {
					return <h4>Belum Dapat Diakses</h4>;
				} else {
					localStorage.setItem('reload',true)
					return (
						<Link to={`/exam/${this.props.caseId}`}>
							<button className="btn btn-gradient-primary" style={{ position: "relative" }} onClick={(e) => this.props.chooseCase(this.props.caseId, this.props.member.others.courses.course_name, this.props.member.others.courses.course_id, this.props.schoolId, dateEnd, this.props.member.others.schedule_id, this.props.member.is_remedial)}>
								<i className="mdi mdi-lead-pencil"></i> Jawab Soal
							</button>
						</Link>
					);
				}
			} else {
				// digunakan di page ketika mengerjakan soal mathjx
				localStorage.setItem('reload',true)
				return (
					<Link to={`/exam/${this.props.caseId}`}>
						<button className="btn btn-gradient-primary" style={{ position: "relative" }} onClick={(e) => this.props.chooseCase(this.props.caseId, this.props.member.others.courses.course_name, this.props.member.others.courses.course_id, this.props.schoolId, dateEnd, this.props.member.others.schedule_id, this.props.member.is_remedial)}>
							<i className="mdi mdi-lead-pencil"></i> Jawab Soal
						</button>
					</Link>
				);
			}
		} else {
			return <h4>Ujian Sudah Dikerjakan</h4>;
		}
	};

	render() {
		return (
			<div className="col-md-auto grid-margin ">
				<div className={`card card-img-holder text-white ${this.props.member.is_remedial ? "boxCase-danger" : "boxCase"}`}>
					<div className="card-body">
						<img src={Circle} className="card-img-absolute" alt="" />
						<h4 className="font-weight-bold mb-2">
							Mata Pelajaran {this.props.member.others.courses.course_name}
							{/* <i className="mdi mdi-note-text mdi-24px float-right"></i> */}
						</h4>
						<h4>Tipe Ujian : {this.props.member.others.exam_types.exam_type}</h4>
						<h4>Tanggal Ujian :{this.changeDateFormat(this.props.member.others.start_time)}</h4>
						<h4 className="card-text mb-3">
							Jam : {this.changeTimeFormat(this.props.member.others.start_time)} - {this.changeTimeFormat(this.props.member.others.end_time)} WIB
						</h4>

						<div className="d-flex justify-content-center">{this.checkAvailable(this.props.member.answered_status, this.props.member.others.start_time, this.props.member.others.end_time)}</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		chooseCase: (case_id, name_case, course_id, school_id, dateEnd, idSchedule, isRemedial) => dispatch(chooseCase(case_id, name_case, course_id, school_id, dateEnd, idSchedule, isRemedial)),
	};
};

export default connect(null, mapDispatchToProps)(CasesBox);
