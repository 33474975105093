import React from "react";

const appVersion = () => {
	return process.env.REACT_APP_VERSION ? 'Versi ' + process.env.REACT_APP_VERSION : ''
};

const Footer = () => (
	<footer className="footer text-center">
		<div>
			<span className="text-muted">
				<span>Copyright © 2020 - 2021. Soal Online MHS. Dikembangkan oleh tim dengan &hearts;. {appVersion()}</span>
			</span>
		</div>
	</footer>
);

export default Footer;
