import React, { Component } from "react";
import Footer from "../../footer";
import axiosInstance from "../../../store/services/AxiosService";
import { connect } from "react-redux";
import { removeStudentAnswer } from "../../../store/actions/index";
import Spinner from "../../../assets/Spinner.gif";
import Ball from "../../../assets/Ball.svg";
import { Link } from "react-router-dom";
import TableStudent from "./TableStudent.jsx";
class PenilaianStudent extends Component {
	constructor(props) {
		super(props);
		this._isMounted = false;
		this.state = {
			listSchedule: null,
			errorServer: false,
		};
	}
	componentDidMount() {
		this._isMounted = true;
		this._isMounted && this.fetchSchedule(this.props.match.params.schedule, this.props.dataSchool.api_url);
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	fetchSchedule = (idSchedule, url_sekolah) => {
		this.props.removeStudentAnswer();
		axiosInstance
			.get(`/student_answers?schedule_id=${idSchedule}&url_sekolah="${url_sekolah}"`)
			.then((res) => {
				this.setState({
					listSchedule: res.data,
				});
				console.log(res.data);
			})
			.catch((error) => {
				if (error.response.status === 422) {
					this.setState({ listSchedule: [], errorServer: true });
				}
			});
	};

	render() {
		console.log(this.state.listSchedule);
		return (
			<div className="main-panel">
				<div className="content-wrapper">
					<div className="page-header">
						<h3 className="page-title text-black">
							<span className="page-title-icon bg-gradient-primary text-white mr-2">
								<i className="mdi mdi-star-circle"></i>
							</span>
							<Link to="/assessment" className="text-black">
								Penilaian
							</Link>
							{" / "}
							List Siswa
						</h3>
						{this.state.errorServer === true ? (
							<nav aria-label="breadcrumb">
								<ul class="breadcrumb">
									<li class="breadcrumb-item active" aria-current="page" style={{ color: "red" }}>
										<span></span>Data Tidak Ditemukan Pada Server
										<i class="mdi mdi-alert-circle-outline icon-sm text-primary align-middle"></i>
									</li>
								</ul>
							</nav>
						) : null}
					</div>

					{this.state.listSchedule === null ? (
						<div className="text-center">
							<img src={Ball} alt=""></img>
						</div>
					) : (
						<div id="content-wrapper" className="d-flex flex-column">
							<div id="content">
								<div className="container-fluid">
									<div className="card shadow mb-4">
										<div className="card-header py-3 ">
											<div className="row">
												<div className="col-6 col-md-3 col-lg-2 font-weight-bold m-0">Mata Pelajaran</div>
												<div className="col-6 col-md-auto m-0">: {this.props.examInfo.courseName}</div>
											</div>
											<div className="row">
												<div className="col-6 col-md-3 col-lg-2 font-weight-bold m-0">Kelas</div>
												<div className="col-6 col-md-auto m-0">: {this.props.examInfo.className}</div>
											</div>
											<div className="row">
												<div className="col-6 col-md-3 col-lg-2 font-weight-bold m-0">Nama Ujian</div>
												<div className="col-6 col-md-auto m-0">: {this.props.examInfo.caseName}</div>
											</div>
											<div className="row">
												<div className="col-6 col-md-3 col-lg-2 font-weight-bold m-0">Tanggal Ujian</div>
												<div className="col-6 col-md-auto m-0">: {this.props.examInfo.dateSchedule}</div>
											</div>
										</div>
										<div className="card-body">
											<div className="table-responsive">
												<TableStudent listSchedule={this.state.listSchedule} course={this.props.match.params.course} schedule={this.props.match.params.schedule} />
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					)}
				</div>
				<Footer />
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		userId: state.User.dataTeacher.user.data_employee.employee_id,
		dataSchool: state.User.dataSchool,
		examInfo: state.User.examInfo,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		removeStudentAnswer: () => dispatch(removeStudentAnswer()),
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(PenilaianStudent);
