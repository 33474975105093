import React from "react";
import "../../index.css";
import "../../assets/css/student.css";
import HomeStudent from "./dashboard/Index.jsx";
import FormQuestion from "./formExam/FormQuestion.jsx";
import Assessment from "./assessment/Index.jsx";
import Timer from "./component/Timer.jsx";
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import PersonalPicture from "../../assets/img/student.svg";
import { connect } from "react-redux";
import { deleteCaseActive, deleteScheduleActive, userLogout, getServerTime } from "../../store/actions/index";
import userLeaveConfirmation from "../userConfirm/userLeaveConfirmation";
import SweetAlert from "react-bootstrap-sweetalert";
import NotFound from "../userConfirm/NotFound";
import axiosInstance from "../../store/services/AxiosService";
import moment from "moment";
import Spinner from "../../assets/Spinner.gif";
import Ball from "../../assets/Ball.svg";

function Notif() {
	return (
		<div>
			<li>
				<div className="alert alert-warning alert-dismissible fade show" role="alert">
					<strong>Waktumu HAMPIR Habis</strong> Yuk segera kirimkan jawabanmu
					<button type="button" className="close" data-dismiss="alert" aria-label="Close">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
			</li>
		</div>
	);
}

class IndexStudent extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			showTimer: true,
			alertLogout: null,
			showSidebar: true,
			clock: null,
		};
	}
	
	// baca pertama kali
	// get data dari server time sekarang
	componentDidMount() {
		axiosInstance.get(`/timeserver`).then((res) => {
			var dateNow = moment(res.data.server_time).format("HH:mm:ss");
			this.props.getServerTime(dateNow);
			// date time now
			this.myInterval = setInterval(() => {
				dateNow = moment(dateNow, "HH:mm:ss").add(1, "seconds").format("HH:mm:ss");
				// set state clock
				this.setState({
					clock: dateNow,
				});
			}, 1000);
		});
	}

	// menghentikan waktu ketika pindah dari component
	componentWillUnmount() {
		clearInterval(this.myInterval);
	}

	handleWhenLogout(e) {
		e.preventDefault();
		const getAlert = () => <SweetAlert custom showCancel showCloseButton confirmBtnText="Ya" cancelBtnText="Tidak" confirmBtnBsStyle="primary" cancelBtnBsStyle="light" title="Apakah Anda Ingin Keluar?" onConfirm={() => this.confirmLogout()} onCancel={() => this.hideAlert()}></SweetAlert>;

		this.setState({
			alertLogout: getAlert(),
		});
	}

	hideAlert() {
		this.setState({
			alertLogout: null,
		});
	}

	confirmLogout() {
		this.setState({
			alertLogout: null,
		});

        const url = `/users/logout`;
		const dataToPost = {
			nisn: this.props.dataStudent.nisn
		};

		axiosInstance
			.post(url, dataToPost)
			.then((response) => {
				if (response.data.status === true) {
					this.props.history.push("/");
				}
                this.props.deleteScheduleActive();
                this.props.userLogout();
                localStorage.removeItem("reduxState");
                this.props.history.push("/");
			})
			.catch((error) => {
				alert(error.response.data);
			});
	}

	sidebar() {
		this.setState({ showSidebar: !this.state.showSidebar });
		if (this.state.showSidebar === true) {
			document.body.classList.remove("sidebar-icon-only");
		} else {
			document.body.classList.add("sidebar-icon-only");
		}
	}

	handleDeleteActivity() {
		localStorage.removeItem("reduxState");
		localStorage.removeItem("dataSoal");
		this.props.deleteCaseActive();
	}

	handleDeleteImage() {
		const url = "/files/delete";
		const dataToDel = { file_name: this.props.listImage };
		axiosInstance
			.post(url, dataToDel)
			.then((r) => {
				this.handleDeleteActivity();
			})
			.catch((error) => {
				alert(`Proses delete gagal => ${error.message}`);
			});
	}

	render() {
		const setConfirmOpen = (status) => {
			this.setState({ showTimer: false });
			if (status === true) {
				if (this.props.isSubmitAnswer !== true) {
					if (this.props.listImage.length > 0) {
						this.handleDeleteImage();
					} else {
						this.handleDeleteActivity();
					}
				} else {
					this.handleDeleteActivity();
				}
			}
		};

		return (
			<Router
				getUserConfirmation={(message, callback) => {
					return userLeaveConfirmation(message, callback, setConfirmOpen);
				}}
				basename="/student"
			>
				<div className="container-scroller">
					<nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 pt-2 pb-1 fixed-top d-flex flex-row">
						<div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
							<Link to="/">
								<span className="navbar-brand brand-logo">
									<b>RuangKelas</b>
								</span>
								<span className="navbar-brand brand-logo-mini">
									<i className="mdi mdi-book-open-page-variant menu-icon"></i>
								</span>
							</Link>
						</div>
						<div className="navbar-menu-wrapper d-flex align-items-stretch">
							<button className="navbar-toggler navbar-toggler align-self-center" type="button" onClick={() => this.sidebar()}>
								<span className="mdi mdi-menu"></span>
							</button>

							<ul className="navbar-nav navbar-nav-right">
								{this.state.clock === null ? (
									<div>
										<img src={Ball} alt="" width="50" />
									</div>
								) : this.props.timer !== undefined ? (
									<div className="d-flex align-items-center">
										<div className="align-self-center mr-3">
											<li className="nav-item">{this.props.timer.alertShow === true ? <Notif /> : null}</li>
										</div>

										{this.props.timer.dateEnd !== null && (
											<div className="align-self-center">
												<li className="nav-item">
													<Timer />
												</li>
											</div>
										)}
									</div>
								) : (
									<div>
										<li className="nav-item">
											<h3>Jam: {this.state.clock}</h3>
										</li>
									</div>
								)}

								<li className="nav-item nav-profile dropdown">
									<button className="nav-link dropdown-toggle btnInvisible" id="profileDropdown" data-toggle="dropdown" aria-expanded="false">
										<div className="nav-profile-img">
											<img src={PersonalPicture} alt="" />
											<span className="availability-status online"></span>
										</div>
										<div className="nav-profile-text">
											<p className="mb-1 text-black">{this.props.dataStudent.name}</p>
										</div>
									</button>
									<div className="dropdown-menu navbar-dropdown" aria-labelledby="profileDropdown">
										<div className="dropdown-divider"></div>
										<button className="dropdown-item btnInvisible" onClick={(e) => this.handleWhenLogout(e)}>
											<i className="mdi mdi-logout mr-2 text-primary"></i>
											Keluar
										</button>
									</div>
								</li>
							</ul>
							<button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" data-toggle="offcanvas" onClick={() => this.sidebar()}>
								<span className="mdi mdi-menu"></span>
							</button>
						</div>
					</nav>
					{this.state.alertLogout}
					<div className="container-fluid page-body-wrapper">
						<div className="sidebar pt-4 sidebar-offcanvas position-fixed" id="sidebar">
							<ul className="nav">
								<li className="nav-item active">
									<Link className="nav-link" to="/">
										<span className="menu-title">Dashboard</span>
										<i className="mdi mdi-home menu-icon"></i>
									</Link>
								</li>

								<li className="nav-item active">
									<Link className="nav-link" to="/assessment">
										<span className="menu-title">Daftar Nilai</span>
										<i className="mdi mdi-star-circle menu-icon"></i>
									</Link>
								</li>
							</ul>
						</div>

						<div className="dashboard-panel">
							<Switch>
								<Route exact path="/" component={HomeStudent} />
								<Route exact path="/:exam/:case" component={FormQuestion} />
								<Route path="/assessment" component={Assessment} />

								<Route path="*" component={NotFound} />
							</Switch>
						</div>
					</div>
				</div>
			</Router>
		);
	}
}
const mapStateToProps = (state) => {
	return {
		dataStudent: state.User.dataStudent.student,
		timer: state.User.timer,
		listImage: state.User.caseStudent.listImg,
		isSubmitAnswer: state.User.caseStudent.statusSubmit,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		deleteCaseActive: () => dispatch(deleteCaseActive()),
		deleteScheduleActive: () => {
			dispatch(deleteScheduleActive());
		},
		userLogout: () => dispatch(userLogout()),
		getServerTime: (time) => dispatch(getServerTime(time)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(IndexStudent);
