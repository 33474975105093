export const loadState = () => {
	try {
		const serializedState = localStorage.getItem("reduxState");
		if (serializedState === null) {
			return undefined;
		}
		return JSON.parse(serializedState);
	} catch (err) {
		return undefined;
	}
};

export const saveState = (state) => {
	try {
		localStorage.setItem("reduxState", JSON.stringify(state.getState()));
	} catch (err) {}
};
