import React, { Component } from "react";
import { connect } from "react-redux";
import "../../assets/css/login.css";
import SelectJenjang from "./components/SelectJenjang";
import SelectSchool from "./components/SelectSchool";
import axiosInstance from "../../store/services/AxiosService";
import { getDataStudent, userLogout, deleteScheduleActive } from "../../store/actions/index";
import { FailureMessageStudent, FailureMessageUserLogin } from "./components/ErrorMessage.jsx";
import PicStudent from "../../assets/img/student.jpg";
import SweetAlert from "react-bootstrap-sweetalert";
import queryString from 'query-string';
import * as loadingAnimation from '../../assets/lottie/loading.json'
import lottie from 'lottie-web'

class Login extends Component {
	constructor(props) {
		super(props);
		this.state = {
			bypass: false,
			nisn: "",
			password: "",
			listSchool: [],
			listSchoolByDegree: [],
			degreeSelected: null,
			schoolSelected: null,
			loadingAuth: false,
			responseLoginFailed: null,
		};
	}
	componentDidMount() {
		const params = queryString.parse(window.location.search);
        if (params.s) {
            // this.decrypt(params.t) // ini adalah id teacher
            this.handleBypassLogin(params.s, params.url)
        } else {
			let arrListDegree = [];
			axiosInstance.get(`/schools/get/degree`).then((res) => {
				Object.values(res.data).map((item) => arrListDegree.push(item));
			});
			this.setState({ listSchool: arrListDegree });
		}
	}
	handleBypassLogin(student_id, api_url) {
		// this.props.logout()
        const url = `/schools/get/url?api_url=${api_url}`;
        this.setState({ bypass: true })
        axiosInstance
            .get(url)
            .then((response) => {
                const { api_url, api_name, id } = response.data
                const data = {
                    api_url: api_url,
                    label: api_name,
                    value: id
                }
				console.log(data)
                this.handleSelectSchool(data)
                this.login(student_id);
            }).catch(err => {
                alert("Data sekolah tidak ditemukan")
            });
    }
	handleSubmit = (e) => {
		e.preventDefault();
		this.setState({ responseLoginFailed: null, loadingAuth: true });
		this.login();
	};
	login(student_id = null) {
		const isBypass = this.state.bypass;		
        const url = isBypass ? `/users/student/${student_id}/school/${this.state.schoolSelected.value}` : `/users/student/school/${this.state.schoolSelected.value}/login`;
        // disini perlu dapatkan id berdasarkan api_url ke backend
        if (!isBypass) {
            const dataToPost = {
				nisn: this.state.nisn,
				password: this.state.password,
			};
			axiosInstance
				.post(url, dataToPost)
				.then((response) => {
					if (response.data.status === true) {
						this.props.getDataStudent(response.data, this.state.schoolSelected);
						this.setState({ loadingAuth: false });
						this.props.history.push("/student");
					} else {
						this.setState({
							loadingAuth: false,
							responseLoginFailed: FailureMessageStudent(),
						});
					}
				})
				.catch((error) => {
					this.setState({
						loadingAuth: false,
						responseLoginFailed: FailureMessageUserLogin(error.response.data),
					});
					// <SweetAlert custom showCancel showCloseButton confirmBtnText="Ya" cancelBtnText="Tidak" confirmBtnBsStyle="primary" cancelBtnBsStyle="light" title="Apakah Anda Ingin Keluar?"></SweetAlert>
					window.alert(error.response.data);
				});
        } else {
            axiosInstance
				.get(url)
				.then((response) => {
					if (response.data.status === true) {
						this.props.getDataStudent(response.data, this.state.schoolSelected);
						this.setState({ loadingAuth: false });
						this.props.history.push("/student");
					} else {
						this.setState({
							loadingAuth: false,
							responseLoginFailed: FailureMessageStudent(),
						});
					}
				})
				.catch((error) => {
					this.setState({
						loadingAuth: false,
						responseLoginFailed: FailureMessageUserLogin(error.response.data),
					});
					// <SweetAlert custom showCancel showCloseButton confirmBtnText="Ya" cancelBtnText="Tidak" confirmBtnBsStyle="primary" cancelBtnBsStyle="light" title="Apakah Anda Ingin Keluar?"></SweetAlert>
					window.alert(error.response.data);
				});
        }
	}

	handleInputNisn = (e) => {
		e.preventDefault();
		this.setState({
			nisn: e.target.value,
			responseLoginFailed: null,
		});
	};
	handleInputPassword = (e) => {
		e.preventDefault();
		this.setState({
			password: e.target.value,
			responseLoginFailed: null,
		});
	};
	handleSelectDegree = (degree) => {
		this.state.listSchool.forEach((item) => {
            if (item.value === degree) {
				this.setState({
					degreeSelected: degree,
					listSchoolByDegree: item.schools,
					schoolSelected: null,
				});
			}
		});
	};

	handleSelectSchool = (school) => {
		this.setState({ schoolSelected: school });
	};
	render() {
		const isBypass = this.state.bypass;

        if (isBypass && this.state.responseLoginFailed === null) {
            lottie.loadAnimation({
                container:  document.getElementById('animation'), // the dom element that will contain the animation
                renderer: 'svg',
                loop: true,
                autoplay: true,
                animationData: loadingAnimation.default,
            })
        }

		return (
			<div className="container" style={{ minHeight: "100vh" }}>
				<div className="d-flex align-items-center justify-content-center" style={{ minHeight: "100vh" }}>
					<div className="card-login align-self-center  w-100" style={{ minHeight: "450px" }}>
						<div className="row" style={{ height: "100%" }}>
							<div className="col-sm d-flex align-items-center justify-content-center box-img-login">
								<img src={PicStudent} className="img-landing-login align-self-center " alt="" />
							</div>
							<div className="col-sm pt-2">
								<div className="auth-form-light text-center p-4">
								{
                                    !isBypass ? (
                                        <div>
											<h4>Hai Siswa, Apa kabar?</h4>
											<h6 className="font-weight-light">Masuk untuk lanjut</h6>
											<div className="mt-3">
												<SelectJenjang onSelectDegree={this.handleSelectDegree} listSchool={this.state.listSchool} />
											</div>
											{this.state.degreeSelected !== null ? (
												<div className="mt-3">
													<SelectSchool onSelectSchool={this.handleSelectSchool} listSchoolByDegree={this.state.listSchoolByDegree} />
												</div>
											) : null}
											{this.state.schoolSelected !== null ? (
												<form className="pt-3" onSubmit={this.handleSubmit}>
													<div className="form-group">
														<input type="text" className="form-control" id="nisn" placeholder="NISN" style={{ fontSize: "0.9rem" }} required onChange={this.handleInputNisn} />
													</div>
													<div className="form-group">
														<input type="password" className="form-control" id="passwordUser" placeholder="Password" style={{ fontSize: "0.9rem" }} required onChange={this.handleInputPassword} />
													</div>
													<div className="mt-3">
														<button type="submit " className="btn btn-lg btn-gradient-primary" style={{ width: "100%" }}>
															{this.state.loadingAuth === true ? (
																<div>
																	<i className="mdi mdi-loading mdi-spin"></i> Masuk{" "}
																</div>
															) : (
																"Masuk"
															)}
														</button>
														{this.state.responseLoginFailed}
													</div>
												</form>
											) : null}
                                        </div>
                                    ) : 
                                    (<div>
                                        <h4>Mendeteksi credential otomatis</h4>
                                        { this.state.responseLoginFailed }
                                        <div id="animation" style={{width: "85%", display: "inline-flex"}}></div>
                                    </div>)
                                }
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		getDataStudent: (dataTeacher, dataSchool) => dispatch(getDataStudent(dataTeacher, dataSchool))
	};
};

export default connect(null, mapDispatchToProps)(Login);
