import React, { Fragment } from "react";
import Footer from "../../footer";
import Spinner from "../../../assets/Spinner.gif";
import Ball from "../../../assets/Ball.svg";
import { Link } from "react-router-dom";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
import axiosInstance from "../../../store/services/AxiosService";
import { connect } from "react-redux";
import {
  getStudentAnswer,
  setScoreAnswer,
  setScoreAnswerShort,
  showImgAssessment,
} from "../../../store/actions/index";
import SweetAlert from "react-bootstrap-sweetalert";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import { Redirect } from "react-router-dom";
import "../../../assets/css/assessment.css";
import Arrow from "../../../assets/img/Arrow";

const baseImgURL = process.env.REACT_APP_BACKEND_IMAGES2;
const IconCorrect = () => {
  return (
    <span
      className="mdi mdi-check mdi-36px ml-3 p-0"
      style={{ color: "blue" }}
    ></span>
  );
};

const IconIncorrect = () => {
  return (
    <span
      className="mdi mdi-close mdi-36px ml-3 p-0"
      style={{ color: "red" }}
    ></span>
  );
};

class PenilaianStudent extends React.Component {
  constructor(props) {
    super(props);
    this._isMounted = false;
    this.state = {
      alertSubmit: null,
      doneGrading: false,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this._isMounted && this.fetchStudentAnswer();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  fetchStudentAnswer() {
    axiosInstance
      .get(
        `/student_answers?schedule_id=${this.props.match.params.schedule}&url_sekolah="${this.props.dataSchool.api_url}"&student_id=${this.props.match.params.student_id}`
      )
      .then((res) => {
        const newData = {
          ...res.data,
          case_parts: Object.values(res.data.case_parts).map((part) => {
            if (part.type_name === "uraian") {
              return {
                ...part,
                case_questions: Object.values(part.case_questions).map(
                  (question) => {
                    if (question.student_answers.file_attached !== null) {
                      return {
                        ...question,
                        student_answers: {
                          ...question.student_answers,
                          // mungkin eror disini
                          // showImg: false,
                        },
                      };
                    } else {
                      return {
                        ...question,
                      };
                    }
                  }
                ),
              };
            } else {
              return {
                ...part,
              };
            }
          }),
        };

        this.props.getStudentAnswer(newData);
      })
      .catch((error) => {
        console.log(error.message);
      });
    this.setState({ alertSubmit: null });
  }

  getIndex = (id, stateData) => {
    var index = stateData
      .map(function (item) {
        return item.id;
      })
      .indexOf(id);
    return index;
  };

  handleInputScore = (indexPart, indexQues, newScore) => {
    newScore = parseFloat(newScore).toFixed(2);
    this.props.setScoreAnswer(indexPart, indexQues, Number(newScore));
  };
  handleInputScoreShort = (indexPart, indexQues, newScore) => {
    newScore = parseFloat(newScore).toFixed(2);
    this.props.setScoreAnswerShort(indexPart, indexQues, Number(newScore));
  };

  handleSubmit(e) {
    e.preventDefault();
    const getAlert = () => (
      <SweetAlert
        custom
        showCancel
        showCloseButton
        confirmBtnText="Ya"
        cancelBtnText="Tidak"
        confirmBtnBsStyle="primary"
        cancelBtnBsStyle="light"
        title="Apakah Anda Yakin ?"
        onConfirm={() => this.submitScore()}
        onCancel={() => this.setState({ alertSubmit: null })}
      >
        Penilaian Anda akan Kami simpan
      </SweetAlert>
    );
    this.setState({
      alertSubmit: getAlert(),
    });
  }

  submitScore() {
    const dataToPost = {
      studentAnswer: this.props.studentAnswer,
      // status: 1,
    };
    // console.log(dataToPost);
    axiosInstance
      .post(
        `/student_answers/correction/student/${this.props.match.params.student_id}`,
        dataToPost
      )
      .then((response) => {
        this.successSubmitScore();
      })
      .catch((error) => {
        this.failureSubmitScore(error.message);
      });
  }

  successSubmitScore() {
    const alertSuccess = () => (
      <SweetAlert
        success
        title="Berhasil !"
        // onConfirm={() => this.fetchStudentAnswer()}
        onConfirm={() => this.setState({ doneGrading: true })}
      >
        Nilai Berhasil Disimpan !
      </SweetAlert>
    );
    this.setState({
      alertSubmit: alertSuccess(),
    });
  }

  failureSubmitScore(errorMessage) {
    const alertFailure = () => (
      <SweetAlert
        warning
        title="Proses Gagal!"
        onConfirm={() => this.setState({ alertSubmit: null })}
        onCancel={() => this.setState({ alertSubmit: null })}
      >
        {errorMessage}
      </SweetAlert>
    );
    this.setState({
      alertSubmit: alertFailure(),
    });
  }

  checkAnwerPilgan(student, item) {
    let data = student.filter((itemss) => itemss.case_choice_id === item.id);
    if (data.length === 1) {
      return true;
    }
  }

  handleShowImg(e, indexPart, indexQues, lastStatus) {
    e.preventDefault();
    this.props.showImgAssessment(indexPart, indexQues, lastStatus);
  }
  render() {
    if (this.state.doneGrading === true) {
      return (
        <Redirect
          to={`/assessment/${this.props.match.params.course}/${this.props.match.params.schedule}`}
        />
      );
    }
    const studentAnswer = this.props.studentAnswer;
    return (
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="page-header">
            <h3 className="page-title">
              <span className="page-title-icon bg-gradient-primary text-white mr-2">
                <i className="mdi mdi-star-circle"></i>
              </span>
              <Link to="/assessment" className="text-black">
                Penilaian
              </Link>
              {" / "}
              <Link
                to={
                  "/assessment/" +
                  this.props.match.params.course +
                  "/" +
                  this.props.match.params.schedule
                }
                className="text-black"
              >
                List Siswa
              </Link>
              {" / "}Jawaban Siswa
            </h3>
          </div>

          {studentAnswer === null ? (
            <div className="text-center">
              <img src={Ball} alt=""></img>
            </div>
          ) : (
            <div id="content-wrapper" className="d-flex flex-column">
              <div id="content">
                <div className="container-fluid">
                  <div className="card shadow mb-4 ">
                    <div className="card-header py-3">
                      <div className="row">
                        <div className="col-12 col-sm">
                          <div className="row">
                            <div className="pr-0 col-3 col-sm-4 col-md-3 col-lg-2 font-weight-bold m-0">
                              Nama
                            </div>
                            <div className="col-9 col-md-auto m-0">
                              : {studentAnswer.name}
                            </div>
                          </div>
                          <div className="row">
                            <div className="pr-0 col-3 col-sm-4 col-md-3 col-lg-2 font-weight-bold m-0">
                              NISN
                            </div>
                            <div className="col-9 col-sm-auto m-0">
                              : {studentAnswer.nisn}
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-sm">
                          <div className="score text-right d-flex justify-content-end align-items-center mr-5">
                            <div className="font-weight-bolder ">
                              Nilai :{" "}
                              {parseFloat(
                                studentAnswer.student_total_scores
                              ).toFixed(2)}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-body ">
                      <form>
                        {Object.values(studentAnswer.case_parts).map((item) => (
                          <div key={item.id} className="mb-4">
                            <div className="mb-2">
                              <h3>
                                Bagian {item.number}. {item.name}
                              </h3>
                              <i>
                                Total Nilai :{" "}
                                {parseFloat(item.part_scores).toFixed(2)}/
                                {parseInt(item.score_weight)}
                              </i>
                            </div>
                            {item.type_name === "pilihan ganda"
                              ? Object.values(item.case_questions).map(
                                  (element, index) => (
                                    <div
                                      key={element.id}
                                      className="d-flex flex-column mb-2 card-question"
                                    >
                                      <div className="d-inline-flex border-bottom-1 mb-4">
                                        <div className="mr-2 title-exam d-flex align-items-start">
                                          {element.order}.
                                        </div>

                                        <div
                                          className="align-items-start title-exam"
                                          dangerouslySetInnerHTML={{
                                            __html: element.question,
                                          }}
                                        ></div>
                                        {/* <div className="d-flex align-items-start">{element.student_answers[0].is_correct === 1 ? <IconCorrect /> : <IconIncorrect />}</div> */}
                                      </div>
                                      {element.file_attached !== null ? (
                                        <div>
                                          <Zoom isZoomed={true}>
                                            <div className="teac-img-preview">
                                              {element.file_attached ===
                                              null ? (
                                                <div>
                                                  <span>Preview Gambar</span>
                                                </div>
                                              ) : (
                                                <img
                                                  src={
                                                    baseImgURL +
                                                    element.file_attached
                                                  }
                                                  alt=""
                                                  id="my-canvas"
                                                />
                                              )}
                                            </div>
                                          </Zoom>
                                        </div>
                                      ) : null}
                                      <div className="form-check ml-3 bounderyChoices">
                                        <div className="row">
                                          {Object.values(
                                            element.case_choices
                                          ).map((item) => (
                                            <div
                                              key={item.id}
                                              className={`col-4 pl-0 mb-3 ${
                                                item.file_attached !== null
                                                  ? "border"
                                                  : ""
                                              }`}
                                            >
                                              <div
                                                className={`p-2 ${
                                                  item.file_attached !== null
                                                    ? "space-wrap"
                                                    : ""
                                                }`}
                                              >
                                                {item.file_attached !== null ? (
                                                  <Zoom isZoomed={true}>
                                                    <div className="teac-img-preview-ans">
                                                      {item.file_attached ===
                                                      null ? (
                                                        <div>
                                                          <span>
                                                            Preview Gambar
                                                          </span>
                                                        </div>
                                                      ) : (
                                                        <img
                                                          src={
                                                            baseImgURL +
                                                            item.file_attached
                                                          }
                                                          alt=""
                                                          id="my-canvas"
                                                        />
                                                      )}
                                                    </div>
                                                  </Zoom>
                                                ) : null}
                                                <div className="choicess align-items-center">
                                                  <input
                                                    className={`form-check-input ${
                                                      item.file_attached !==
                                                      null
                                                        ? "form-check-input-answer"
                                                        : ""
                                                    }`}
                                                    type="radio"
                                                    name={
                                                      "choice" +
                                                      item.case_question_id
                                                    }
                                                    disabled={
                                                      element.student_answers[0]
                                                        .case_choice_id ===
                                                      item.id
                                                        ? false
                                                        : true
                                                    }
                                                    checked={
                                                      element.student_answers[0]
                                                        .case_choice_id ===
                                                      item.id
                                                        ? true
                                                        : false
                                                    }
                                                    readOnly
                                                  />

                                                  <label
                                                    className="form-check-label"
                                                    dangerouslySetInnerHTML={{
                                                      __html: item.value,
                                                    }}
                                                  ></label>
                                                  {item.is_correct === 1 ? (
                                                    <IconCorrect />
                                                  ) : (
                                                    <IconIncorrect />
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          ))}
                                        </div>
                                      </div>
                                    </div>
                                  )
                                )
                              : item.type_name === "pilihan ganda benar salah"
                              ? Object.values(item.case_questions).map(
                                  (element, index) => (
                                    <div
                                      key={element.id}
                                      className="d-flex flex-column mb-2 card-question"
                                    >
                                      <div className="d-inline-flex border-bottom-1 mb-4">
                                        <div className="mr-2 title-exam d-flex align-items-start">
                                          {element.order}.
                                        </div>

                                        <div
                                          className="align-items-start title-exam"
                                          dangerouslySetInnerHTML={{
                                            __html: element.question,
                                          }}
                                        ></div>
                                        {/* <div className="d-flex align-items-start">{element.student_answers[0].is_correct === 1 ? <IconCorrect /> : <IconIncorrect />}</div> */}
                                      </div>
                                      {element.file_attached !== null ? (
                                        <div>
                                          <Zoom isZoomed={true}>
                                            <div className="teac-img-preview">
                                              {element.file_attached ===
                                              null ? (
                                                <div>
                                                  <span>Preview Gambar</span>
                                                </div>
                                              ) : (
                                                <img
                                                  src={
                                                    baseImgURL +
                                                    element.file_attached
                                                  }
                                                  alt=""
                                                  id="my-canvas"
                                                />
                                              )}
                                            </div>
                                          </Zoom>
                                        </div>
                                      ) : null}
                                      <div className="form-check ml-3 bounderyChoices">
                                        <div className="row ">
                                          {Object.values(
                                            element.case_choices
                                          ).map((item) => (
                                            <div
                                              key={item.id}
                                              className={`col-4 pl-0 mb-3 ${
                                                item.file_attached !== null
                                                  ? "border"
                                                  : ""
                                              }`}
                                            >
                                              <div
                                                className={`p-2 ${
                                                  item.file_attached !== null
                                                    ? "space-wrap"
                                                    : ""
                                                }`}
                                              >
                                                {item.file_attached !== null ? (
                                                  <Zoom isZoomed={true}>
                                                    <div className="teac-img-preview-ans">
                                                      {item.file_attached ===
                                                      null ? (
                                                        <div>
                                                          <span>
                                                            Preview Gambar
                                                          </span>
                                                        </div>
                                                      ) : (
                                                        <img
                                                          src={
                                                            baseImgURL +
                                                            item.file_attached
                                                          }
                                                          alt=""
                                                          id="my-canvas"
                                                        />
                                                      )}
                                                    </div>
                                                  </Zoom>
                                                ) : null}
                                                <div className="choicess align-items-center">
                                                  <input
                                                    className={`form-check-input ${
                                                      item.file_attached !==
                                                      null
                                                        ? "form-check-input-answer"
                                                        : ""
                                                    }`}
                                                    type="radio"
                                                    name={
                                                      "choice" +
                                                      item.case_question_id
                                                    }
                                                    disabled={
                                                      element.student_answers[0]
                                                        .case_choice_id ===
                                                      item.id
                                                        ? false
                                                        : true
                                                    }
                                                    checked={
                                                      element.student_answers[0]
                                                        .case_choice_id ===
                                                      item.id
                                                        ? true
                                                        : false
                                                    }
                                                    readOnly
                                                  />

                                                  <label
                                                    className="form-check-label"
                                                    dangerouslySetInnerHTML={{
                                                      __html: item.value,
                                                    }}
                                                  ></label>
                                                  {item.is_correct === 1 ? (
                                                    <IconCorrect />
                                                  ) : (
                                                    <IconIncorrect />
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          ))}
                                        </div>
                                      </div>
                                    </div>
                                  )
                                )
                              : item.type_name === "pilihan ganda kompleks"
                              ? Object.values(item.case_questions).map(
                                  (element, index) => (
                                    <div
                                      key={element.id}
                                      className="d-flex flex-column mb-2 card-question"
                                    >
                                      <div className="d-inline-flex border-bottom-1 mb-4">
                                        <div className="mr-2 title-exam d-flex align-items-start">
                                          {element.order}.
                                        </div>

                                        <div
                                          className="align-items-start title-exam"
                                          dangerouslySetInnerHTML={{
                                            __html: element.question,
                                          }}
                                        ></div>

                                        {/* <div className="d-flex align-items-start">{element.student_answers[0].is_correct === 1 ? <IconCorrect /> : <IconIncorrect />}</div> */}
                                      </div>
                                      {element.file_attached !== null ? (
                                        <div>
                                          <Zoom isZoomed={true}>
                                            <div className="teac-img-preview">
                                              {element.file_attached ===
                                              null ? (
                                                <div>
                                                  <span>Preview Gambar</span>
                                                </div>
                                              ) : (
                                                <img
                                                  src={
                                                    baseImgURL +
                                                    element.file_attached
                                                  }
                                                  alt=""
                                                  id="my-canvas"
                                                />
                                              )}
                                            </div>
                                          </Zoom>
                                        </div>
                                      ) : null}
                                      <div className="form-check ml-3 bounderyChoices">
                                        <div className="row ">
                                          {Object.values(
                                            element.case_choices
                                          ).map((item, i) => (
                                            <div
                                              key={item.id}
                                              className={`col-4 pl-0 mb-3 ${
                                                item.file_attached !== null
                                                  ? "border"
                                                  : ""
                                              }`}
                                            >
                                              <div
                                                className={`p-2 ${
                                                  item.file_attached !== null
                                                    ? "space-wrap"
                                                    : ""
                                                }`}
                                              >
                                                {item.file_attached !== null ? (
                                                  <Zoom isZoomed={true}>
                                                    <div className="teac-img-preview-ans">
                                                      {item.file_attached ===
                                                      null ? (
                                                        <div>
                                                          <span>
                                                            Preview Gambar
                                                          </span>
                                                        </div>
                                                      ) : (
                                                        <img
                                                          src={
                                                            baseImgURL +
                                                            item.file_attached
                                                          }
                                                          alt=""
                                                          id="my-canvas"
                                                        />
                                                      )}
                                                    </div>
                                                  </Zoom>
                                                ) : null}
                                                <div className="choicess align-items-center">
                                                  <input
                                                    className={`form-check-input ${
                                                      item.file_attached !==
                                                      null
                                                        ? "form-check-input-answer"
                                                        : ""
                                                    }`}
                                                    type="checkbox"
                                                    name={
                                                      "choice" +
                                                      item.case_question_id
                                                    }
                                                    checked={this.checkAnwerPilgan(
                                                      element.student_answers,
                                                      item
                                                    )}
                                                    disabled={true}

                                                    // disabled={element.student_answers[i] === undefined ? console.log("error") : element.student_answers[i].case_choice_id === item.id ? false : true}

                                                    // disabled={element.student_answers[i] === undefined ? true : element.student_answers[i].case_choice_id === item.id ? false:true}

                                                    // 	checked={element.student_answers[i] === undefined ? false : element.student_answers[i].case_choice_id === item.id ? true:false} readOnly

                                                    // checked={element.student_answers[i] === undefined ? console.log("error") : element.student_answers[i].case_choice_id === item.id ? true : false} readOnly
                                                  />

                                                  <label
                                                    className="form-check-label"
                                                    dangerouslySetInnerHTML={{
                                                      __html: item.value,
                                                    }}
                                                  ></label>
                                                  {item.is_correct === 1 ? (
                                                    <IconCorrect />
                                                  ) : (
                                                    <IconIncorrect />
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          ))}
                                        </div>
                                      </div>
                                    </div>
                                  )
                                )
                              : item.type_name === "isian singkat"
                              ? Object.values(item.case_questions).map(
                                  (element, index) => (
                                    <div
                                      key={element.id}
                                      className="d-flex flex-column mb-2 card-question"
                                    >
                                      <div className="d-inline-flex border-bottom-1 mb-4">
                                        <div className="mr-2 title-exam d-flex align-items-start">
                                          {element.order}.
                                        </div>

                                        <div
                                          className="align-items-start title-exam"
                                          dangerouslySetInnerHTML={{
                                            __html: element.question,
                                          }}
                                        ></div>
                                        <div className="d-flex align-items-start">
                                          {element.student_answers[0]
                                            .is_correct === 1 ? (
                                            <IconCorrect />
                                          ) : (
                                            <IconIncorrect />
                                          )}
                                        </div>
                                      </div>
                                      {element.file_attached !== null ? (
                                        <div>
                                          <Zoom isZoomed={true}>
                                            <div className="teac-img-preview">
                                              {element.file_attached ===
                                              null ? (
                                                <div>
                                                  <span>Preview Gambar</span>
                                                </div>
                                              ) : (
                                                <img
                                                  src={
                                                    baseImgURL +
                                                    element.file_attached
                                                  }
                                                  alt=""
                                                  id="my-canvas"
                                                />
                                              )}
                                            </div>
                                          </Zoom>
                                        </div>
                                      ) : null}
                                      <div className="ml-3 mt-3">
                                        <div className="row ">
                                          {Object.values(
                                            element.case_choices
                                          ).map((item) => (
                                            <div
                                              className="col-4 mb-2 bounderyChoices"
                                              key={item.id}
                                            >
                                              <div className="d-flex align-items-start p-0 w-100">
                                                <div className="form-check my-0 p-0 w-100">
                                                  <i>Jawaban :</i>

                                                  <input
                                                    className="form-check-input mr-3 d-none"
                                                    type="radio"
                                                    name={
                                                      "choice" +
                                                      item.case_question_id
                                                    }
                                                    disabled={
                                                      element.student_answers[0]
                                                        .case_choice_id ===
                                                      item.id
                                                        ? false
                                                        : true
                                                    }
                                                    checked={
                                                      element.student_answers[0]
                                                        .case_choice_id ===
                                                      item.id
                                                        ? true
                                                        : false
                                                    }
                                                    readOnly
                                                  />
                                                  <input
                                                    type="text"
                                                    className="form-control form-control-isian-singkat-assesment mt-3"
                                                    value={
                                                      element.student_answers[0]
                                                        .answer_text
                                                    }
                                                    disabled
                                                  />

                                                  {/* <div
																									className="form-check-label ml-0 answer-score"
																									dangerouslySetInnerHTML={{
																										__html: item.value,
																									}}
																								></div> */}
                                                </div>
                                                {item.file_attached !== null ? (
                                                  <Zoom isZoomed={true}>
                                                    <div className="teac-img-preview-ans">
                                                      {item.file_attached ===
                                                      null ? (
                                                        <div>
                                                          <span>
                                                            Preview Gambar
                                                          </span>
                                                        </div>
                                                      ) : (
                                                        <img
                                                          src={
                                                            baseImgURL +
                                                            item.file_attached
                                                          }
                                                          alt=""
                                                          id="my-canvas"
                                                        />
                                                      )}
                                                    </div>
                                                  </Zoom>
                                                ) : null}
                                              </div>
                                            </div>
                                          ))}
                                          {/* value isian singkat */}
                                          <div className="form-group">
                                            <label className="font-weight-bold">
                                              Nilai (0-1)
                                            </label>
                                            <input
                                              type="number"
                                              className="form-control form-control-sm"
                                              id="inputSCoreEssayShort"
                                              min="0.00"
                                              max="1.00"
                                              step="0.1"
                                              value={
                                                element.student_answers[0]
                                                  .is_correct === null
                                                  ? Number(0)
                                                  : Number(
                                                      element.student_answers[0]
                                                        .is_correct
                                                    )
                                              }
                                              placeholder="Masukan Nilai"
                                              style={{
                                                borderColor: "#867272",
                                              }}
                                              onChange={(e) =>
                                                Number(e.target.value) <= 1
                                                  ? this.handleInputScoreShort(
                                                      this.getIndex(
                                                        item.id,
                                                        studentAnswer.case_parts
                                                      ),
                                                      index,
                                                      Number(e.target.value)
                                                    )
                                                  : null
                                              }
                                            />

                                            <div
                                              style={{
                                                color: "black",
                                                marginTop: "25px",
                                              }}
                                            >
                                              <InputRange
                                                maxValue={1.0}
                                                minValue={0.0}
                                                step={0.05}
                                                value={
                                                  element.student_answers[0]
                                                    .is_correct === null
                                                    ? Number(0)
                                                    : Number(
                                                        element
                                                          .student_answers[0]
                                                          .is_correct
                                                      )
                                                }
                                                onChange={(value) =>
                                                  this.handleInputScoreShort(
                                                    this.getIndex(
                                                      item.id,
                                                      studentAnswer.case_parts
                                                    ),
                                                    this.getIndex(
                                                      element.id,
                                                      item.case_questions
                                                    ),
                                                    value
                                                  )
                                                }
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )
                                )
                              : item.type_name === "menjodohkan"
                              ? Object.values(item.case_questions).map(
                                  (element, index) => (
                                    <div
                                      key={index}
                                      className="d-flex flex-column mb-2 card-question"
                                    >
                                      <div className="d-inline-flex border-bottom-1 mb-4">
                                        <div className="mr-2 title-exam d-flex align-items-start">
                                          {element.order}.
                                        </div>

                                        <div
                                          className="align-items-start title-exam"
                                          dangerouslySetInnerHTML={{
                                            __html: element.question,
                                          }}
                                        ></div>
                                      </div>

                                      {element.file_attached !== null ? (
                                        <div>
                                          <Zoom isZoomed={true}>
                                            <div className="teac-img-preview">
                                              {element.file_attached ===
                                              null ? (
                                                <div>
                                                  <span>Preview Gambar</span>
                                                </div>
                                              ) : (
                                                <img
                                                  src={
                                                    baseImgURL +
                                                    element.file_attached
                                                  }
                                                  alt=""
                                                  id="my-canvas"
                                                />
                                              )}
                                            </div>
                                          </Zoom>
                                        </div>
                                      ) : null}

                                      <ul className="list-group">
                                        {element.student_answers.map(
                                          (items, index) => {
                                            return (
                                              <li
                                                key={index}
                                                className="list-group-item"
                                              >
                                                <div className="d-flex align-items-center justify-content-between">
                                                  <div className="d-flex items-center">
                                                    {element.case_choices.map(
                                                      (case_choice) => {
                                                        return (
                                                          <>
                                                            {case_choice.id ===
                                                            items.case_choice_id ? (
                                                              <div
                                                                className="pr-5"
                                                                style={{
                                                                  minWidth:
                                                                    "6rem",
                                                                }}
                                                                dangerouslySetInnerHTML={{
                                                                  __html:
                                                                    case_choice.value,
                                                                }}
                                                              ></div>
                                                            ) : (
                                                              ""
                                                            )}{" "}
                                                          </>
                                                        );
                                                      }
                                                    )}

                                                    <div className="d-flex items-center">
                                                      <Arrow className="mt-1" />
                                                      <div className="line-hub"></div>
                                                      <Arrow
                                                        className="mt-1"
                                                        style={{
                                                          transform:
                                                            "rotate(180deg)",
                                                        }}
                                                      />
                                                    </div>

                                                    <div
                                                      className="pl-5"
                                                      dangerouslySetInnerHTML={{
                                                        __html:
                                                          items.answer_text,
                                                      }}
                                                    ></div>
                                                  </div>
                                                  {items.is_correct === 1 ? (
                                                    <IconCorrect />
                                                  ) : (
                                                    <IconIncorrect />
                                                  )}
                                                </div>
                                              </li>
                                            );
                                          }
                                        )}
                                      </ul>
                                    </div>
                                  )
                                )
                              : Object.values(item.case_questions).map(
                                  (element, index) => (
                                    <div
                                      key={element.id}
                                      className="mb-4 card-question"
                                    >
                                      <div className="d-inline-flex border-bottom-1 mb-4 w-100">
                                        <div className="mr-2 title-exam d-flex align-items-start">
                                          {element.order}.
                                        </div>
                                        <div
                                          className="align-items-start title-exam"
                                          dangerouslySetInnerHTML={{
                                            __html: element.question,
                                          }}
                                        ></div>
                                      </div>
                                      {element.file_attached !== null ? (
                                        <div>
                                          <Zoom isZoomed={true}>
                                            <div className="teac-img-preview">
                                              {element.file_attached ===
                                              null ? (
                                                <div>
                                                  <span>Preview Gambar</span>
                                                </div>
                                              ) : (
                                                <img
                                                  src={
                                                    baseImgURL +
                                                    element.file_attached
                                                  }
                                                  alt=""
                                                  id="my-canvas"
                                                />
                                              )}
                                            </div>
                                          </Zoom>
                                        </div>
                                      ) : null}
                                      <div className="row ml-4">
                                        <div className="col-xm-1 p-0">
                                          <p>
                                            <i>Jawaban :</i>
                                          </p>
                                        </div>
                                        <div className="col">
                                          <div
                                            dangerouslySetInnerHTML={{
                                              __html:
                                                element.student_answers[0]
                                                  .answer_text,
                                            }}
                                            className={
                                              element.student_answers[0]
                                                .answer_text !== null
                                                ? ` previewAnswerStudent mb-1`
                                                : ` previewAnswerStudent-null mb-1`
                                            }
                                          ></div>
                                        </div>

                                        <div className="col col-lg-2 ">
                                          {/* value uraian */}
                                          <div className="form-group">
                                            <label className="font-weight-bold">
                                              Nilai (0-1)
                                            </label>
                                            <input
                                              type="number"
                                              className="form-control form-control-sm"
                                              id="inputSCoreEssay"
                                              min="0.00"
                                              max="1.00"
                                              step="0.1"
                                              value={
                                                element.student_answers[0]
                                                  .is_correct === null
                                                  ? Number(0)
                                                  : Number(
                                                      element.student_answers[0]
                                                        .is_correct
                                                    )
                                              }
                                              placeholder="Masukan Nilai"
                                              style={{
                                                borderColor: "#867272",
                                              }}
                                              onChange={(e) =>
                                                Number(e.target.value) <= 1
                                                  ? this.handleInputScore(
                                                      this.getIndex(
                                                        item.id,
                                                        studentAnswer.case_parts
                                                      ),
                                                      index,
                                                      Number(e.target.value)
                                                    )
                                                  : null
                                              }
                                            />

                                            <div
                                              style={{
                                                color: "black",
                                                marginTop: "25px",
                                              }}
                                            >
                                              <InputRange
                                                maxValue={1.0}
                                                minValue={0.0}
                                                step={0.05}
                                                value={
                                                  element.student_answers[0]
                                                    .is_correct === null
                                                    ? Number(0)
                                                    : Number(
                                                        element
                                                          .student_answers[0]
                                                          .is_correct
                                                      )
                                                }
                                                onChange={(value) =>
                                                  this.handleInputScore(
                                                    this.getIndex(
                                                      item.id,
                                                      studentAnswer.case_parts
                                                    ),
                                                    this.getIndex(
                                                      element.id,
                                                      item.case_questions
                                                    ),
                                                    value
                                                  )
                                                }
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="row ml-4 mt-3">
                                        <div className="col-xm-1 p-0">
                                          <p>
                                            <i>Jawaban image :</i>
                                          </p>
                                        </div>
                                        <div className="col col-lg-2">
                                          {element.student_answers[0]
                                            .file_attached !== null ? (
                                            <Zoom isZoomed={true}>
                                              <div className="teac-img-preview">
                                                <img
                                                  src={
                                                    baseImgURL +
                                                    element.student_answers[0]
                                                      .file_attached
                                                  }
                                                  alt=""
                                                  id="my-canvas"
                                                />
                                              </div>
                                            </Zoom>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>

                                      {/* <div className="row ml-4 ">
																			{element.student_answers[0].showImg !== undefined && (
																				<div className="col-md-auto p-0 ml-5">
																					<button className="btn btn-link p-0 ml-5" onClick={(e) => this.handleShowImg(e, this.getIndex(item.id, studentAnswer.case_parts), index, element.student_answers[0].showImg)}>
																						{element.student_answers[0].showImg === true ? "Sembunyikan" : "Tampilkan Gambar"}
																					</button>
																				</div>
																			)}
																			{element.student_answers[0].showImg === true ? (
																				<Zoom isZoomed={true}>
																					<div className="teac-img-preview">
																						<img src={baseImgURL + element.student_answers[0].file_attached} alt="" id="my-canvas" />
																					</div>
																				</Zoom>
																			) : null}
																		</div> */}
                                    </div>
                                  )
                                )}
                          </div>
                        ))}
                        <div className="text-center">
                          <button
                            type="submit"
                            className="btn btn-gradient-primary mr-2 "
                            onClick={(e) => this.handleSubmit(e)}
                          >
                            Simpan
                          </button>

                          {this.state.alertSubmit}
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userId: state.User.dataTeacher.user.data_employee.employee_id,
    dataSchool: state.User.dataSchool,
    studentAnswer: state.User.studentAnswer,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getStudentAnswer: (studentAnswer) =>
      dispatch(getStudentAnswer(studentAnswer)),
    setScoreAnswer: (indexPart, indexQues, newScore) =>
      dispatch(setScoreAnswer(indexPart, indexQues, newScore)),
    setScoreAnswerShort: (indexPart, indexQues, newScore) =>
      dispatch(setScoreAnswerShort(indexPart, indexQues, newScore)),
    showImgAssessment: (indexPart, indexQues, lastStatus) =>
      dispatch(showImgAssessment(indexPart, indexQues, lastStatus)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PenilaianStudent);
