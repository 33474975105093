export const config1 = {
	toolbar: [
		"heading",
		"alignment",
		"|",
		"bold",
		"italic",
		"underline",
		"strikethrough",
		"link",
		"highlight",
		"|",
		"bulletedList",
		"numberedList",
		"blockQuote",
		"outdent",
		"indent",
		"|",
		"mathType",
		"specialCharacters",
		'insertTable',
		"|",
		"undo",
		"redo",

	],
	placeholder: "Tulis Jawaban",
        indentBlock: {
            offset: 1,
            unit: 'em'
        },table: {
            contentToolbar: [ 'tableColumn', 'tableRow', 'mergeTableCells' ]
		},
		image: {
			toolbar: [
				'imageStyle:full',
				'imageStyle:side',
				'|',
				'imageTextAlternative'
			]
		},
};