import React from "react";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import { connect } from "react-redux";
import RemoveIcon from "@material-ui/icons/Remove";
import {
    addChoiceMenjodohkan,
    addChoiceAnswerMenjodohkan,
    deleteAnswerMenjodohkan,
    deleteChoicesAnswerMenjodohkan,
    inputAnswerCaseChoices,
    inputChoiceAnswer,
    selectCorrectAnswer,
} from "../../../store/actions/index";
import "react-medium-image-zoom/dist/styles.css";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { config2 } from "../component/ConfigEditor";

const baseImgURL = process.env.REACT_APP_BACKEND_IMAGES2;
class AnswerMenjodohkan extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hidden: true,
        }
    }

    handleChangeInput = (
        keyPart,
        indexQuestion,
        indexAnswer,
        text,
        keyCaseChoices
    ) => {
        this.props.inputAnswerCaseChoices(
            keyPart,
            indexQuestion,
            indexAnswer,
            text,
            keyCaseChoices
        );
    };
    handleChangeInputChoiceAnswer = (
        keyPart,
        indexQuestion,
        indexAnswer,
        text,
        keyCaseChoices
    ) => {
        this.props.inputChoiceAnswer(
            keyPart,
            indexQuestion,
            indexAnswer,
            text,
            keyCaseChoices
        );
    };

    getIndex = (key) => {
        var Index = this.props.case_part
            .map(function (item) {
                return item.key;
            })
            .indexOf(key);
        console.log("getIndex", Index);
        return Index;
    };

    checkChoiceAnswer = (valueAnswerChoices, key, index, idQuest) => {
        if (valueAnswerChoices === 10) {
            return alert(
                "jawaban dari pasangan tidak boleh lebih dari 10, hapus salah satu terlebih dahulu"
            );
        }
        return this.props.addChoiceMenjodohkan(
            this.props.xPart,
            this.props.xQues,
            index,
            idQuest
        );
        // return console.log("object")
    };

    getIndexPre = (key) => {
        var Index = key
            .map(function (item) {
                return item.is_correct;
            })
            .indexOf(1); // 1 untuk dapatin true sebelumnya
        return Index;
    };

    show() {
        this.setState({ hidden: false });
    }

    componentWillMount() {
        var that = this;
        setTimeout(function () {
            that.show();
        }, Number(process.env.REACT_APP_TIMEOUT_CKEDITOR));
    }

    render() {
        const maxCaseChoicesAnswer = 10;
        return (
            <div className="row">
                <div className="col-6">
                    {/* {} */}
                    {this.props.item.case_choices.map((items, index) => {
                        return (
                            <div key={index + 1}>
                                <div className="row mb-2">
                                    <div className={`col-md-1 ${this.props.stillEmpty && (items.value === null || items.value === "") ? 'bg-danger' : ''}`}>
                                        <div className="float-right">
                                            <div className="form-check form-check-primary">
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-7">
                                        {
                                            this.state.hidden 
                                                ? '' 
                                                : <CKEditor
                                                    editor={ClassicEditor}
                                                    data={
                                                        items.value === null ? "" : `<div>${items.value}</div>`
                                                    }
                                                    config={config2}
                                                    onBlur={(event, editor) => {
                                                        const data = editor.getData();
                                                        this.handleChangeInput(
                                                            this.props.xPart,
                                                            this.props.xQues,
                                                            index,
                                                            data,
                                                            items.key
                                                        );
                                                    }}
                                                    required
                                                />
                                        }
                                        
                                    </div>

                                    <div className="col-md-2">
                                        {index !== 0 && (
                                            <IconButton
                                                onClick={() =>
                                                    this.props.deleteAnswerMenjodohkan(
                                                        this.props.xPart,
                                                        this.props.xQues,
                                                        index,
                                                        items.key,
                                                        this.props.case_part[this.props.xPart]
                                                            .case_questions[this.props.xQues]
                                                            .case_choices_answers[index].key
                                                    )
                                                }
                                            >
                                                <RemoveIcon />
                                            </IconButton>
                                        )}
                                        {index === maxCaseChoicesAnswer - 1 ? (
                                            ""
                                        ) : (
                                            <IconButton
                                                onClick={() =>
                                                    this.checkChoiceAnswer(
                                                        this.props.case_part[this.props.xPart]
                                                            .case_questions[this.props.xQues]
                                                            .case_choices_answers.length,
                                                        items.key,
                                                        index,
                                                        this.props.indexQuest

                                                    )
                                                }
                                            >
                                                <AddIcon />
                                            </IconButton>
                                        )}
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
                <div className="col-6">
                    {this.props.item.case_choices_answers.map((items, index) => {
                        return (
                            <div key={index + 1}>
                                <div className="row mb-2">
                                    <div className={`col-md-1 ${this.props.stillEmpty && (items.value === null || items.value === "") ? 'bg-danger' : ''}`}>
                                        <div className="float-right">
                                            <div className="form-check form-check-primary">
                                                {/* <input
											type="radio"
											className="form-check"
											// pertanyaan
											name={`optionsAnswer-${this.props.xPart}-${this.props.xQues}`}
											id={
												"optionsAnswer" +
												this.props.xPart +
												this.props.xQues +
												index
											}
											value={index}
											onChange={() =>
												this.props.selectCorrectAnswer(
													this.getIndex(this.props.keyID),
													this.props.xQues,
													index,
													this.getIndexPre(
														this.props.case_part[
															this.getIndex(this.props.keyID)
														].case_questions[this.props.xQues].case_choices,
														index
													)
												)
											}
											checked={inputField.is_correct === 1 ? true : false}
										/> */}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-7">
                                        {
                                            this.state.hidden 
                                                ? ''
                                                : <CKEditor
                                                    // id={`choiceInput${this.props.keyID}${this.props.indexQues}${index}`}
                                                    editor={ClassicEditor}
                                                    data={
                                                        items.value === null ? "" : `<div>${items.value}</div>`
                                                    }
                                                    config={config2}
                                                    onBlur={(event, editor) => {
                                                        const data = editor.getData();
                                                        this.handleChangeInputChoiceAnswer(
                                                            this.props.xPart,
                                                            this.props.xQues,
                                                            index,
                                                            data,
                                                            items.order
                                                        );
                                                    }}
                                                    required
                                                />
                                        }
                                        
                                    </div>

                                    <div className="col-md-2">
                                        {(this.props.case_part[this.props.xPart].case_questions[
                                            this.props.xQues
                                        ].case_choices.length -
                                            1 ===
                                            index) && index < maxCaseChoicesAnswer - 1 && this.props.case_part[this.props.xPart].case_questions[this.props.xQues].case_choices_answers.length !== maxCaseChoicesAnswer ? (
                                            <IconButton
                                                onClick={() =>
                                                    this.props.addChoiceAnswerMenjodohkan(
                                                        this.props.xPart,
                                                        this.props.xQues,
                                                        index,
                                                        this.props.item.id
                                                    )
                                                }
                                            >
                                                <AddIcon />
                                            </IconButton>
                                        ) : (index > this.props.case_part[this.props.xPart].case_questions[this.props.xQues].case_choices.length - 1
                                            ||
                                            index === this.props.case_part[this.props.xPart].case_questions[this.props.xQues].case_choices_answers.length - 1) && index <= maxCaseChoicesAnswer - 1
                                            ?
                                            (
                                                <div>
                                                    <IconButton
                                                        onClick={() =>
                                                            this.props.deleteChoicesAnswerMenjodohkan(
                                                                this.props.xPart,
                                                                this.props.xQues,
                                                                index,
                                                                this.props.case_part[this.props.xPart]
                                                                    .case_questions[this.props.xQues].case_choices,
                                                                items.order
                                                            )
                                                        }
                                                    >
                                                        <RemoveIcon />
                                                    </IconButton>

                                                    {index === maxCaseChoicesAnswer - 1 || this.props.case_part[this.props.xPart].case_questions[this.props.xQues].case_choices_answers.length === maxCaseChoicesAnswer
                                                        ? ("")
                                                        : (
                                                            <IconButton
                                                                onClick={() =>
                                                                    this.props.addChoiceAnswerMenjodohkan(
                                                                        this.props.xPart,
                                                                        this.props.xQues,
                                                                        index,
                                                                        this.props.item.id
                                                                    )
                                                                }
                                                            >
                                                                <AddIcon />
                                                            </IconButton>
                                                        )}
                                                </div>
                                            ) : (
                                                ""
                                            )}
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        case_part: state.Exam.case_part,
        url_school: state.User.dataSchool.url_sekolah,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addChoiceMenjodohkan: (index, indexQues, indexAns, idQuest) =>
            dispatch(addChoiceMenjodohkan(index, indexQues, indexAns, idQuest)),
        addChoiceAnswerMenjodohkan: (index, indexQues, indexAns, idQuesNew) =>
            dispatch(
                addChoiceAnswerMenjodohkan(index, indexQues, indexAns, idQuesNew)
            ),

        deleteAnswerMenjodohkan: (
            index,
            indexQues,
            indexAns,
            keyCaseChoices,
            keyChoicesAnswer
        ) =>
            dispatch(
                deleteAnswerMenjodohkan(
                    index,
                    indexQues,
                    indexAns,
                    keyCaseChoices,
                    keyChoicesAnswer
                )
            ),
        deleteChoicesAnswerMenjodohkan: (
            index,
            indexQues,
            indexAns,
            listCaseChoices,
            keyChoicesAnswer
        ) =>
            dispatch(
                deleteChoicesAnswerMenjodohkan(
                    index,
                    indexQues,
                    indexAns,
                    listCaseChoices,
                    keyChoicesAnswer
                )
            ),
        inputAnswerCaseChoices: (
            indexPart,
            indexQues,
            IndexAns,
            text,
            keyCaseChoices
        ) =>
            dispatch(
                inputAnswerCaseChoices(
                    indexPart,
                    indexQues,
                    IndexAns,
                    text,
                    keyCaseChoices
                )
            ),
        inputChoiceAnswer: (indexPart, indexQues, IndexAns, text, keyCaseChoices) =>
            dispatch(
                inputChoiceAnswer(indexPart, indexQues, IndexAns, text, keyCaseChoices)
            ),
        selectCorrectAnswer: (indPart, indQues, indChoice, keyIndexAnsPre) =>
            dispatch(
                selectCorrectAnswer(indPart, indQues, indChoice, keyIndexAnsPre)
            ),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AnswerMenjodohkan);
