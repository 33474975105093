import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class PenilaianStudent extends Component {
	componentDidMount() {
		const script = document.createElement("script");
		script.src = "assets/js/dataTable-content.js";
		script.async = true;
		document.body.appendChild(script);
	}

	groupingScorePilgan(student) {
		let score = 0;
		Object.values(student.student_answer.case_parts).map((element) => {
			if (element.type_name === "pilihan ganda") {
				score = score + element.part_scores;
			}
			return score;
		});
		return score;
	}
	groupingScorePilganTrueFalse(student) {
		let score = 0;
		Object.values(student.student_answer.case_parts).map((element) => {
			if (element.type_name === "pilihan ganda benar salah") {
				score = score + element.part_scores;
			}
			return score;
		});
		return score;
	}
	groupingScoreEassayShort(student) {
		let score = 0;
		Object.values(student.student_answer.case_parts).map((element) => {
			if (element.type_name === "isian singkat") {
				score = score + element.part_scores;
			}
			return score;
		});
		return score;
	}
	groupingScorePilganKompleks(student) {
		let score = 0;
		Object.values(student.student_answer.case_parts).map((element) => {
			if (element.type_name === "pilihan ganda kompleks") {
				score = score + element.part_scores;
			}
			return score;
		});
		return score;
	}
	groupingScoreEssay(student) {
		let score = 0;
		Object.values(student.student_answer.case_parts).map((element) => {
			if (element.type_name === "uraian") {
				score = score + element.part_scores;
			}
			return score;
		});
		return score;
	}
	groupingScoreMenjodohkan(student) {
		let score = 0;
		Object.values(student.student_answer.case_parts).map((element) => {
			if (element.type_name === "menjodohkan") {
				score = score + element.part_scores;
			}
			return score;
		});
		return score;
	}

	render() {
		return (
			<table
				className="table table-bordered text-center nowrap table-hover"
				id="dt_listStudentScoring"
				style={{ width: "100%" }}
			>
				<thead>
					<tr>
						<td className="th-table">No.</td>
						<td className="th-table">Nama</td>
						<td className="th-table">Pilgan</td>
						<td className="th-table">Pilgan B/S</td>
						<td className="th-table">Pilgan Kompleks</td>
						<td className="th-table">Uraian</td>
						<td className="th-table">Isian Singkat</td>
						<td className="th-table">Menjodohkan</td>
						<td className="th-table">Total Nilai</td>
						<td className="th-table">Aksi</td>
					</tr>
				</thead>
				<tbody>
					{Object.values(this.props.listSchedule).map((element, index) => (
						<tr key={element.api_student_id}>
							<td>{index + 1}</td>
							<td>{element.name}</td>
							<td>
								{parseFloat(this.groupingScorePilgan(element)).toFixed(2)}
							</td>
							<td>
								{parseFloat(this.groupingScorePilganTrueFalse(element)).toFixed(2)}
							</td>
							<td>
								{parseFloat(this.groupingScorePilganKompleks(element)).toFixed(2)}
							</td>
							<td>{parseFloat(this.groupingScoreEssay(element)).toFixed(2)}</td>

							<td>{parseFloat(this.groupingScoreEassayShort(element)).toFixed(2)}</td>
							<td>{parseFloat(this.groupingScoreMenjodohkan(element)).toFixed(2)}</td>
							<td>
								{parseFloat(
									element.student_answer.student_total_scores
								).toFixed(2)}
							</td>
							<td>
								<Link
									to={`/assessment/${this.props.course}/${this.props.schedule}/assess/${element.api_student_id}`}
								>
									<button className="btn btn-sm btn-outline-info mr-3">
										<i className="mdi mdi-tooltip-edit"></i>
										{/* Periksa */}
										{element.student_answer.is_already_corrected === 1
											? "Periksa Kembali"
											: "Periksa"}
									</button>
								</Link>
							</td>
						</tr>
					))}
				</tbody>
			</table>
		);
	}
}
