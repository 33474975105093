import React from "react";
import IconButton from "@material-ui/core/IconButton";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";
import ImageIcon from "@material-ui/icons/Image";
import TestChoices from "./AnswerChoices";
import TestChoicesTrueFalse from "./AnswerChoicesTrueFalse";
import TestChoicesMultiple from "./AnswerChoicesMultiple";
import TestShortAnswer from "./AnswerShort";
import TestMenjodohkan from "./AnswerMenjodohkan";
import { connect } from "react-redux";
import {
	addQuestion,
	addQuestionMenjodohkan,
	addQuestionTrueFalse,
	addQuestionEssay,
	deleteQuestion,
	inputQuestion,
    inputVideoEmbed,
	addToListImg,
	addImageQues,
	removeImage,
	addImageFileQues,
} from "../../../store/actions/index";
import ImageUploader from "react-images-upload";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import axiosInstance from "../../../store/services/AxiosService";
import imageCompression from "browser-image-compression";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { config1 } from "../component/ConfigEditor";

const baseImgURL = process.env.REACT_APP_BACKEND_IMAGES2;
class Question extends React.Component {
	editor = null;
	handleChangeInput = (keyPart, indexQuestion, text) => {
		this.props.inputQuestion(this.getIndex(keyPart), indexQuestion, text);
	};
    
    handleVideoEmbed = (keyPart, indexQuestion, text) => {
		this.props.inputVideoEmbed(this.getIndex(keyPart), indexQuestion, text);
	};

	handleIconAddImg = (indexPart, indexQues) => {
		this.props.addImageQues(indexPart, indexQues, null);
	};
	getIndex = (key) => {
		var Index = this.props.case_part
			.map(function (item) {
				return item.key;
			})
			.indexOf(key);
		return Index;
	};

	async handleUploadImg(e, indexPart, indexQues, fileOld, fileNew) {
		e.preventDefault();
		if (fileNew !== null || fileNew !== undefined) {
			this.props.addImageQues(indexPart, indexQues, "Loading");
			const options = {
				maxSizeMB: 1,
				maxWidthOrHeight: 720,
				useWebWorker: true,
			};
			const compressedFile = await imageCompression(fileNew, options);
			var imgCompressed = new File([compressedFile], "uploaded_file.jpg", {
				type: "image/jpeg",
				lastModified: Date.now(),
			});
			let data = new FormData();
			data.append("file_attached", imgCompressed);
			data.append("old_file", fileOld);
			const url = "/files/upload";
			const config = {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			};
			axiosInstance
				.post(url, data, config)
				.then((r) => {
					this.props.addImageQues(indexPart, indexQues, r.data.file);
					this.props.addImageFileQues(indexPart, indexQues, null);
					this.props.addToListImg(r.data.file);
				})
				.catch((error) => {
					alert(`Proses upload gagal`);
				});
		}
	}

	async handleDeleteImage(e, fileName, indexPart, indexQues) {
		e.preventDefault();
		if (fileName !== null) {
			const data = { file_name: fileName };
			const url = "/files/delete";
			axiosInstance
				.post(url, data)
				.then((r) => {
					this.props.removeImage(indexPart, indexQues);
				})
				.catch((error) => {
					alert(`Proses delete gagal => ${error.message}`);
				});
		} else {
			this.props.removeImage(indexPart, indexQues);
		}
	}

	onDrop(pictureFiles, pictureDataURLs, indexPart, indexQues) {
		this.props.addImageFileQues(indexPart, indexQues, pictureFiles[0]);
	}

	localStorageSpace() {
		var data = "";
		for (var key in window.localStorage) {
			if (window.localStorage.hasOwnProperty(key)) {
				data += window.localStorage[key];
				// console.log(
				// 	key +
				// 		" = " +
				// 		((window.localStorage[key].length * 16) / (8 * 1024)).toFixed(2) +
				// 		" KB"
				// );
			}
		}
		console.log(
			data
				? "\n" +
						"Total space used: " +
						((data.length * 16) / (8 * 1024)).toFixed(2) +
						" KB"
				: "Empty (0 KB)"
		);
		console.log(
			data
				? "Approx. space remaining: " +
						(5120 - ((data.length * 16) / (8 * 1024)).toFixed(2)) +
						" KB"
				: "5 MB"
		);
	}

	render() {
		this.localStorageSpace();
		const {
			case_part,
			keyID,
			typeCase,
			deleteQuestion,
			addQuestion,
			addQuestionMenjodohkan,
			addQuestionTrueFalse,
			addQuestionEssay,
		} = this.props;
		return (
			<div>
				{case_part[this.getIndex(keyID)].case_questions.map(
					(inputField, index) => (
						<div key={index + 1} className="card-question">
							<div className="row mb-3">
								
								<div className={`col-md-auto align-self-start pt-1 ${this.props.stillEmpty && (inputField.question === null || inputField.question === "") ? 'bg-danger' : ''}`}>
									<h3 className="text-right ">{index + 1}.</h3>
								</div>

								<div className="col-md-9">
									<CKEditor
										id={"questionInput" + keyID + index}
										editor={ClassicEditor}
										data={
											inputField.question === null
												? ""
												: `<div>${inputField.question}</div>`
										}
										config={config1}
										onBlur={(event, editor) => {
											const data = editor.getData();
											this.handleChangeInput(keyID, index, data);
										}}
										required
									/>
                                    <div>
                                        <input className="form-control form-group border-top" style={{"box-shadow": "rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px"}} value={inputField.video_embed === null
                                            ? ""
                                            : inputField.video_embed} onChange={(e)=>this.handleVideoEmbed(
                                                keyID,
                                                index,
                                                e.target.value
                                            )} placeholder="Video URL"/>
                                    </div>

									{inputField.status_img === 1 ? (
										<div className="d-flex align-items-start m-3">
											<div className="box-imageQuestionPreview ">
												<Zoom isZoomed={true}>
													<div className="imageQuestionPreview ">
														{inputField.file_attached === null ||
														inputField.file_attached === "Loading" ? (
															<div>
																<span>Preview Gambar</span>
																{/* )} */}
															</div>
														) : (
															<img
																src={baseImgURL + inputField.file_attached}
																alt=""
																id="my-canvas"
															/>
														)}
													</div>
												</Zoom>
											</div>

											<div className="imgUploader ml-3">
												<ImageUploader
													className="imgUploader d-inline"
													name="quesImg"
													withIcon={true}
													singleImage={true}
													withPreview={true}
													buttonText="Pilih Gambar"
													onChange={(pictureFiles, pictureDataURLs) =>
														this.onDrop(
															pictureFiles,
															pictureDataURLs,
															this.getIndex(keyID),
															index
														)
													}
													imgExtension={[".jpg", ".jpeg", ".png"]}
													maxFileSize={2242880}
													fileSizeError="Ukuran File Terlalu Besar"
													label="Ukuran Maks: 2mb dengan format file .jpeg/.jpg/.png"
												/>
												<button
													className="btn btn-gradient-danger btn-sm mr-1 "
													onClick={(e) =>
														this.handleDeleteImage(
															e,
															inputField.file_attached,
															this.getIndex(keyID),
															index
														)
													}
												>
													Hapus Gambar
												</button>
												<button
													className="btn btn-gradient-primary btn-sm"
													onClick={(e) =>
														this.handleUploadImg(
															e,
															this.getIndex(keyID),
															index,
															inputField.file_attached,
															inputField.img_file
														)
													}
													disabled={
														inputField.img_file === null ||
														inputField.img_file === undefined
															? true
															: false
													}
												>
													{inputField.file_attached === "Loading" ? (
														<div>
															<i className="mdi mdi-loading mdi-spin"></i>{" "}
															Upload Gambar{" "}
														</div>
													) : (
														"Upload Gambar"
													)}
												</button>
											</div>
										</div>
									) : null}
								</div>
								<div className="col-md-auto">
									{typeCase === "menjodohkan" ? "" :
									<IconButton
									onClick={() =>
										this.handleIconAddImg(this.getIndex(keyID), index)
									}
									>
										<ImageIcon />
									</IconButton>
									}

									{index !== 0 && (
										<IconButton
											onClick={() =>
												deleteQuestion(this.getIndex(keyID), index)
											}
										>
											<RemoveIcon />
										</IconButton>
									)}
									{typeCase === "uraian" ? (
										<IconButton
											onClick={() =>
												addQuestionEssay(this.getIndex(keyID), index)
											}
										>
											<AddIcon />
										</IconButton>
									) : typeCase === "pilihan ganda benar salah" ? (
										<IconButton
											onClick={() => addQuestionTrueFalse(this.getIndex(keyID), index,case_part[this.getIndex(keyID)].type_name,inputField)}
										>
											<AddIcon />
										</IconButton>
									) : typeCase === "menjodohkan" ? (
										<IconButton
											onClick={() => addQuestionMenjodohkan(this.getIndex(keyID), index,case_part[this.getIndex(keyID)].type_name,inputField)}
										>
											<AddIcon />
										</IconButton>
									) : (
										<IconButton
											onClick={() => addQuestion(this.getIndex(keyID), index,case_part[this.getIndex(keyID)].type_name,inputField)}
										>
											<AddIcon />
										</IconButton>
									)}
								</div>
							</div>
							{typeCase === "pilihan ganda" ? (
								<TestChoices
                                    stillEmpty={this.props.stillEmpty}
									keyID={keyID}
									indexQues={index}
									indexPart={this.getIndex(keyID)}
								/>
							): typeCase === "pilihan ganda benar salah" ? (
								<TestChoicesTrueFalse
                                    stillEmpty={this.props.stillEmpty}
									keyID={keyID}
									indexQues={index}
									indexPart={this.getIndex(keyID)}
								/>
							)  : typeCase === "pilihan ganda kompleks" ?  (
								<TestChoicesMultiple
                                    stillEmpty={this.props.stillEmpty}
									keyID={keyID}
									indexQues={index}
									indexPart={this.getIndex(keyID)}
								/>
							) : typeCase === "isian singkat" ? (
                                <TestShortAnswer
                                    stillEmpty={this.props.stillEmpty}
                                    keyID={keyID}
									indexQues={index}
									indexPart={this.getIndex(keyID)}
								/>
							) : typeCase === "menjodohkan" ? (
								<TestMenjodohkan
									stillEmpty={this.props.stillEmpty}
                                    keyID={keyID}
									indexQues={index}
									indexPart={this.getIndex(keyID)}
								/>
							) : ""}

							<br></br>
						</div>
					)
				)}
			</div>
		);
	}
}

const mapToStateToProps = (state) => {
	return {
		case_part: state.Exam.case_part,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		addQuestion: (index, indexQues,typeCase,inputField) => dispatch(addQuestion(index, indexQues,typeCase,inputField)),
		addQuestionTrueFalse: (index, indexQues,typeCase,inputField) => dispatch(addQuestionTrueFalse(index, indexQues,typeCase,inputField)),
		addQuestionMenjodohkan: (index, indexQues,typeCase,inputField) => dispatch(addQuestionMenjodohkan(index, indexQues,typeCase,inputField)),
		addQuestionEssay: (index, indexQues) =>
			dispatch(addQuestionEssay(index, indexQues)),
		deleteQuestion: (index, indexQues) =>
			dispatch(deleteQuestion(index, indexQues)),
		inputQuestion: (index, indexQues, text) =>
			dispatch(inputQuestion(index, indexQues, text)),
        inputVideoEmbed: (index, indexQues, text) =>
			dispatch(inputVideoEmbed(index, indexQues, text)),
		addToListImg: (imageName) => dispatch(addToListImg(imageName)),
		addImageQues: (index, indexQues, image) =>
			dispatch(addImageQues(index, indexQues, image)),
		addImageFileQues: (index, indexQues, imageFile) =>
			dispatch(addImageFileQues(index, indexQues, imageFile)),
		removeImage: (index, indexQues) => dispatch(removeImage(index, indexQues)),
	};
};
export default connect(mapToStateToProps, mapDispatchToProps)(Question);
