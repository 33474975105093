import React from "react";
import Footer from "../../footer";
import axiosInstance from "../../../store/services/AxiosService";
import { connect } from "react-redux";
import { removeExamInfo } from "../../../store/actions/index";
import Spinner from "../../../assets/Spinner.gif";
import Ball from "../../../assets/Ball.svg";
import TableSchedule from "./TableSchedule.jsx";
class Penilaian extends React.Component {
	constructor(props) {
		super(props);
		this._isMounted = false;
		this.state = {
			errorServer: false,
			listSchedule: null,
		};
	}
	componentDidMount() {
		this._isMounted = true;
		if (this._isMounted) {
			this.fetchSchedule(this.props.userId, this.props.schoolId);
		}
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	fetchSchedule = (idTeacher, idSchool) => {
		this.props.removeExamInfo();
		axiosInstance
			.get(`/schedules/teacher/${idTeacher}/school/${idSchool}`)
			// .get(`/schedules/teacher/${idTeacher}/school/${idSchool}`)
			.then((res) => {
				const data = res.data;
				this.setState({
					listSchedule: data.filter((item) => item.case_id !== null),
				});
			})
			.catch((error) => {
				if (error.response.status === 422) {
					this.setState({ listSchedule: [], errorServer: true });
				}
			});
	};

	render() {
		return (
			<div className="main-panel">
				<div className="content-wrapper">
					<div className="page-header">
						<h3 className="page-title text-black">
							<span className="page-title-icon bg-gradient-primary text-white mr-2">
								<i className="mdi mdi-star-circle"></i>
							</span>
							Penilaian
						</h3>
						{this.state.errorServer === true ? (
							<nav aria-label="breadcrumb">
								<ul class="breadcrumb">
									<li
										class="breadcrumb-item active"
										aria-current="page"
										style={{ color: "red" }}
									>
										<span></span>Data Tidak Ditemukan Pada Server
										<i class="mdi mdi-alert-circle-outline icon-sm text-primary align-middle"></i>
									</li>
								</ul>
							</nav>
						) : null}
					</div>

					{this.state.listSchedule === null ? (
						<div className="text-center">
							<img src={Ball} alt=""></img>
						</div>
					) : (
						<div id="content-wrapper" className="d-flex flex-column">
							<div id="content">
								<div className="container-fluid">
									<div className="card shadow mb-4">
										<div className="card-header py-3 text-center">
											<h6 className="m-0 font-weight-bold text-primary">
												Jadwal Ujian
											</h6>
										</div>
										<div className="card-body">
											<div className="table-responsive">
												<TableSchedule listSchedule={this.state.listSchedule} />
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					)}
				</div>
				<Footer />
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		userId: state.User.dataTeacher.user.data_employee.employee_id,
		schoolId: state.User.dataSchool.value,
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		removeExamInfo: () => dispatch(removeExamInfo()),
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(Penilaian);
