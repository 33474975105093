import React, { Component } from "react";
import Footer from "../../footer";
import axiosInstance from "../../../store/services/AxiosService";
import { connect } from "react-redux";
import Spinner from "../../../assets/Spinner.gif";
import Ball from "../../../assets/Ball.svg";
import TableScore from "./TableScore.jsx";

class PenilaianStudent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			listScore: null,
			errorServer: false,
		};
	}
	componentDidMount() {
		this.fetchSchedule(this.props.dataStudent.student.api_student_id, this.props.dataSchool.api_url);
	}

	fetchSchedule = (idStudent, url_sekolah) => {
		axiosInstance
			.get(`/student_answers?student_id=${idStudent}&url_sekolah="${url_sekolah}"`)
			.then((res) => {
				this.setState({
					listScore: res.data,
				});
			})
			.catch((error) => {
				if (error.response.status === 404) {
					this.setState({
						listScore: [],
					});
				}
				if (error.response.status === 422) {
					this.setState({ listScore: [], errorServer: true });
				}
			});
	};

	render() {
		const dataStudent = this.props.dataStudent;
		return (
			<div className="main-panel">
				<div className="content-wrapper">
					<div className="page-header">
						<h3 className="page-title">
							<span className="page-title-icon bg-gradient-primary text-white mr-2">
								<i className="mdi mdi-home"></i>
							</span>
							Daftar Nilai
						</h3>
						{this.state.errorServer === true ? (
							<nav aria-label="breadcrumb">
								<ul class="breadcrumb">
									<li class="breadcrumb-item active" aria-current="page" style={{ color: "red" }}>
										<span></span>Data Tidak Ditemukan Pada Server
										<i class="mdi mdi-alert-circle-outline icon-sm text-primary align-middle"></i>
									</li>
								</ul>
							</nav>
						) : null}
					</div>

					{this.state.listScore === null ? (
						<div className="text-center">
							<img src={Ball} alt=""></img>
						</div>
					) : (
						<div id="content-wrapper" className="d-flex flex-column">
							<div id="content">
								<div className="container-fluid">
									<div className="card shadow mb-4">
										<div className="card-header py-3 ">
											<div className="row">
												<div className="pr-0 col-3 col-sm-3 col-md-2 col-lg-1 font-weight-bold m-0">Nama</div>
												<div className="col-9 col-sm-auto m-0">: {dataStudent.student.name}</div>
											</div>
											<div className="row">
												<div className="pr-0 col-3 col-sm-3 col-md-2 col-lg-1 font-weight-bold m-0">NISN</div>
												<div className="col-9 col-sm-auto m-0">: {dataStudent.student.nisn}</div>
											</div>
											<div className="row">
												<div className="pr-0 col-3 col-sm-3 col-md-2 col-lg-1  font-weight-bold m-0">Kelas</div>
												<div className="col-9 col-sm-auto m-0">: {dataStudent.student.class.class_label}</div>
											</div>
										</div>
										<div className="card-body">
											<div className="table-responsive">
												<TableScore listScore={this.state.listScore} />
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					)}
				</div>
				<Footer />
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		dataStudent: state.User.dataStudent,
		dataSchool: state.User.dataSchool,
	};
};

export default connect(mapStateToProps, null)(PenilaianStudent);
