import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import IndexTeacher from "./components/teacher/index.jsx";
import login from "./components/login/index.jsx";
import LoginTeacher from "./components/login/LoginTeacher.jsx";
import LoginStudent from "./components/login/LoginStudent.jsx";
import IndexStudent from "./components/student/index.jsx";
import NotFound from "./components/userConfirm/NotFound";
import "./index.css";
import "./assets/css/new/make_soal.css";
import "./assets/css/new/edit_soal.css";
import "./assets/css/new/list_penilaian.css";
import ProtectedRoute from "./store/services/protectedRoute";
import { connect } from "react-redux";
function App(props) {
  return (
    <Router basename="/">
      <Switch>
        <Route exact path="/" component={login} />
        <Route path="/login" component={login} />
        <Route path="/login-teacher" component={LoginTeacher} />
        <Route path="/login-student" component={LoginStudent} />
        <ProtectedRoute
          path="/teacher"
          component={IndexTeacher}
          roleUser="TEACHER"
          isAuthenticated={props.isAuthenticated}
          isRoleTrue={props.roleUser}
        />
        <ProtectedRoute
          path="/student"
          component={IndexStudent}
          roleUser="STUDENT"
          isAuthenticated={props.isAuthenticated}
          isRoleTrue={props.roleUser}
        />
        <Route path="*" component={NotFound} />
      </Switch>
    </Router>
  );
}

const mapStateToProps = (state) => ({
  roleUser: state.User.role,
  isAuthenticated: state.User.status,
  roleTeacher: state.User.dataTeacher,
  roleStudent: state.User.dataStudent,
});

export default connect(mapStateToProps)(App);
