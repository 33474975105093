import React, { Fragment } from "react";
import Footer from "../../footer";
import CasePart from "./CasePart.jsx";
import SweetAlert from "react-bootstrap-sweetalert";
import { Prompt, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import {
    setNameCase,
    handleSelectTypeCasePilgan,
    handleSelectTypeCaseMenjodohkan,
    handleSelectTypeCaseEssay,
    addCasePart,
    deleteItem,
    deleteScheduleActive,
    postRequestSuccess,
    handleSelectCourse,
} from "../../../store/actions/index";
import axiosInstance from "../../../store/services/AxiosService";
import Spinner from "../../../assets/Spinner.gif";
import Ball from "../../../assets/Ball.svg";
import SelectCourse from "../component/SelectCourseComponent";


class Exam extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            alertSubmit: null,
            taskDone: false,
            listCourse: null,
            caseStillEmpty: false,
        };
    }

    addItem(e) {
        console.log("this.props.currentItemTypeCase", this.props.currentItemTypeCase)


        e.preventDefault();
        this.props.addCasePart(
            this.props.currentItemTypeCase,
            this.props.cases.name
        );
    }

    deleteItem(key) {
        var removeIndex = this.props.casePart
            .map(function (item) {
                return item.key;
            })
            .indexOf(key);
        this.props.deleteCasePart(removeIndex);
    }

    handleSelectTypeCase(text) {
        if (text === "uraian") {
            this.props.handleSelectTypeCaseEssay(text, null);
        } else if (text === "menjodohkan") {
            this.props.handleSelectTypeCaseMenjodohkan(text, null);
        }
        else {
            this.props.handleSelectTypeCasePilgan(text, null);
        }
    }

    handleSubmit(e) {
        e.preventDefault();
        const getAlert = () => (
            <SweetAlert
                custom
                showCancel
                showCloseButton
                confirmBtnText="Ya"
                cancelBtnText="Tidak"
                confirmBtnBsStyle="primary"
                cancelBtnBsStyle="light"
                title="Apakah Anda Yakin ?"
                onConfirm={() => this.submitExam()}
                onCancel={() => this.setState({ alertSubmit: null })}
            >
                Simpan Soal
            </SweetAlert>
        );
        const alertScoreWeightError = () => (
            <SweetAlert
                warning
                title="Proses Gagal !"
                onConfirm={() => this.setState({ alertSubmit: null })}
                onCancel={() => this.setState({ alertSubmit: null })}
            >
                Total Nilai Harus Sama Dengan 100
            </SweetAlert>
        );

        const alertNullValue = () => (
            <SweetAlert
                warning
                title="Proses Gagal !"
                onConfirm={() => this.setState({ alertSubmit: null })}
                onCancel={() => this.setState({ alertSubmit: null })}
            >
                Terdapat Pertanyaan/Jawaban yang kosong
            </SweetAlert>
        );

        let errNullValue = false;
        try {
            Object.values(this.props.casePart).map((element) => {
                Object.values(element.case_questions).map((item) => {
                    if (item.question === "") {
                        return (errNullValue = true);
                    }
                    if (element.type_name === "pilihan ganda") {
                        Object.values(item.case_choices).map((i) => {
                            if (i.value === "") {
                                return (errNullValue = true);
                            }
                        });
                    }
                });
            });
        } catch (e) {
            this.setState({
                alertSubmit: alertNullValue(),
            });
        }
        let totalScore = 0;
        Object.values(this.props.casePart).map((element) => {
            return (totalScore += element.score_weight);
        });
        console.log(errNullValue);
        if (errNullValue === true) {
            this.setState({
                alertSubmit: alertNullValue(),
            });
        } else {
            totalScore === 100
                ? this.setState({
                    alertSubmit: getAlert(),
                })
                : this.setState({
                    alertSubmit: alertScoreWeightError(),
                });
        }
    }

    async submitExam() {
        this.setState({ alertSubmit: null });
        const url = "/cases/createExam";
        const dataToPost = {
            cases: this.props.cases,
            case_parts: this.props.casePart,
            schedules: this.props.schedules,
        };
        axiosInstance
            .post(url, dataToPost)
            .then((response) => {
                this.props.postRequestSuccess();
                this.handleSuccessSubmit();
            })
            .catch((error) => {
                if (error.response.status === 500) {
                    this.handleFailureSubmit("Terdapat data soal/jawaban kosong", true);
                } else {
                    this.handleFailureSubmit(error.message);
                }
            });
    }

    handleSuccessSubmit() {
        const alertSuccess = () => (
            <SweetAlert
                success
                title="Berhasil !"
                onConfirm={() =>
                    this.setState({
                        alertSubmit: null,
                        taskDone: true,
                    })
                }
            >
                Soal berhasil disimpan !
            </SweetAlert>
        );
        this.setState({
            alertSubmit: alertSuccess(),
        });
    }

    handleFailureSubmit(errorMessage, isRequired = false) {
        if (isRequired) {
            this.setState({
                caseStillEmpty: true
            })
        }

        const alertFailure = () => (
            <SweetAlert
                warning
                title="Proses Gagal !"
                onConfirm={() => this.setState({ alertSubmit: null })}
                onCancel={() => this.setState({ alertSubmit: null })}
            >
                {errorMessage}
            </SweetAlert>
        );
        this.setState({
            alertSubmit: alertFailure(),
        });
    }

    componentDidMount() {
        if (this.props.makeCaseBySchedule === false) {
            let listCourse = [];
            axiosInstance
                .get(
                    `/courses/teacher/${this.props.userId}/school/${this.props.schoolId}`
                )
                .then((res) => {
                    const list = res.data;
                    Object.values(list).map((item) => {
                        return (listCourse[item.course_id] = {
                            value: item.course_id,
                            label: item.course_name,
                        });
                    });
                    this.setState({ listCourse: listCourse });
                });
        }
    }

    handleMaxScore(value) {
        this.setState({ maxScore: value });
    }

    componentWillUnmount() {
        this.props.deleteScheduleActive();
        if (this.state.taskDone === true) {
            localStorage.removeItem("reduxState");
        }
    }

    render() {
        if (this.state.taskDone === true) {
            return <Redirect to="/" />;
        }

        return (
            <Fragment>
                <Prompt
                    message={JSON.stringify({
                        confirmText: "Tetap Keluar",
                        messageText: "Apakah Anda akan keluar? Simpan Terlebih Dahulu",
                        cancelText: "Batal",
                    })}
                />
                <div className="main-panel">
                    <div className="content-wrapper">
                        {/* <div className="page-header">
							<h3 className="page-title">
								<span className="page-title-icon bg-gradient-primary text-white mr-2">
									<i className="mdi mdi-lead-pencil"></i>
								</span>
								Buat Soal
							</h3>
						</div> */}
                        {this.props.scheduleSelected === undefined ? (
                            <div className="d-flex justify-content-center">
                                <img src={Ball} className="img-fluid" alt="" />
                            </div>
                        ) :
                            this.props.makeCaseBySchedule === true ? (

                                // make soal from dashoboard
                                <div className="make-soal">
                                    <div className="page-header">
                                        <h3 className="page-title">
                                            <span className="page-title-icon bg-gradient-primary text-white mr-2">
                                                <i className="mdi mdi-lead-pencil"></i>
                                            </span>
                                            Buat Soal
                                        </h3>
                                    </div>
                                    <div className="info-panel">
                                        <div className="d-flex justify-content-start">
                                            <h1 className="m-2">
                                                {this.props.scheduleSelected.courseName}
                                            </h1>

                                            <label className="badge badge-gradient-danger align-self-center m-2">
                                                {this.props.scheduleSelected.typeCase}
                                            </label>
                                        </div>
                                        <div className="d-flex justify-content-start">
                                            <h5 className="m-2">
                                                {this.props.scheduleSelected.className}
                                            </h5>
                                            <h5 className="m-2">
                                                {this.props.scheduleSelected.date}
                                            </h5>
                                            <h5 className="m-2">
                                                {this.props.scheduleSelected.time}
                                            </h5>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 grid-margin">
                                            <div className="card custom-card-shadow">
                                                <div className="card-body">
                                                    <h4 className="card-title">
                                                        Klik <strong>"Tambah"</strong> untuk menambahkan
                                                        soal
                                                    </h4>
                                                    <h6 className="card-subtitle mb-2 text-muted">
                                                        <i>Catatan: Masukan nama soal terlebih dahulu</i>
                                                    </h6>

                                                    <div className="question-panel">
                                                        <form
                                                            onSubmit={(e) => this.handleSubmit(e)}
                                                        // method="post"
                                                        // encType="multipart/form-data"
                                                        >
                                                            {this.props.makeCaseBySchedule === false ? (
                                                                this.state.listCourse === null ? (
                                                                    <div className="d-flex justify-content-center">
                                                                        <img
                                                                            src={Ball}
                                                                            className="img-fluid"
                                                                            alt="loading"
                                                                        />
                                                                    </div>
                                                                ) : (
                                                                    <div className="form-group">
                                                                        <p className="h4">Pilih Mata Pelajaran:</p>
                                                                        <SelectCourse
                                                                            listCourse={this.state.listCourse}
                                                                            onSelectCourse={(e) =>
                                                                                this.props.handleSelectCourse(e)
                                                                            }
                                                                        />
                                                                    </div>
                                                                )
                                                            ) : null}

                                                            <div className="form-group">
                                                                <p className="h4">Nama Soal:</p>
                                                                <input
                                                                    id="examName"
                                                                    type="text"
                                                                    className="form-control inputQuestion darkLine"
                                                                    placeholder="Masukan Nama Soal Terlebih Dahulu"
                                                                    value={this.props.cases.name}
                                                                    onChange={this.props.inputChanged}
                                                                    required
                                                                />
                                                            </div>
                                                            <div className="dropdown-divider"></div>
                                                            <div className="form-group mt-2 form-group-tipe-soal">
                                                                <p className="h4">Tipe Soal</p>
                                                                <div className="d-flex flex-wrap">


                                                                    <select
                                                                        value={
                                                                            this.props.currentItemTypeCase.type_name
                                                                        }
                                                                        className="custom-select custom-select-tipe-soal mr-sm-2 darkLine"
                                                                        id="chooseCasePart"
                                                                        // style={{ width: "15%" }}
                                                                        onChange={(e) =>
                                                                            this.handleSelectTypeCase(e.target.value)
                                                                        }
                                                                        disabled={this.props.cases.name ? null : true}
                                                                    >
                                                                        <option value="">Pilih Tipe Soal</option>
                                                                        <option value="pilihan ganda">
                                                                            Pilihan Ganda
                                                                        </option>
                                                                        <option value="pilihan ganda benar salah">
                                                                            Pilihan Ganda Benar Salah
                                                                        </option>
                                                                        <option value="uraian">Uraian</option>
                                                                        <option value="pilihan ganda kompleks">
                                                                            Pilihan Ganda Kompleks
                                                                        </option>
                                                                        <option value="isian singkat">
                                                                            Isian Singkat
                                                                        </option>
                                                                        <option value="menjodohkan">
                                                                            Menjodohkan
                                                                        </option>
                                                                    </select>

                                                                    <button
                                                                        type="submit"
                                                                        className="btn btn-gradient-primary mr-2 "
                                                                        onClick={this.addItem.bind(this)}
                                                                        disabled={
                                                                            this.props.currentItemTypeCase.type_name ===
                                                                                ""
                                                                                ? true
                                                                                : false
                                                                        }
                                                                    >
                                                                        Tambah
                                                                    </button>
                                                                    {this.props.casePart.length > 0 && (
                                                                        <div>
                                                                            <button
                                                                                type="submit"
                                                                                className="btn btn-gradient-primary mr-2 "
                                                                            >
                                                                                Simpan
                                                                            </button>
                                                                            {this.state.alertSubmit}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>

                                                            {this.props.casePart.map((member, index) => (
                                                                <Fragment key={index}>
                                                                    <div>
                                                                        <CasePart
                                                                            index={index}
                                                                            keyID={member.key}
                                                                            noBab={index + 1}
                                                                            typeCase={member.type_name}
                                                                            stillEmpty={this.state.caseStillEmpty}
                                                                            handleDeletePartCase={this.deleteItem.bind(
                                                                                this
                                                                            )}
                                                                            handleMaxScore={this.handleMaxScore.bind(
                                                                                this
                                                                            )}
                                                                        />
                                                                    </div>
                                                                    <div className="dropdown-divider mt-5 mb-5 darkLine"></div>
                                                                </Fragment>
                                                            ))}

                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            ) : (

                                // make soal new
                                <div className="make-soal">
                                    <div className="page-header">
                                        <h3 className="page-title">
                                            <span className="page-title-icon bg-gradient-primary text-white mr-2">
                                                <i className="mdi mdi-lead-pencil"></i>
                                            </span>
                                            Buat Soal
                                        </h3>
                                    </div>
                                    <div className="info-panel">
                                        <div className="d-flex justify-content-start">
                                            <h1 className="m-2">Soal Baru</h1>
                                            <label className="badge badge-gradient-danger align-self-center m-2">
                                                Soal Baru
                                            </label>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 grid-margin">
                                            <div className="card custom-card-shadow">
                                                <div className="card-body">
                                                    <h4 className="card-title">
                                                        Klik <strong>"Tambah"</strong> untuk menambahkan
                                                        soal
                                                    </h4>
                                                    <h6 className="card-subtitle mb-2 text-muted">
                                                        <i>Catatan: Masukan nama soal terlebih dahulu</i>
                                                    </h6>

                                                    <div className="question-panel">
                                                        <form
                                                            onSubmit={(e) => this.handleSubmit(e)}
                                                        // method="post"
                                                        // encType="multipart/form-data"
                                                        >
                                                            {this.props.makeCaseBySchedule === false ? (
                                                                this.state.listCourse === null ? (
                                                                    <div className="d-flex justify-content-center">
                                                                        <img
                                                                            src={Ball}
                                                                            className="img-fluid"
                                                                            alt="loading"
                                                                        />
                                                                    </div>
                                                                ) : (
                                                                    <div className="form-group">
                                                                        <p className="h4">Pilih Mata Pelajaran:</p>
                                                                        <SelectCourse
                                                                            listCourse={this.state.listCourse}
                                                                            onSelectCourse={(e) =>
                                                                                this.props.handleSelectCourse(e)
                                                                            }
                                                                        />
                                                                    </div>
                                                                )
                                                            ) : null}

                                                            <div className="form-group">
                                                                <p className="h4">Nama Soal:</p>
                                                                <input
                                                                    id="examName"
                                                                    type="text"
                                                                    className="form-control inputQuestion darkLine"
                                                                    placeholder="Masukan Nama Soal Terlebih Dahulu"
                                                                    value={this.props.cases.name}
                                                                    onChange={this.props.inputChanged}
                                                                    required
                                                                />
                                                            </div>
                                                            <div className="dropdown-divider"></div>
                                                            <div className="form-group form-group-tipe-soal mt-2">
                                                                <p className="h4">Tipe Soal</p>
                                                                <div className="d-flex flex-wrap">

                                                                    <select
                                                                        value={
                                                                            this.props.currentItemTypeCase.type_name
                                                                        }
                                                                        className="custom-select custom-select-tipe-soal mr-sm-2 darkLine"
                                                                        id="chooseCasePart"
                                                                        // style={{ width: "15%" }}
                                                                        onChange={(e) =>
                                                                            this.handleSelectTypeCase(e.target.value)
                                                                        }
                                                                        disabled={this.props.cases.name ? null : true}
                                                                    >
                                                                        <option value="">Pilih Tipe Soal</option>
                                                                        <option value="pilihan ganda">
                                                                            Pilihan Ganda
                                                                        </option>
                                                                        <option value="pilihan ganda benar salah">
                                                                            Pilihan Ganda Benar Salah
                                                                        </option>
                                                                        <option value="uraian">Uraian</option>
                                                                        <option value="pilihan ganda kompleks">
                                                                            Pilihan Ganda Kompleks
                                                                        </option>
                                                                        <option value="isian singkat">
                                                                            Isian Singkat
                                                                        </option>
                                                                        <option value="menjodohkan">
                                                                            Menjodohkan
                                                                        </option>
                                                                    </select>

                                                                    <button
                                                                        type="submit"
                                                                        className="btn btn-gradient-primary mr-2 "
                                                                        onClick={this.addItem.bind(this)}
                                                                        disabled={
                                                                            this.props.currentItemTypeCase.type_name ===
                                                                                ""
                                                                                ? true
                                                                                : false
                                                                        }
                                                                    >
                                                                        Tambah
                                                                    </button>


                                                                    {this.props.casePart.length > 0 && (
                                                                        <div>
                                                                            <button
                                                                                type="submit"
                                                                                className="btn btn-gradient-primary mr-2 "
                                                                            >
                                                                                Simpan soal
                                                                            </button>
                                                                            {this.state.alertSubmit}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>

                                                            {this.props.casePart.map((member, index) => (
                                                                <Fragment key={index}>
                                                                    <div>
                                                                        <CasePart
                                                                            index={index}
                                                                            keyID={member.key}
                                                                            noBab={index + 1}
                                                                            typeCase={member.type_name}
                                                                            stillEmpty={this.state.caseStillEmpty}
                                                                            handleDeletePartCase={this.deleteItem.bind(
                                                                                this
                                                                            )}
                                                                            handleMaxScore={this.handleMaxScore.bind(
                                                                                this
                                                                            )}
                                                                        />
                                                                    </div>
                                                                    <div className="dropdown-divider mt-5 mb-5 darkLine"></div>
                                                                </Fragment>
                                                            ))}
                                                        </form>
                                                    </div>
                                                    <Footer />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                    </div>
                </div>
            </Fragment>
        );
    }
}

const mapToStateToProps = (state) => {
    console.log(state)
    return {
        currentItemTypeCase: state.Exam.currentItemCase,
        casePart: state.Exam.case_part,
        scheduleSelected: state.Exam.scheduleSelected,
        schedules: state.Exam.schedules,
        cases: state.Exam.cases,
        makeCaseBySchedule: state.Exam.makeCaseBySchedule,
        userId: state.User.dataTeacher.user.data_employee.employee_id,
        schoolId: state.User.dataSchool.value,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        inputChanged: (event) => dispatch(setNameCase(event.target.value)),
        handleSelectTypeCaseEssay: (text) =>
            dispatch(handleSelectTypeCaseEssay(text, null)),
        handleSelectTypeCasePilgan: (text) =>
            dispatch(handleSelectTypeCasePilgan(text, null)),
        handleSelectTypeCaseMenjodohkan: (text) =>
            dispatch(handleSelectTypeCaseMenjodohkan(text, null)),
        addCasePart: (casePart, nameExam) =>
            dispatch(addCasePart(casePart, nameExam)),
        deleteCasePart: (part) => dispatch(deleteItem(part)),
        deleteScheduleActive: () => dispatch(deleteScheduleActive()),
        postRequestSuccess: () => dispatch(postRequestSuccess()),
        handleSelectCourse: (courseSelected) =>
            dispatch(handleSelectCourse(courseSelected)),
    };
};

export default connect(mapToStateToProps, mapDispatchToProps)(Exam);
