import * as actionTypes from "./actionTypes";

export const setNameCase = (event) => {
  return {
    type: actionTypes.SET_CASE_NAME,
    text: event,
  };
};

export const handleSelectTypeCasePilgan = (event, case_id) => {
  return {
    type: actionTypes.CHOOSE_CASE_PART_PILGAN,
    currentItemCase: {
      type_name: event,
      key: Date.now(),
      case_id: case_id,
    },
  };
};
export const handleSelectTypeCaseMenjodohkan = (event, case_id) => {
  return {
    type: actionTypes.CHOOSE_CASE_PART_MENJODOHKAN,
    currentItemCase: {
      type_name: event,
      key: Date.now(),
      case_id: case_id,
    },
  };
};

export const handleSelectTypeCaseEssay = (event, case_id) => {
  return {
    type: actionTypes.CHOOSE_CASE_PART_ESSAY,
    currentItemCase: {
      type_name: event,
      key: Date.now(),
      case_id: case_id,
    },
  };
};

export const addCasePart = (casePart, nameExam) => {
  console.log("casePart", casePart);
  return {
    type: actionTypes.ADD_CASE_PART,
    casePart: casePart,
    currentItemCase: {
      type_name: "",
      key: "",
    },
    nameExam: nameExam,
  };
};

export const deleteItem = (indexDeleted) => {
  return {
    type: actionTypes.DELETE_CASE_PART,
    indexDeleted: indexDeleted,
  };
};

export const setRandomQuestion = (keyIndex) => {
  return {
    type: actionTypes.SET_RANDOM_QUESTION,
    keyIndex: keyIndex,
  };
};

export const setRandomAnswer = (keyIndex) => {
  return {
    type: actionTypes.SET_RANDOM_ANSWER,
    keyIndex: keyIndex,
  };
};
export const setCaseSensitife = (keyIndex) => {
  return {
    type: actionTypes.SET_CASE_SENSITIVE_ANSWER,
    keyIndex: keyIndex,
  };
};

export const setNameCasePart = (keyIndex, text) => {
  return {
    type: actionTypes.SET_CASE_PART_NAME,
    keyIndex: keyIndex,
    text: text,
  };
};

export const setScoreWeight = (keyIndex, text) => {
  return {
    type: actionTypes.SET_SCORE_WEIGHT,
    keyIndex: keyIndex,
    text: text,
  };
};

export const addQuestion = (keyIndexPart, keyIndexQues, typeCase, inputField) => {
  return {
    type: actionTypes.ADD_QUESTION,
    keyIndexPart: keyIndexPart,
    keyIndexQues: keyIndexQues + 1,
    case_questions: {
      id: null,
      case_part_id: keyIndexPart,
      key: Date.now(),
      question: "",
      status_img: 0,
      file_attached: null,
      img_file: null,
      case_choices: [
        {
          id: null,
          status_img: 0,
          file_attached: null,
          img_file: null,
          case_question_id: keyIndexQues + 1,
          value: null,
          is_correct: 0,
          key: Date.now(),
        },
      ],
    },
  };
};
export const addQuestionMenjodohkan = (keyIndexPart, keyIndexQues, typeCase, inputField) => {
  return {
    type: actionTypes.ADD_QUESTION_MENJODOHKAN,
    keyIndexPart: keyIndexPart,
    keyIndexQues: keyIndexQues + 1,
    case_questions: {
      id: null,
      case_part_id: keyIndexPart,
      key: Date.now(),
      question: "",
      status_img: 0,
      file_attached: null,
      img_file: null,
      case_choices: [
        {
          id: null,
          case_question_id: keyIndexQues + 1,
          value: null,
          is_correct: 1,
          status_img: 0,
          file_attached: null,
          img_file: null,
          key: Date.now(),
          order: Date.now(),
          color: "#DEBA9D",
        },
      ],
      case_choices_answers: [
        {
          case_question_id: keyIndexQues + 1,
          case_choice_id: Date.now(),
          value: "",
          is_correct: 1,
          status_img: 0,
          id: null,
          file_attached: null,
          img_file: null,
          key: Date.now(),
          order: Date.now(),
          color: "#DEBA9D",
        },
      ],
    },
  };
};
export const addQuestionEssay = (keyIndexPart, keyIndexQues) => {
  return {
    type: actionTypes.ADD_QUESTION,
    keyIndexPart: keyIndexPart,
    keyIndexQues: keyIndexQues + 1,
    case_questions: {
      id: null,
      case_part_id: keyIndexPart,
      key: Date.now(),
      question: "",
      file_attached: null,
      img_file: null,
      status_img: 0,
      case_choices: [],
    },
  };
};

export const addQuestionTrueFalse = (keyIndexPart, keyIndexQues, typeCase, inputField) => {
  return {
    type: actionTypes.ADD_QUESTION_TRUE_FALSE,
    keyIndexPart: keyIndexPart,
    keyIndexQues: keyIndexQues + 1,
    case_questions: {
      id: null,
      case_part_id: keyIndexPart,
      key: Date.now(),
      question: "",
      status_img: 0,
      file_attached: null,
      img_file: null,
      case_choices: [
        {
          id: null,
          status_img: 0,
          file_attached: null,
          img_file: null,
          case_question_id: keyIndexQues + 1,
          value: inputField.case_choices[0].value,
          is_correct: 0,
          key: Date.now(),
        },
        {
          id: null,
          status_img: 0,
          file_attached: null,
          img_file: null,
          case_question_id: keyIndexQues + 1,
          value: inputField.case_choices[1].value,
          is_correct: 0,
          key: Date.now() + 1,
        },
      ],
    },
  };
};

export const deleteQuestion = (keyIndexPart, keyIndexQues) => {
  return {
    type: actionTypes.DELETE_QUESTION,
    keyIndexPart: keyIndexPart,
    keyIndexQues: keyIndexQues,
  };
};

export const inputQuestion = (keyIndexPart, keyIndexQues, text) => {
  return {
    type: actionTypes.SET_QUESTION,
    keyIndexPart: keyIndexPart,
    keyIndexQues: keyIndexQues,
    text: text,
  };
};

export const inputVideoEmbed = (keyIndexPart, keyIndexQues, text) => {
  return {
    type: actionTypes.SET_VIDEO_EMBED,
    keyIndexPart: keyIndexPart,
    keyIndexQues: keyIndexQues,
    text: text,
  };
};

export const addAnswer = (keyIndexPart, keyIndexQues, keyIndexAns) => {
  return {
    type: actionTypes.ADD_ANSWEAR,
    keyIndexPart: keyIndexPart,
    keyIndexQues: keyIndexQues,
    keyIndexAns: keyIndexAns + 1,
    case_choices: {
      case_question_id: keyIndexQues,
      value: "",
      is_correct: 0,
      id: null,
      file_attached: null,
      img_file: null,
      status_img: 0,
      key: Date.now(),
    },
  };
};
export const addChoiceMenjodohkan = (keyIndexPart, keyIndexQues, keyIndexAns, idQuest) => {
  console.log("idQuest", idQuest);
  const ids = Date.now();
  return {
    type: actionTypes.ADD_CHOICE_MENJODOHKAN,
    keyIndexPart: keyIndexPart,
    keyIndexQues: keyIndexQues,
    keyIndexAns: keyIndexAns + 1,
    case_choices: {
      case_question_id: idQuest,
      value: "",
      is_correct: 0,
      id: null,
      file_attached: null,
      img_file: null,
      status_img: 0,
      key: ids,
      order: ids,
    },
    case_choices_answers: {
      case_question_id: idQuest,
      case_choice_id: ids,
      value: "",
      is_correct: 0,
      id: null,
      file_attached: null,
      img_file: null,
      status_img: 0,
      key: ids,
      order: ids,
    },
  };
};
export const addChoiceAnswerMenjodohkan = (keyIndexPart, keyIndexQues, keyIndexAns) => {
  // console.log("keyIndexAns", keyIndexQues);
  return {
    type: actionTypes.ADD_CHOICE_ANSWER_MENJODOHKAN,
    keyIndexPart: keyIndexPart,
    keyIndexQues: keyIndexQues,
    keyIndexAns: keyIndexAns + 1,
    // keyIndexAns: keyIndexAns,
    case_choices_answers: {
      case_question_id: keyIndexQues,
      // kenapa dia null, karena dia tidak ada pasangan ketika create di bagian chooices answer
      case_choice_id: null,
      value: "",
      is_correct: 0,
      id: null,
      file_attached: null,
      img_file: null,
      status_img: 0,
      key: Date.now(),
      order: Date.now(),
    },
  };
};
export const deleteAnswer = (keyIndexPart, keyIndexQues, keyIndexAns) => {
  return {
    type: actionTypes.DELETE_ANSWEAR,
    keyIndexPart: keyIndexPart,
    keyIndexQues: keyIndexQues,
    keyIndexAns: keyIndexAns,
  };
};
export const deleteAnswerMenjodohkan = (keyIndexPart, keyIndexQues, keyIndexAns, keyCaseChoices, keyChoicesAnswer) => {
  return (dispatch, getState) => {
    let case_choices = getState().Exam.case_part[keyIndexPart].case_questions[keyIndexQues].case_choices;
    let newcase_choices = case_choices.filter((item) => item.order !== keyCaseChoices);
    let case_choices_answers = getState().Exam.case_part[keyIndexPart].case_questions[keyIndexQues].case_choices_answers;
    let newcase_choices_answers = case_choices_answers.filter((item) => item.order !== keyCaseChoices);
    dispatch(successDeleteAnswerMenjodohkan(keyIndexPart, keyIndexQues, newcase_choices, newcase_choices_answers));
  };
};

export const successDeleteAnswerMenjodohkan = (keyIndexPart, keyIndexQues, newcase_choices, newcase_choices_answers) => {
  return {
    type: actionTypes.DELETE_ANSWEAR_MENJODOHKAN,
    keyIndexPart,
    keyIndexQues,
    newcase_choices,
    newcase_choices_answers,
  };
};

export const deleteChoicesAnswerMenjodohkan = (keyIndexPart, keyIndexQues, keyIndexAns, listCaseChoices, keyChoicesAnswer) => {
  return (dispatch, getState) => {
    let case_choices_answers = getState().Exam.case_part[keyIndexPart].case_questions[keyIndexQues].case_choices_answers;
    let newcase_choices_answers = case_choices_answers.filter((item) => item.order !== keyChoicesAnswer);

    let case_choices = getState().Exam.case_part[keyIndexPart].case_questions[keyIndexQues].case_choices;
    let newcase_case_choices = case_choices.filter((item) => item.order !== keyChoicesAnswer);

    dispatch(successDeleteAnswerMenjodohkan(keyIndexPart, keyIndexQues, newcase_case_choices, newcase_choices_answers));
  };
};

export const successDeleteChoicesAnswerMenjodohkan = (keyIndexPart, keyIndexQues, newcase_case_choices, newcase_choices_answers) => {
  return {
    type: actionTypes.DELETE_CASE_ANSWEAR_MENJODOHKAN,
    keyIndexPart,
    keyIndexQues,
    newcase_case_choices,
    newcase_choices_answers,
  };
};
export const addAnswerTrueFalse = (keyIndexPart, keyIndexQues, keyIndexAns) => {
  return {
    type: actionTypes.ADD_ANSWEAR_TRUE_FALSE,
    keyIndexPart: keyIndexPart,
    keyIndexQues: keyIndexQues,
    keyIndexAns: keyIndexAns + 1,
    case_choices: {
      case_question_id: keyIndexQues,
      value: null,
      is_correct: 0,
      id: null,
      file_attached: null,
      img_file: null,
      status_img: 0,
      key: Date.now(),
    },
  };
};
export const addAnswerShort = (keyIndexPart, keyIndexQues, keyIndexAns) => {
  return {
    type: actionTypes.ADD_ANSWEAR,
    keyIndexPart: keyIndexPart,
    keyIndexQues: keyIndexQues,
    keyIndexAns: keyIndexAns + 1,
    case_choices: {
      case_question_id: keyIndexQues,
      value: null,
      is_correct: 0,
      id: null,
      file_attached: null,
      img_file: null,
      status_img: 0,
      key: Date.now(),
    },
  };
};
export const addAnswerMultiple = (keyIndexPart, keyIndexQues, keyIndexAns) => {
  return {
    type: actionTypes.ADD_ANSWEAR_MULTIPLE,
    keyIndexPart: keyIndexPart,
    keyIndexQues: keyIndexQues,
    keyIndexAns: keyIndexAns + 1,
    case_choices: {
      case_question_id: keyIndexQues,
      value: "",
      is_correct: 0,
      id: null,
      file_attached: null,
      img_file: null,
      status_img: 0,
      key: Date.now(),
    },
  };
};

export const deleteAnswerTrueFalse = (keyIndexPart, keyIndexQues, keyIndexAns) => {
  return {
    type: actionTypes.DELETE_ANSWEAR_TRUE_FALSE,
    keyIndexPart: keyIndexPart,
    keyIndexQues: keyIndexQues,
    keyIndexAns: keyIndexAns,
  };
};

export const deleteAnswerShort = (keyIndexPart, keyIndexQues, keyIndexAns) => {
  return {
    type: actionTypes.DELETE_ANSWEAR,
    keyIndexPart: keyIndexPart,
    keyIndexQues: keyIndexQues,
    keyIndexAns: keyIndexAns,
  };
};
export const deleteAnswerMultiple = (keyIndexPart, keyIndexQues, keyIndexAns) => {
  return {
    type: actionTypes.DELETE_ANSWEAR_MULTIPLE,
    keyIndexPart: keyIndexPart,
    keyIndexQues: keyIndexQues,
    keyIndexAns: keyIndexAns,
  };
};

export const inputAnswer = (keyIndexPart, keyIndexQues, keyIndexAns, text) => {
  return {
    type: actionTypes.SET_ANSWEAR,
    keyIndexPart: keyIndexPart,
    keyIndexQues: keyIndexQues,
    keyIndexAns: keyIndexAns,
    text: text,
  };
};
export const inputAnswerCaseChoices = (keyIndexPart, keyIndexQues, keyIndexAns, text, keyCaseChoices) => {
  return (dispatch, getState) => {
    let case_choices_answers = getState().Exam.case_part[keyIndexPart].case_questions[keyIndexQues].case_choices.map((item) => (item.order === keyCaseChoices ? {...item, value: text} : item));
    dispatch(successInputChoiceAnswer(case_choices_answers, keyIndexPart, keyIndexQues));
  };
};
export const successInputChoiceAnswer = (texcase_choices_answers, keyIndexPart, keyIndexQues) => {
  return {
    type: actionTypes.SET_ANSWEAR_CASE_CHOICES,
    texcase_choices_answers: texcase_choices_answers,
    keyIndexPart: keyIndexPart,
    keyIndexQues: keyIndexQues,
  };
};
export const inputChoiceAnswer = (keyIndexPart, keyIndexQues, keyIndexAns, text, keyCaseChoices) => {
  return (dispatch, getState) => {
    // type: actionTypes.SET_ANSWEAR_CHOICES,
    // keyIndexPart: keyIndexPart,
    // keyIndexQues: keyIndexQues,
    // keyIndexAns: keyIndexAns,
    // text: text,
    let case_choices_answers = getState().Exam.case_part[keyIndexPart].case_questions[keyIndexQues].case_choices_answers.map((item) => (item.order === keyCaseChoices ? {...item, value: text} : item));
    dispatch(successInputAnswerCaseChoices(case_choices_answers, keyIndexPart, keyIndexQues));
  };
};
export const successInputAnswerCaseChoices = (texcase_choices_answers, keyIndexPart, keyIndexQues) => {
  return {
    type: actionTypes.SET_ANSWEAR_CHOICES,
    texcase_choices_answers: texcase_choices_answers,
    keyIndexPart: keyIndexPart,
    keyIndexQues: keyIndexQues,
  };
};
// export const inputAnswerTrueFalse = (
//   keyIndexPart,
//   keyIndexQues,
//   keyIndexAns,
//   text
// ) => {
//   return {
//     type: actionTypes.SET_ANSWEAR_TRUE_FALSE,
//     keyIndexPart: keyIndexPart,
//     keyIndexQues: keyIndexQues,
//     keyIndexAns: keyIndexAns,
//     text: text,
//   };
// };
export const inputAnswerTrueFalse = (keyIndexPart, keyIndexQues, keyIndexAns, text) => {
  return (dispatch, getState) => {
    let case_questionsvvv = getState().Exam.case_part[keyIndexPart].case_questions;
    case_questionsvvv.map((question, i) => {
      question.case_choices[keyIndexAns].value = text;
    });
    dispatch(successInputAnswerTrueFalse(case_questionsvvv, keyIndexPart));
  };
};

export const successInputAnswerTrueFalse = (case_questionss, keyIndexPart) => {
  return {
    type: actionTypes.SET_ANSWEAR_TRUE_FALSE,
    case_questionsss: case_questionss,
    keyIndexPart: keyIndexPart,
  };
};
export const inputAnswerShort = (keyIndexPart, keyIndexQues, keyIndexAns, text) => {
  return {
    type: actionTypes.SET_ANSWEAR_SHORT,
    keyIndexPart: keyIndexPart,
    keyIndexQues: keyIndexQues,
    keyIndexAns: keyIndexAns,
    text: text,
  };
};

export const inputAnswerMultiple = (keyIndexPart, keyIndexQues, keyIndexAns, text) => {
  return {
    type: actionTypes.SET_ANSWEAR_MULTIPLE,
    keyIndexPart: keyIndexPart,
    keyIndexQues: keyIndexQues,
    keyIndexAns: keyIndexAns,
    text: text,
  };
};

export const selectCorrectAnswer = (indPart, indQues, indChoice, keyIndexAnsPre) => {
  return {
    type: actionTypes.SELECT_CORRECT_ANSWER,
    keyIndexPart: indPart,
    keyIndexQues: indQues,
    keyIndexAns: indChoice,
    keyIndexAnsPre: keyIndexAnsPre,
  };
};
export const selectCorrectAnswerTrueFalse = (indPart, indQues, indChoice, keyIndexAnsPre) => {
  return {
    type: actionTypes.SELECT_CORRECT_ANSWER_TRUE_FALSE,
    keyIndexPart: indPart,
    keyIndexQues: indQues,
    keyIndexAns: indChoice,
    keyIndexAnsPre: keyIndexAnsPre,
  };
};
export const selectCorrectAnswerShort = (indPart, indQues, indChoice, keyIndexAnsPre) => {
  return {
    type: actionTypes.SELECT_CORRECT_ANSWER,
    keyIndexPart: indPart,
    keyIndexQues: indQues,
    keyIndexAns: indChoice,
    keyIndexAnsPre: keyIndexAnsPre,
  };
};
export const selectCorrectAnswerMultiple = (indPart, indQues, indChoice, keyIndexAnsPre) => {
  return {
    type: actionTypes.SELECT_CORRECT_ANSWER_MULTIPLE,
    keyIndexPart: indPart,
    keyIndexQues: indQues,
    keyIndexAns: indChoice,
    keyIndexAnsPre: keyIndexAnsPre,
  };
};
// -------ini edit case
export const editCorrectAnswer = (indexPart, indexQuestion, indexAnswer, indexAnswerOld) => {
  return {
    type: actionTypes.EDIT_ANSWER_CORRECT,
    indexPart: indexPart,
    indexQuestion: indexQuestion,
    indexAnswer: indexAnswer,
    indexAnswerOld: indexAnswerOld,
  };
};
export const editCorrectAnswerTrueFalse = (indexPart, indexQuestion, indexAnswer, indexAnswerOld) => {
  return {
    type: actionTypes.EDIT_ANSWER_CORRECT_TRUE_FALSE,
    indexPart: indexPart,
    indexQuestion: indexQuestion,
    indexAnswer: indexAnswer,
    indexAnswerOld: indexAnswerOld,
  };
};
export const editCorrectAnswerShort = (indexPart, indexQuestion, indexAnswer, indexAnswerOld) => {
  return {
    type: actionTypes.EDIT_ANSWER_CORRECT,
    indexPart: indexPart,
    indexQuestion: indexQuestion,
    indexAnswer: indexAnswer,
    indexAnswerOld: indexAnswerOld,
  };
};
export const editCorrectAnswerMultiple = (indexPart, indexQuestion, indexAnswer, indexAnswerOld) => {
  return {
    type: actionTypes.EDIT_ANSWER_CORRECT_MULTIPLE,
    indexPart: indexPart,
    indexQuestion: indexQuestion,
    indexAnswer: indexAnswer,
    indexAnswerOld: indexAnswerOld,
  };
};

export const editRandomQuestion = (indexPart, newValue) => {
  return {
    type: actionTypes.EDIT_RANDOM_QUESTION,
    indexPart: indexPart,
    newValue: newValue,
  };
};
export const editCaseSensitife = (indexPart, newValue) => {
  return {
    type: actionTypes.EDIT_CASE_SENSITIFE,
    indexPart: indexPart,
    newValue: newValue,
  };
};

export const editRandomAnswer = (indexPart, newValue) => {
  return {
    type: actionTypes.EDIT_RANDOM_ANSWER,
    indexPart: indexPart,
    newValue: newValue,
  };
};
//----------------
export const postRequestSuccess = () => {
  return {
    type: actionTypes.REQUEST_SUBMIT_EXAM_SUCCESS,
    payload: true,
  };
};

export const handleSelectCourse = (course) => {
  return {
    type: actionTypes.SELECT_COURSE_NEW_CASE,
    courseSelected: course,
  };
};

export const addToListImg = (imageName) => {
  return {
    type: actionTypes.ADD_TO_LIST_IMG,
    imageName: imageName,
  };
};

export const addImageQues = (indexPart, indexQues, image) => {
  return {
    type: actionTypes.ADD_IMAGE_QUESTION,
    indexPart: indexPart,
    indexQues: indexQues,
    image: image,
  };
};
export const addImageFileQues = (indexPart, indexQues, imageFile) => {
  return {
    type: actionTypes.ADD_IMAGE_FILE_QUESTION,
    indexPart: indexPart,
    indexQues: indexQues,
    imageFile: imageFile,
  };
};
export const removeImage = (indexPart, indexQues) => {
  return {
    type: actionTypes.REMOVE_IMAGE,
    indexPart: indexPart,
    indexQues: indexQues,
  };
};

export const addImageAns = (indexPart, indexQues, indexAns, image) => {
  return {
    type: actionTypes.ADD_IMAGE_ANSWER,
    indexPart: indexPart,
    indexQues: indexQues,
    indexAns: indexAns,
    image: image,
  };
};
export const addImageFileAns = (indexPart, indexQues, indexAns, imageFile) => {
  return {
    type: actionTypes.ADD_IMAGE_FILE_ANSWER,
    indexPart: indexPart,
    indexQues: indexQues,
    indexAns: indexAns,
    imageFile: imageFile,
  };
};

export const removeImageAnswer = (indexPart, indexQues, indexAns) => {
  return {
    type: actionTypes.REMOVE_IMAGE_ANSWER,
    indexPart: indexPart,
    indexQues: indexQues,
    indexAns: indexAns,
    image: null,
  };
};

export const setEditable = (status) => {
  return {
    type: actionTypes.TEACHER_EDIT_CASES,
    status: status,
  };
};
