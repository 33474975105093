import React from "react";

export default function Arrow({ style, className, width, height, fill }) {
  return (
    <svg
      style={style}
      width={width}
      height={height}
      className={className}
      viewBox="0 0 22 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 6.66701L21.223 0.518007C21.2983 0.467902 21.3857 0.439158 21.4761 0.434836C21.5664 0.430515 21.6562 0.450778 21.736 0.493466C21.8157 0.536155 21.8824 0.599672 21.9289 0.67725C21.9754 0.754829 21.9999 0.843565 22 0.934007V15.066C21.9999 15.1564 21.9754 15.2452 21.9289 15.3228C21.8824 15.4003 21.8157 15.4639 21.736 15.5065C21.6562 15.5492 21.5664 15.5695 21.4761 15.5652C21.3857 15.5609 21.2983 15.5321 21.223 15.482L12 9.33301V15.066C11.9999 15.1564 11.9754 15.2452 11.9289 15.3228C11.8824 15.4003 11.8157 15.4639 11.736 15.5065C11.6562 15.5492 11.5664 15.5695 11.4761 15.5652C11.3857 15.5609 11.2983 15.5321 11.223 15.482L0.623979 8.41601C0.555501 8.37035 0.499353 8.30849 0.460519 8.23592C0.421686 8.16335 0.401367 8.08231 0.401367 8.00001C0.401367 7.9177 0.421686 7.83667 0.460519 7.7641C0.499353 7.69153 0.555501 7.62967 0.623979 7.58401L11.223 0.518007C11.2983 0.467902 11.3857 0.439158 11.4761 0.434836C11.5664 0.430515 11.6562 0.450778 11.736 0.493466C11.8157 0.536155 11.8824 0.599672 11.9289 0.67725C11.9754 0.754829 11.9999 0.843565 12 0.934007V6.66701ZM9.99998 12.263V3.73701L3.60598 8.00001L9.99998 12.263ZM20 12.263V3.73701L13.606 8.00001L20 12.263Z"
        fill={fill}
      />
    </svg>
  );
}

Arrow.defaultProps = {
  width: "22",
  height: "16",
  fill: "#e7e7e7",
};
