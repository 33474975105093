import React from "react";
import TestChoices from "./AnswerEditExam.jsx";
import TestChoicesMultiple from "./AnswerEditExamMultiple.jsx";
import TestChoicesMenjodohkan from "./AnswerEditMenjodohkan.jsx";
import TestShortAnswer from "./AnswerShortEditExam.jsx";
import TestChoicesTrueFalse from "./AnswerEditChoicesTrueFalse";
import { connect } from "react-redux";
import {
	inputQuestion,
    inputVideoEmbed,
	addQuestion,
	addQuestionEssay,
	addQuestionTrueFalse,
	addQuestionMenjodohkan,
	deleteQuestion,
	removeImage,
	addToListImg,
	addImageQues,
	addImageFileQues,
} from "../../../store/actions/index";
import IconButton from "@material-ui/core/IconButton";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";
import ImageIcon from "@material-ui/icons/Image";
import ImageUploader from "react-images-upload";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import axiosInstance from "../../../store/services/AxiosService";
import imageCompression from "browser-image-compression";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { config1 } from "../component/ConfigEditor";

const baseImgURL = process.env.REACT_APP_BACKEND_IMAGES2;
class Question extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hidden: false
        }
    }
    
	getIndex = (key, stateData) => {
		var index = stateData
			.map(function (item) {
				return item.key;
			})
			.indexOf(key);
		return index;
	};
	handleUpdateQuestion = (keyPart, keyQues, data, text) => {
		var indexQues = this.getIndex(keyQues, data);
		// let text = event.target.value;
		this.props.inputQuestion(keyPart, indexQues, text);
	};

    handleUpdateVideoEmbed = (keyPart, keyQues, data, text) => {
		var indexQues = this.getIndex(keyQues, data);
		// let text = event.target.value;
		this.props.inputVideoEmbed(keyPart, indexQues, text);
	};

	handleIconAddImg = (indexPart, indexQues) => {
		this.props.addImageQues(indexPart, indexQues, null);
	};

	async handleUploadImg(e, indexPart, indexQues, oldFile, fileNew) {
		e.preventDefault();
		if (fileNew !== null || fileNew !== undefined) {
			this.props.addImageQues(indexPart, indexQues, "Loading");
			const options = {
				maxSizeMB: 1,
				maxWidthOrHeight: 720,
				useWebWorker: true,
			};
			this.props.addToListImg(oldFile);
			const compressedFile = await imageCompression(fileNew, options);
			var imgCompressed = new File([compressedFile], "uploaded_file.jpg", {
				type: "image/jpeg",
				lastModified: Date.now(),
			});
			let data = new FormData();
			data.append("file_attached", imgCompressed);
			data.append("old_file", null);
			const url = "/files/upload";
			const config = {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			};
			axiosInstance
				.post(url, data, config)
				.then((r) => {
					this.props.addImageQues(indexPart, indexQues, r.data.file);
					this.props.addImageFileQues(indexPart, indexQues, null);
					this.props.addToListImg(r.data.file);
					this.setState({ statusUpload: true });
				})
				.catch((error) => {
					alert(`Proses upload gagal`);
				});
		}
	}

	async handleDeleteImage(e, fileName, indexPart, indexQues) {
		e.preventDefault();
		if (fileName !== null) {
			const data = { file_name: fileName };
			const url = "/files/delete";
			axiosInstance
				.post(url, data)
				.then((r) => {
					this.props.removeImage(indexPart, indexQues);
				})
				.catch((error) => {
					alert(`Proses delete gagal => ${error.message}`);
				});
		} else {
			this.props.removeImage(indexPart, indexQues);
		}
	}

	onDrop(pictureFiles, pictureDataURLs, indexPart, indexQues) {
		this.props.addImageFileQues(indexPart, indexQues, pictureFiles[0]);
	}

    show() {
        this.setState({hidden : false});
    }

    componentWillMount() {
        // var that = this;
        // setTimeout(function() {
        //     that.show();
        // }, 100); 
    }

	render() {
		return (
			<div>
				{this.props.member.case_questions.map((item, index) => (
					<div key={index + 1} className="card-question">
						<div className="row mb-3">
							<div className={`col-md-auto align-self-start pt-1 ${this.props.stillEmpty && (item.question === null || item.question === "") ? 'bg-danger' : ''}`}>
								<h3 className="text-right ">{index + 1}.</h3>
							</div>

							<div className="col-md-9">
                                {
                                    this.state.hidden 
                                        ? '' 
                                        :  <CKEditor
                                            id={"questionInput" + this.props.indexCasePart + item.key}
                                            editor={ClassicEditor}
                                            data={
                                                item.question === null
                                                    ? ""
                                                    : `<div>${item.question}</div>`
                                            }
                                            config={config1}
                                            onBlur={(event, editor) => {
                                                const data = editor.getData();
                                                this.handleUpdateQuestion(
                                                    this.props.indexCasePart,
                                                    item.key,
                                                    this.props.member.case_questions,
                                                    data
                                                );
                                            }}
                                            required
                                        />
                                }
								
                                <div>
                                    <input className="form-control form-group border-top" style={{"box-shadow": "rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px"}} value={item.video_embed === null
                                        ? ""
                                        : item.video_embed} onChange={(e)=>this.handleUpdateVideoEmbed(
                                            this.props.indexCasePart,
                                            item.key,
                                            this.props.member.case_questions,
                                            e.target.value
                                        )} placeholder="Video URL"/>
                                </div>

								{item.status_img === 1 ? (
									<div className="d-flex align-items-start m-3">
										<div className="box-imageQuestionPreview ">
											<Zoom isZoomed={true}>
												<div className="imageQuestionPreview">
													{item.file_attached === null ||
													item.file_attached === "Loading" ? (
														<div>
															<span>Preview Gambar</span>
														</div>
													) : (
														<img
															src={baseImgURL + item.file_attached}
															alt=""
															id="my-canvas"
														/>
													)}
												</div>
											</Zoom>
										</div>

										<div className="imgUploader ml-3">
											<ImageUploader
												className="imgUploader d-inline"
												withIcon={true}
												singleImage={true}
												withPreview={true}
												buttonText="Pilih Gambar"
												onChange={(pictureFiles, pictureDataURLs) =>
													this.onDrop(
														pictureFiles,
														pictureDataURLs,
														this.props.indexCasePart,
														index
													)
												}
												imgExtension={[".jpg", ".jpeg", ".png"]}
												maxFileSize={2242880}
												fileSizeError="Ukuran File Terlalu Besar"
												label="Ukuran Maks: 2mb dengan format file .jpeg/.jpg/.png"
											/>
											<button
												className="btn btn-gradient-danger btn-sm mr-1"
												onClick={(e) =>
													this.handleDeleteImage(
														e,
														item.file_attached,
														this.props.indexCasePart,
														index
													)
												}
											>
												Hapus Gambar
											</button>
											<button
												className="btn btn-gradient-primary btn-sm"
												onClick={(e) =>
													this.handleUploadImg(
														e,
														this.props.indexCasePart,
														index,
														item.file_attached,
														item.img_file
													)
												}
												disabled={
													item.img_file === null || item.img_file === undefined
														? true
														: false
												}
											>
												{item.file_attached === "Loading" ? (
													<div>
														<i className="mdi mdi-loading mdi-spin"></i> Upload
														Gambar{" "}
													</div>
												) : (
													"Upload Gambar"
												)}
											</button>
										</div>
									</div>
								) : null}
							</div>
							{this.props.doingEditCase === true ? (
								<div className="col-md-auto">
									<IconButton
										onClick={() =>
											this.handleIconAddImg(this.props.indexCasePart, index)
										}
									>
										<ImageIcon />
									</IconButton>
									{index !== 0 && (
										<IconButton
											onClick={() =>
												this.props.deleteQuestion(
													this.props.indexCasePart,
													index
												)
											}
										>
											<RemoveIcon />
										</IconButton>
									)}
									{this.props.typeCase === "uraian" ? (
										<IconButton
											onClick={() =>
												this.props.addQuestionEssay(
													this.props.indexCasePart,
													index,
												)
											}
										>
											<AddIcon />
										</IconButton>
									):this.props.typeCase === "pilihan ganda benar salah" ? (
										<IconButton
											onClick={() =>
												this.props.addQuestionTrueFalse(
													this.props.indexCasePart,
													index,
													this.props.member.type_name,item
												)
											}
										>
											<AddIcon />
										</IconButton>
									):this.props.typeCase === "menjodohkan" ? (
										<IconButton
											onClick={() =>
												this.props.addQuestionMenjodohkan(
													this.props.indexCasePart,
													index,
													this.props.member.type_name,item
												)
											}
										>
											<AddIcon />
										</IconButton>
									) : (
										<IconButton
											onClick={() =>
												this.props.addQuestion(
													this.props.indexCasePart,
													index,
													this.props.member.type_name,item
												)
											}
										>
											<AddIcon />
										</IconButton>
									)}
								</div>
							) : null}
						</div>
						{this.props.typeCase === "pilihan ganda" ? ( // jika bukan uraian
							<TestChoices
                                stillEmpty={this.props.stillEmpty}
								member={item.case_choices}
								xPart={this.props.indexCasePart}
								xQues={index}
							/>
						):this.props.typeCase === "pilihan ganda kompleks" ? <TestChoicesMultiple
                                stillEmpty={this.props.stillEmpty}
								member={item.case_choices}
								xPart={this.props.indexCasePart}
								xQues={index}
							/>:this.props.typeCase === "menjodohkan" ? <TestChoicesMenjodohkan
								member={item.case_choices}
								item={item}
                                stillEmpty={this.props.stillEmpty}
								xPart={this.props.indexCasePart}
								xQues={index}
								indexQuest={item.id}
							/>  :this.props.typeCase === "isian singkat" ? <TestShortAnswer
								member={item.case_choices}
								xPart={this.props.indexCasePart}
								xQues={index}
							/> : this.props.typeCase === "pilihan ganda benar salah" ? <TestChoicesTrueFalse
                                stillEmpty={this.props.stillEmpty}
								member={item.case_choices}
								xPart={this.props.indexCasePart}
								xQues={index}
							/> : ""}
						<br></br>
					</div>
				))}
			</div>
		);
	}
}
const mapToStateToProps = (state) => {
	return {
		doingEditCase: state.Exam.doingEditCase,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		inputQuestion: (xPart, xQues, newValue) =>
			dispatch(inputQuestion(xPart, xQues, newValue)),
        inputVideoEmbed: (xPart, xQues, newValue) =>
			dispatch(inputVideoEmbed(xPart, xQues, newValue)),
		addQuestion: (index, indexQues,typeCase,item) => dispatch(addQuestion(index, indexQues,typeCase,item)),
		deleteQuestion: (index, indexQues) =>
			dispatch(deleteQuestion(index, indexQues)),
		addQuestionEssay: (index, indexQues) =>
			dispatch(addQuestionEssay(index, indexQues)),
		addQuestionTrueFalse: (index, indexQues,typeCase,item) =>
			dispatch(addQuestionTrueFalse(index, indexQues,typeCase,item)),
		addQuestionMenjodohkan: (index, indexQues,typeCase,item) =>
			dispatch(addQuestionMenjodohkan(index, indexQues,typeCase,item)),
		addToListImg: (imageName) => dispatch(addToListImg(imageName)),
		addImageQues: (index, indexQues, image) =>
			dispatch(addImageQues(index, indexQues, image)),
		removeImage: (index, indexQues) => dispatch(removeImage(index, indexQues)),
		addImageFileQues: (index, indexQues, imageFile) =>
			dispatch(addImageFileQues(index, indexQues, imageFile)),
	};
};
export default connect(mapToStateToProps, mapDispatchToProps)(Question);
