import React from "react";
import Footer from "../../footer";
import {connect} from "react-redux";
import {fetchTeacherScheduleSuccess, changeStatusEdit} from "../../../store/actions/index";
import Spinner from "../../../assets/Spinner.gif";
import Ball from "../../../assets/Ball.svg";
import DataTable from "./DataTable";
import axiosInstance from "../../../store/services/AxiosService";

class Home extends React.Component {
	constructor(props) {
		super(props);
		this._isMounted = false;
		this.state = {
			errorServer: false,
			listKosong: [],
			listSchedule: [],
		};
	}

	componentDidMount() {
		this._isMounted = true;
		this._isMounted && this.fetchSchedule();
	}

	fetchSchedule() {
		axiosInstance
			.get(`/schedules/teacher/${this.props.userId}/school/${this.props.schoolId}`)
		// axiosInstance
		// 	.get(`/schedules/teacher/${this.props.userId}/school/${this.props.schoolId}`)
			.then((response) => {
				// this.props.fetchTeacherScheduleSuccess(response.data);
				this.setState({listSchedule: response.data});
			})
			.catch((error) => {
				this.props.fetchTeacherScheduleSuccess(this.state.listKosong);
				if (error.response.status === 422) {
					this.setState({errorServer: true});
				}
			});
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	componentDidUpdate() {
		if (this.props.finishEdit === true) {
			this.props.changeStatusEdit(false);
			this.fetchSchedule();
		}
	}
	render() {
		return (
			<div className="main-panel">
				<div className="content-wrapper">
					<div className="page-header">
						<h3 className="page-title">
							<span className="page-title-icon bg-gradient-primary text-white mr-2">
								<i className="mdi mdi-home"></i>
							</span>
							Dashboard
						</h3>
						{this.state.errorServer === true ? (
							<nav aria-label="breadcrumb">
								<ul className="breadcrumb">
									<li className="breadcrumb-item active" aria-current="page" style={{color: "red"}}>
										<span></span>Data Tidak Ditemukan Pada Server
										<i className="mdi mdi-alert-circle-outline icon-sm text-primary align-middle"></i>
									</li>
								</ul>
							</nav>
						) : null}
					</div>

					{this.state.listSchedule.length === 0 ? (
						<div className="d-flex justify-content-center">
							<img src={Ball} className="img-fluid" alt="" />
						</div>
					) : (
						<div className="row">
							<div className="col-12 grid-margin">
								<div className="card custom-card-shadow">
                                    <div className="card-header py-3 text-center">
                                        <h6 className="m-0 font-weight-bold text-primary">
                                            Jadwal
                                        </h6>
                                    </div>
									<div className="card-body">
										<DataTable listSchedule={this.state.listSchedule} data={this.dataset} />
									</div>
								</div>
							</div>
						</div>
					)}
				</div>
				<Footer />
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		finishEdit: state.User.statusEditFinished,
		userId: state.User.dataTeacher.user.data_employee.employee_id,
		schoolId: state.User.dataSchool.value,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchTeacherScheduleSuccess: (listSchedule) => dispatch(fetchTeacherScheduleSuccess(listSchedule)),
		changeStatusEdit: (status) => dispatch(changeStatusEdit(status)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
