import React from "react";
export const FailureMessageTeacher = () => {
	return (
		<div style={{ color: "red", marginTop: "10px", marginBottom: "0" }} className="d-flex justify-content-center">
			<i className="mdi mdi-alert"></i>
			<p style={{ marginBottom: "0px" }}>Email atau Password Salah!</p>
		</div>
	);
};


export const FailureMessageTeacherBypass = () => {
	return (
		<div style={{ color: "red", marginTop: "10px", marginBottom: "0" }} className="d-flex justify-content-center">
			<i className="mdi mdi-alert"></i>
			<p style={{ marginBottom: "0px" }}>Credential gagal!</p>
		</div>
	);
};

export const FailureMessageStudent = () => {
	return (
		<div style={{ color: "red", marginTop: "10px", marginBottom: "0" }} className="d-flex justify-content-center">
			<i className="mdi mdi-alert"></i>
			<p style={{ marginBottom: "0px" }}>NISN atau Password Salah!</p>
		</div>
	);
};


export const FailureMessageUserLogin = (data) => {
	return (
		<div style={{ color: "red", marginTop: "10px", marginBottom: "0" }} className="d-flex justify-content-center">
			<i className="mdi mdi-alert"></i>
			<p style={{ marginBottom: "0px" }}>{data}</p>
		</div>
	);
};