import React from "react";
import SelectCaseModal from "../component/SelectCaseModal.jsx";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { makeCaseBySchedule } from "../../../store/actions/index";
import moment from "moment";
import AccessExamModal from "../component/AccessExamModal.jsx";

class Schedules extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			addModalShow: false,
			courseId: "",
			cases: null,
			accessExamModalShow: false,
			caseId: null,
			apiScheduleId: null,
		};
	}

	onClickChooseCase(courseId, cases) {
		this.setState({
			addModalShow: true,
			courseId: courseId,
			cases: cases,
		});
	}

	onClickAccessExam(apiScheduleId, caseId) {
		this.setState({
			accessExamModalShow: true,
			apiScheduleId: apiScheduleId,
			caseId: caseId
		})
	}

	changeDateFormat = (date) => {
		let dateNew = moment(date, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY");
		return dateNew;
	};

	changeTimeFormat = (time) => {
		let dateNew = moment(time, "HH:mm:ss").format("HH mm");
		let fields = String(dateNew).split(" ");
		return fields[0] + "." + fields[1];
	};

	render() {
		let addModalClose = () => this.setState({ addModalShow: false });
		let accessExamModalClose = () => this.setState({ accessExamModalShow: false });
		return (
			<tr>
				<td>
					<b style={{ color: "#403f3f" }}>
						{this.props.member.courses.course_name}
					</b>
				</td>
				<td className="text-left">
					<div>
						<p className=" item-table-dash">
							<span className="mdi mdi-calendar-text icon-dash"></span>
							{this.changeDateFormat(this.props.member.start_date)}
						</p>
					</div>
					<div>
						<p className="item-table-dash">
							<span className="mdi mdi-clock icon-dash"></span>
							{this.changeTimeFormat(this.props.member.start_time)} -{" "}
							{this.changeTimeFormat(this.props.member.end_time)} WIB
						</p>
					</div>
					<div>
						<p className="item-table-dash">
							<span className="mdi mdi-school icon-dash"></span>
							{this.props.member.classes.class_label}
						</p>
					</div>
				</td>

				<td className="text-left">
                    <div>
						<p className="item-table-dash">
							<b className="text-danger">{this.props.member.is_remedial ? 'Remedial' : ''}</b>
						</p>
					</div>
					<div>
						<p className="item-table-dash">
							<span className="mdi mdi-note icon-dash"></span>Jenis:{" "}
							<b>{this.props.member.exam_types.exam_type}</b>
						</p>
					</div>
					<div>
						<p className="item-table-dash">
							<span className="mdi mdi-checkbox-marked-circle-outline icon-dash"></span>
							Status:
							{this.props.member.cases === null ? (
								<label className="badge badge-danger ml-1">
									<i>Belum Ada Soal</i>
								</label>
							) : (
								<label className="badge badge-success ml-1">
									<span className="text-black" style={{overflow: "hidden", whiteSpace: "nowrap", display: "inline-block", maxWidth: "180px", textOverflow: "ellipsis"}}>
										{this.props.member.cases.name}
									</span>
								</label>
							)}
						</p>
					</div>

					<div>
						<button
							type="button"
							style={{width: '100%', marginTop: '1rem'}}
							className={`btn btn-sm ${this.props.member.is_generate ? 'btn-gradient-primary' : 'btn-outline-info'} btn-icon-text btn-dash-teacher`}
							onClick={() =>
								this.onClickAccessExam(
									this.props.member.exam_schedule_id,
									this.props.member.case_id
								)
							}
						>
							<i className="mdi mdi-key-variant btn-icon-text"></i> Lihat Akses
						</button>
					</div>
				</td>

				<td className="row">
					<div className="col" style={{ padding: 0 }}>
						<Link
							to={`/exam/${this.props.userId}/${this.props.member.class_id}/${this.props.member.course_id}/${this.props.member.exam_schedule_id}`}
						>
							<button
								type="button"
								className="btn btn-sm btn-gradient-primary btn-icon-text btn-dash-teacher"
								onClick={() =>
									this.props.makeCaseBySchedule(
										this.props.member.courses.course_name,
										this.props.member.exam_types.exam_type,
										this.props.member.exam_schedule_id,
										this.props.member.courses.course_id,
										this.props.schoolId,
										this.props.member.classes.class_label,
										this.changeDateFormat(this.props.member.start_date),
										this.changeTimeFormat(this.props.member.start_time) +
											" - " +
											this.changeTimeFormat(this.props.member.end_time) +
											"WIB",
										this.props.userId
									)
								}
								disabled={this.props.member.answered_status}
							>
								<i className="mdi mdi-lead-pencil btn-icon-text"></i> Buat Soal
							</button>
						</Link>
					</div>
					<div className="w-100 mt-2"></div>
					<div className="col" style={{ padding: 0 }}>
						<button
							type="button"
							className="btn btn-sm btn-outline-info btn-icon-text btn-dash-teacher"
							onClick={() =>
								this.onClickChooseCase(
									this.props.member.courses.course_id,
									this.props.member.cases
								)
							}
							disabled={this.props.member.answered_status}
						>
							<i className="mdi mdi-cursor-pointer btn-icon-text"></i> Pilih
							Soal
						</button>
					</div>

					{ this.state.accessExamModalShow && (<AccessExamModal
						show={this.state.accessExamModalShow}
						onHide={() => {accessExamModalClose()}}
						apiScheduleId={this.state.apiScheduleId}
						idCase={this.state.caseId}
					/>) }

					<SelectCaseModal
						show={this.state.addModalShow}
						onHide={addModalClose}
						idcourse={this.state.courseId}
						idschedule={this.props.member.exam_schedule_id}
						cases={this.state.cases}
                        isRemedial={this.props.member.is_remedial}
					/>
				</td>
			</tr>
		);
	}
}

const mapStateToProps = (state) => {
    return {
		userId: state.User.dataTeacher.user.data_employee.employee_id,
		schoolId: state.User.dataSchool.value,
	};
};
const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		makeCaseBySchedule: (
			courseName,
			typeCase,
			scheduleId,
			courseId,
			schooldId,
			className,
			date,
			time,
			userId
		) =>
			dispatch(
				makeCaseBySchedule(
					courseName,
					typeCase,
					scheduleId,
					courseId,
					schooldId,
					className,
					date,
					time,
					userId,
                    ownProps.member.is_remedial
				)
			),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Schedules);
