import React from "react";
import { connect } from "react-redux";
import {
    // inputAnswer,
    inputAnswerMultiple,
    // editCorrectAnswer,
    editCorrectAnswerMultiple,
    // addAnswer,
    addAnswerMultiple,
    // deleteAnswer,
    deleteAnswerMultiple,
    addImageAns,
    addToListImg,
    removeImageAnswer,
    addImageFileAns,
} from "../../../store/actions/index";
import IconButton from "@material-ui/core/IconButton";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";
import ImageIcon from "@material-ui/icons/Image";
import ImageUploader from "react-images-upload";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import axiosInstance from "../../../store/services/AxiosService";
import imageCompression from "browser-image-compression";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { config2 } from "../component/ConfigEditor";

const baseImgURL = process.env.REACT_APP_BACKEND_IMAGES2;
class AnswerMultiple extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hidden: true
        }
    }

    getIndex = (key, stateData) => {
        var index = stateData
            .map(function (item) {
                return item.key;
            })
            .indexOf(key);
        return index;
    };

    handleUpdateAnswer = (keyPart, keyQues, keyAns, data, text) => {
        let indexAns = this.getIndex(keyAns, data);
        // let text = event.target.value;
        this.props.inputAnswerMultiple(keyPart, keyQues, indexAns, text);
    };

    handleIconAddImg = (indexPart, indexQues, indexAns) => {
        this.setState({ imgToPost: null });
        this.props.addImageAns(indexPart, indexQues, indexAns, null);
    };

    handleUpdateCorrectAnswer = (keyPart, keyQues, keyAns, item) => {
        let indexOld = item.indexOf(item.find((indOld) => indOld.is_correct === 1));
        let index = this.getIndex(keyAns, item);
        this.props.editCorrectAnswerMultiple(keyPart, keyQues, index, indexOld);
    };

    async handleUploadImg(e, indexPart, indexQues, indexAns, oldFile, fileNew) {
        e.preventDefault();
        if (fileNew !== null || fileNew !== undefined) {
            this.props.addImageAns(indexPart, indexQues, indexAns, "Loading");
            const options = {
                maxSizeMB: 1,
                maxWidthOrHeight: 720,
                useWebWorker: true,
            };
            this.props.addToListImg(oldFile);
            const compressedFile = await imageCompression(fileNew, options);
            var imgCompressed = new File([compressedFile], "uploaded_file.jpg", {
                type: "image/jpeg",
                lastModified: Date.now(),
            });
            let data = new FormData();
            data.append("file_attached", imgCompressed);
            data.append("old_file", null);
            const url = "/files/upload";
            const config = {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            };
            axiosInstance
                .post(url, data, config)
                .then((r) => {
                    this.props.addImageAns(indexPart, indexQues, indexAns, r.data.file);
                    this.props.addImageFileAns(indexPart, indexQues, indexAns, null);
                    this.props.addToListImg(r.data.file);
                })
                .catch((error) => {
                    alert(`Proses upload gagal`);
                });
        }
    }

    async handleDeleteImage(e, fileName, indexPart, indexQues, indexAns) {
        e.preventDefault();
        if (fileName !== null) {
            const data = { file_name: fileName };
            const url = "/files/delete";
            axiosInstance
                .post(url, data)
                .then((r) => {
                    this.props.removeImageAnswer(indexPart, indexQues, indexAns);
                })
                .catch((error) => {
                    alert(`Proses delete gagal => ${error.message}`);
                });
        } else {
            this.props.removeImage(indexPart, indexQues, indexAns);
        }
    }

    onDrop(pictureFiles, pictureDataURLs, indexPart, indexQues, indexAns) {
        this.props.addImageFileAns(indexPart, indexQues, indexAns, pictureFiles[0]);
    }

    show() {
        this.setState({ hidden: false });
    }

    componentWillMount() {
        var that = this;
        setTimeout(function () {
            that.show();
        }, Number(process.env.REACT_APP_TIMEOUT_CKEDITOR));
    }

    render() {
        return (
            <div>
                {this.props.member.map((item, index) => (
                    <div key={index + 1}>
                        <div className="row mb-2">
                            <div className={`col-md-1 ${this.props.stillEmpty && (item.value === null || item.value === "") ? 'bg-danger' : ''}`}>
                                <div className="float-right">
                                    <div className="form-check form-check-primary ">
                                        <input
                                            type="checkbox"
                                            className="form-check"
                                            name={
                                                "optionsAnswer" + this.props.xPart + this.props.xQues
                                            }
                                            id={
                                                "optionsAnswer" +
                                                this.props.xPart +
                                                this.props.xQues +
                                                item.key
                                            }
                                            value={item.value}
                                            onChange={() =>
                                                this.handleUpdateCorrectAnswer(
                                                    this.props.xPart,
                                                    this.props.xQues,
                                                    item.key,
                                                    this.props.member
                                                )
                                            }
                                            checked={item.is_correct === 1 ? true : false}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-7">
                                {
                                    this.state.hidden 
                                        ? '' 
                                        : <CKEditor
                                            id={"choiceInput" + item.id}
                                            editor={ClassicEditor}
                                            data={
                                                item.value === null ? "" : `<div>${item.value}</div>`
                                            }
                                            config={config2}
                                            onBlur={(event, editor) => {
                                                const data = editor.getData();
                                                this.handleUpdateAnswer(
                                                    this.props.xPart,
                                                    this.props.xQues,
                                                    item.key,
                                                    this.props.member,
                                                    data
                                                );
                                            }}
                                            required
                                        />
                                }

                                {item.status_img === 1 ? (
                                    <div className="d-flex align-items-start m-3">
                                        <div className="box-imageQuestionPreview ">
                                            <Zoom isZoomed={true}>
                                                <div className="imageQuestionPreview">
                                                    {item.file_attached === null ||
                                                        item.file_attached === "Loading" ? (
                                                        <div>
                                                            <span>Preview Gambar</span>
                                                        </div>
                                                    ) : (
                                                        <img
                                                            src={baseImgURL + item.file_attached}
                                                            alt=""
                                                            id="my-canvas"
                                                        />
                                                    )}
                                                </div>
                                            </Zoom>
                                        </div>

                                        <div className="imgUploader ml-3">
                                            <ImageUploader
                                                className="imgUploader d-inline"
                                                withIcon={true}
                                                singleImage={true}
                                                withPreview={true}
                                                buttonText="Pilih Gambar"
                                                onChange={(pictureFiles, pictureDataURLs) =>
                                                    this.onDrop(
                                                        pictureFiles,
                                                        pictureDataURLs,
                                                        this.props.xPart,
                                                        this.props.xQues,
                                                        index
                                                    )
                                                }
                                                imgExtension={[".jpg", ".jpeg", ".png"]}
                                                maxFileSize={2242880}
                                                fileSizeError="Ukuran File Terlalu Besar"
                                                label="Ukuran Maks: 2mb dengan format file .jpeg/.jpg/.png"
                                            />
                                            <button
                                                className="btn btn-gradient-danger btn-sm mr-1"
                                                onClick={(e) =>
                                                    this.handleDeleteImage(
                                                        e,
                                                        item.file_attached,
                                                        this.props.xPart,
                                                        this.props.xQues,
                                                        index
                                                    )
                                                }
                                            >
                                                Hapus Gambar
                                            </button>
                                            <button
                                                className="btn btn-gradient-primary btn-sm"
                                                onClick={(e) =>
                                                    this.handleUploadImg(
                                                        e,
                                                        this.props.xPart,
                                                        this.props.xQues,
                                                        index,
                                                        item.file_attached,
                                                        item.img_file
                                                    )
                                                }
                                                disabled={
                                                    item.img_file === null || item.img_file === undefined
                                                        ? true
                                                        : false
                                                }
                                            >
                                                {item.file_attached === "Loading" ? (
                                                    <div>
                                                        <i className="mdi mdi-loading mdi-spin"></i> Upload
                                                        Gambar{" "}
                                                    </div>
                                                ) : (
                                                    "Upload Gambar"
                                                )}
                                            </button>
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                            {this.props.doingEditCase === true ? (
                                <div className="col-md-2">
                                    <IconButton
                                        onClick={() =>
                                            this.handleIconAddImg(
                                                this.props.xPart,
                                                this.props.xQues,
                                                index
                                            )
                                        }
                                    >
                                        <ImageIcon />
                                    </IconButton>
                                    {index !== 0 && (
                                        <IconButton
                                            onClick={() =>
                                                this.props.deleteAnswerMultiple(
                                                    this.props.xPart,
                                                    this.props.xQues,
                                                    index
                                                )
                                            }
                                        >
                                            <RemoveIcon />
                                        </IconButton>
                                    )}
                                    <IconButton
                                        onClick={() =>
                                            this.props.addAnswerMultiple(
                                                this.props.xPart,
                                                this.props.xQues,
                                                this.getIndex(item.key, this.props.member),
                                                item.key
                                            )
                                        }
                                    >
                                        <AddIcon />
                                    </IconButton>
                                </div>
                            ) : null}
                        </div>
                    </div>
                ))}
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        doingEditCase: state.Exam.doingEditCase,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        inputAnswerMultiple: (xPart, xQues, xAns, newValue) =>
            dispatch(inputAnswerMultiple(xPart, xQues, xAns, newValue)),
        editCorrectAnswerMultiple: (xPart, xQues, xAns, xAnsOld) =>
            dispatch(editCorrectAnswerMultiple(xPart, xQues, xAns, xAnsOld)),
        addAnswerMultiple: (index, indexQues, indexAns) =>
            dispatch(addAnswerMultiple(index, indexQues, indexAns)),
        deleteAnswerMultiple: (index, indexQues, indexAns) =>
            dispatch(deleteAnswerMultiple(index, indexQues, indexAns)),
        addToListImg: (imageName) => dispatch(addToListImg(imageName)),
        addImageAns: (index, indexQues, indexAns, image) =>
            dispatch(addImageAns(index, indexQues, indexAns, image)),
        removeImageAnswer: (index, indexQues, indexAns) =>
            dispatch(removeImageAnswer(index, indexQues, indexAns)),
        addImageFileAns: (index, indexQues, indChoice, imageFile) =>
            dispatch(addImageFileAns(index, indexQues, indChoice, imageFile)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AnswerMultiple);
