export const config1 = {
	toolbar: [
		"alignment",
		"|",
		"bold",
		"italic",
		"underline",
		"link",
		"|",
		"bulletedList",
		"numberedList",
		"outdent",
		"indent",
		"|",
		"mathType",
		"specialCharacters",
		'insertTable'
	],
	placeholder: "Tulis Soal",
        indentBlock: {
            offset: 1,
            unit: 'em'
        },table: {
            contentToolbar: [ 'tableColumn', 'tableRow', 'mergeTableCells' ]
        }
};


export const config2 = {
	toolbar: [
		"alignment",
		"|",
		"bold",
		"italic",
		"underline",
		"link",
		"|",
		"bulletedList",
		"numberedList",
		"outdent",
		"indent",
		"|",
		"mathType",
		"specialCharacters"
	],
	placeholder: "Tulis Jawaban",
        indentBlock: {
            offset: 1,
            unit: 'em'
        }
};