import React from "react";
import Async from "react-select/async";

export default class SelectJenjang extends React.Component {
	state = {
		selectedOption: null,
	};

	handleChange = (selectedOption) => {
		this.setState({ selectedOption });
		this.props.onSelectCourse(selectedOption.value);
	};

	filterCourse = (inputValue) => {
		return this.props.listCourse.filter((i) =>
			i.label.toLowerCase().includes(inputValue.toLowerCase())
		);
	};
	loadOptions = (inputValue, callback) => {
		setTimeout(() => {
			callback(this.filterCourse(inputValue));
		}, 1000);
	};
	render() {
		const { selectedOption } = this.state;
		return (
			<Async
				cacheOptions
				value={selectedOption}
				onChange={this.handleChange}
				options={this.props.listCourse}
				defaultOptions
				loadOptions={this.loadOptions}
				placeholder="Pilih Mata Pelajaran"
			/>
		);
	}
}
