import React from "react";
import IconButton from "@material-ui/core/IconButton";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";
import ImageIcon from "@material-ui/icons/Image";
import { connect } from "react-redux";
import {
    // addAnswer,
    addAnswerMultiple,
    // deleteAnswer,
    deleteAnswerMultiple,
    // inputAnswer,
    inputAnswerMultiple,
    // selectCorrectAnswer,
    selectCorrectAnswerMultiple,
    addToListImg,
    addImageAns,
    removeImageAnswer,
    addImageFileAns,
} from "../../../store/actions/index";
import ImageUploader from "react-images-upload";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import axiosInstance from "../../../store/services/AxiosService";
import imageCompression from "browser-image-compression";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { config2 } from "../component/ConfigEditor";

const baseImgURL = process.env.REACT_APP_BACKEND_IMAGES2;

class TestChoicesMultiple extends React.Component {
    // onchange checkbox CKeditor
    handleChangeInput = (keyPart, indexQuestion, indexAnswer, text) => {
        this.props.inputAnswerMultiple(
            this.getIndex(keyPart),
            indexQuestion,
            indexAnswer,
            text
        );
    };

    getIndexPre = (key) => {
        var Index = key
            .map(function (item) {
                return item.is_correct;
            })
            .indexOf(1); // 1 untuk dapatin true sebelumnya
        return Index;
    };

    handleIconAddImg = (indexPart, indexQues, indexAns) => {
        this.props.addImageAns(indexPart, indexQues, indexAns, null);
    };


    getIndex = (key) => {
        var Index = this.props.case_part
            .map(function (item) {
                return item.key;
            })
            .indexOf(key);
        return Index;
    };

    async handleUploadImg(e, indexPart, indexQues, indexAns, oldFile, fileNew) {
        e.preventDefault();
        console.log(fileNew);
        if (fileNew !== null || fileNew !== undefined) {
            this.props.addImageAns(indexPart, indexQues, indexAns, "Loading");
            const options = {
                maxSizeMB: 1,
                maxWidthOrHeight: 720,
                useWebWorker: true,
            };
            const compressedFile = await imageCompression(fileNew, options);
            var imgCompressed = new File([compressedFile], "uploaded_file.jpg", {
                type: "image/jpeg",
                lastModified: Date.now(),
            });

            let data = new FormData();
            data.append("file_attached", imgCompressed);
            data.append("old_file", oldFile);
            const url = "/files/upload";
            const config = {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            };
            axiosInstance
                .post(url, data, config)
                .then((r) => {
                    this.props.addImageAns(indexPart, indexQues, indexAns, r.data.file);
                    this.props.addImageFileAns(indexPart, indexQues, indexAns, null);
                    this.props.addToListImg(r.data.file);
                })
                .catch((error) => {
                    alert(`Proses upload gagal => ${error.message}`);
                });
        }
    }

    async handleDeleteImage(e, fileName, indexPart, indexQues, indexAns) {
        e.preventDefault();

        if (fileName !== null) {
            const data = { file_name: fileName };
            const url = "/files/delete";
            axiosInstance
                .post(url, data)
                .then((r) => {
                    this.props.removeImageAnswer(indexPart, indexQues, indexAns);
                })
                .catch((error) => {
                    alert(`Proses delete gagal => ${error.message}`);
                });
        } else {
            this.props.removeImageAnswer(indexPart, indexQues, indexAns);
        }
    }

    onDrop = (pictureFiles, pictureDataURLs, indexPart, indexQues, indexAns) => {
        this.props.addImageFileAns(indexPart, indexQues, indexAns, pictureFiles[0]);
    };

    render() {
        return (
            <div>
                {this.props.case_part[this.getIndex(this.props.keyID)].case_questions[
                    this.props.indexQues
                ].case_choices.map((inputField, index) => (

                    <div key={index + 1}>
                        <div className="row mb-2">
                            <div className={`col-md-1 ${this.props.stillEmpty && (inputField.value === null || inputField.value === "") ? 'bg-danger' : ''}`}>
                                <div className="float-right">
                                    <div className="form-check form-check-primary">
                                        <input type="checkbox" className="form-check" name={`optionsAnswer-${this.props.indexPart}-${this.props.indexQues}`}
                                            id={
                                                "optionsAnswer" +
                                                this.props.indexPart +
                                                this.props.indexQues +
                                                index
                                            } value={index}
                                            onChange={() =>
                                                this.props.selectCorrectAnswerMultiple(
                                                    this.getIndex(this.props.keyID),
                                                    this.props.indexQues,
                                                    index,
                                                    this.getIndexPre(
                                                        this.props.case_part[
                                                            this.getIndex(this.props.keyID)
                                                        ].case_questions[this.props.indexQues].case_choices,
                                                        index
                                                    )
                                                )
                                            }
                                            checked={inputField.is_correct === 1 ? true : false} />

                                    </div>
                                </div>

                            </div>
                            <div className="col-md-7">
                                <CKEditor
                                    id={`choiceInput${this.props.keyID}${this.props.indexQues}${index}`}
                                    editor={ClassicEditor}
                                    data={
                                        inputField.value === null
                                            ? ""
                                            : `<div>${inputField.value}</div>`
                                    }
                                    config={config2}
                                    onBlur={(event, editor) => {
                                        const data = editor.getData();
                                        this.handleChangeInput(
                                            this.props.keyID,
                                            this.props.indexQues,
                                            index,
                                            data
                                        );
                                    }}
                                    required
                                />

                                {inputField.status_img === 1 ? (
                                    <div className="d-flex align-items-start m-3">
                                        <div className="box-imageQuestionPreview ">
                                            <Zoom isZoomed={true}>
                                                <div className="imageQuestionPreview">
                                                    {inputField.file_attached === null ||
                                                        inputField.file_attached === "Loading" ? (
                                                        <div>
                                                            <span>Preview Gambar</span>
                                                        </div>
                                                    ) : (
                                                        <img
                                                            src={baseImgURL + inputField.file_attached}
                                                            alt=""
                                                        />
                                                    )}
                                                </div>
                                            </Zoom>
                                        </div>

                                        <div className="imgUploader ml-3">
                                            <ImageUploader
                                                className="imgUploader d-inline"
                                                withIcon={true}
                                                singleImage={true}
                                                withPreview={true}
                                                buttonText="Pilih Gambar"
                                                onChange={(pictureFiles, pictureDataURLs) =>
                                                    this.onDrop(
                                                        pictureFiles,
                                                        pictureDataURLs,
                                                        this.getIndex(this.props.keyID),
                                                        this.props.indexQues,
                                                        index
                                                    )
                                                }
                                                imgExtension={[".jpg", ".jpeg", ".png"]}
                                                label="Ukuran Maks: 2mb dengan format file .jpeg/.jpg/.png"
                                                maxFileSize={2242880}
                                                fileSizeError="Ukuran File Terlalu Besar"
                                            />
                                            <button
                                                className="btn btn-gradient-danger btn-sm mr-1"
                                                onClick={(e) =>
                                                    this.handleDeleteImage(
                                                        e,
                                                        inputField.file_attached,
                                                        this.getIndex(this.props.keyID),
                                                        this.props.indexQues,
                                                        index
                                                    )
                                                }
                                            >
                                                Hapus Gambar
                                            </button>
                                            <button
                                                className="btn btn-gradient-primary btn-sm"
                                                onClick={(e) =>
                                                    this.handleUploadImg(
                                                        e,
                                                        this.getIndex(this.props.keyID),
                                                        this.props.indexQues,
                                                        index,
                                                        inputField.file_attached,
                                                        inputField.img_file
                                                    )
                                                }
                                                disabled={
                                                    inputField.img_file === null ||
                                                        inputField.img_file === undefined
                                                        ? true
                                                        : false
                                                }
                                            >
                                                {inputField.file_attached === "Loading" ? (
                                                    <div>
                                                        <i className="mdi mdi-loading mdi-spin"></i> Upload
                                                        Gambar{" "}
                                                    </div>
                                                ) : (
                                                    "Upload Gambar"
                                                )}
                                            </button>
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                            <div className="col-md-2">
                                <IconButton
                                    onClick={() =>
                                        this.handleIconAddImg(
                                            this.getIndex(this.props.keyID),
                                            this.props.indexQues,
                                            index
                                        )
                                    }
                                >
                                    <ImageIcon />
                                </IconButton>
                                {index !== 0 && (
                                    <IconButton
                                        onClick={() =>
                                            this.props.deleteAnswerMultiple(
                                                this.getIndex(this.props.keyID),
                                                this.props.indexQues,
                                                index
                                            )
                                        }
                                    >
                                        <RemoveIcon />
                                    </IconButton>
                                )}
                                <IconButton
                                    onClick={() =>
                                        this.props.addAnswerMultiple(
                                            this.getIndex(this.props.keyID),
                                            this.props.indexQues,
                                            index
                                        )
                                    }
                                >
                                    <AddIcon />
                                </IconButton>
                            </div>
                        </div>
                    </div>

                ))}
            </div>
        )
    }

}

const mapStateToProps = (state) => {
    return {
        case_part: state.Exam.case_part,
        url_school: state.User.dataSchool.url_sekolah,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addAnswerMultiple: (index, indexQues, indexAns) =>
            dispatch(addAnswerMultiple(index, indexQues, indexAns)),
        deleteAnswerMultiple: (index, indexQues, indexAns) =>
            dispatch(deleteAnswerMultiple(index, indexQues, indexAns)),
        // inputAnswer: (indexPart, indexQues, IndexAns, text) =>
        // 	dispatch(inputAnswer(indexPart, indexQues, IndexAns, text)),
        inputAnswerMultiple: (indexPart, indexQues, IndexAns, text) =>
            dispatch(inputAnswerMultiple(indexPart, indexQues, IndexAns, text)),
        selectCorrectAnswerMultiple: (indPart, indQues, indChoice, keyIndexAnsPre) =>
            dispatch(
                selectCorrectAnswerMultiple(indPart, indQues, indChoice, keyIndexAnsPre)
            ),
        addToListImg: (imageName) => dispatch(addToListImg(imageName)),
        addImageAns: (index, indexQues, indChoice, image) =>
            dispatch(addImageAns(index, indexQues, indChoice, image)),
        removeImageAnswer: (index, indexQues, indChoice) =>
            dispatch(removeImageAnswer(index, indexQues, indChoice)),
        addImageFileAns: (index, indexQues, indChoice, imageFile) =>
            dispatch(addImageFileAns(index, indexQues, indChoice, imageFile)),

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TestChoicesMultiple);