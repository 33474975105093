import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import "../../../assets/css/Modal.css";
import Cell from "./CaseModal.jsx";
import Spinner from "../../../assets/Spinner.gif";
import Ball from "../../../assets/Ball.svg";
import axiosInstance from "../../../store/services/AxiosService";
import SweetAlert from "react-bootstrap-sweetalert";
import { connect } from "react-redux";
import { changeStatusEdit } from "../../../store/actions/index";
const delay = () => new Promise((res) => setTimeout(res, 2000));

export class AddModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			btnSelected: null,
			itemCase: "",
			fetching: false,
			caseSelected: "",
			alertSuccess: null,
			taskDone: false,
		};
	}

	componentDidUpdate = (prevProps) => {
		if (prevProps.idcourse !== this.props.idcourse) {
			this.fetchCase(this.props.idcourse, this.props.schoolId);
		}
	};

	fetchCase = (idCourse, idSchool) => {
		this.setState({ fetching: true });
		delay();
		axiosInstance
			.get(`/cases/course/${idCourse}/school/${idSchool}`)
			.then((res) => {
				this.setState({
					itemCase: res.data,
					fetching: false,
				});
			})
			.catch((error) => {
				this.setState({
					fetching: false,
				});
			});
	};

	onClickHandler(index) {
		this.setState({ btnSelected: index, caseSelected: index });
	}

	searchSoal = () => {
		var input, filter, table, tr, td, i, txtValue;
		input = document.getElementById("inputSearch");
		filter = input.value.toUpperCase();
		table = document.getElementById("tabelSoal");
		tr = table.getElementsByTagName("tr");
		for (i = 0; i < tr.length; i++) {
			td = tr[i].getElementsByTagName("td")[0];
			if (td) {
				txtValue = td.textContent || td.innerText;
				if (txtValue.toUpperCase().indexOf(filter) > -1) {
					tr[i].style.display = "";
				} else {
					tr[i].style.display = "none";
				}
			}
		}
	};

	addBtn = () => {
		let btn_class_color = this.state.btnCliked ? "btn btn-sm btn-success" : "btn btn-sm btn-primary";
		return btn_class_color;
	};

	btnCanceled = () => {
		this.setState({
			btnSelected: null,
		});
		this.props.onHide();
	};

	btnSave = (idschedule, idschool, idcase, isRemedial) => {
		axiosInstance
			.get(`/schedules/pair/case?schedule_id=${idschedule}&case_id=${idcase}&school_id=${idschool}&is_remedial=${isRemedial}`)
			.then((res) => {
				this.handleSuccessSubmit();
				console.log(res.data);
			})
			.catch((error) => {
				this.handleFailureSubmit(error.message);
			});
	};

	handleSuccessSubmit() {
		this.props.changestatusedit(true);
		const alertSuccess = () => (
			<SweetAlert success title="Berhasil!" onConfirm={() => this.hideAlertSuccess()} onCancel={() => this.setState({ alertSuccess: null })}>
				Soal berhasil dipilih
			</SweetAlert>
		);
		this.setState({
			alertSuccess: alertSuccess(),
		});
	}

	handleFailureSubmit(errorMessage) {
		const alertFailure = () => (
			<SweetAlert warning title="Proses Gagal!" onConfirm={() => this.hideAlertSuccess()} onCancel={() => this.setState({ alertSuccess: null })}>
				{errorMessage}
			</SweetAlert>
		);
		this.setState({
			alertSuccess: alertFailure(),
		});
	}

	hideAlertSuccess() {
		this.setState({
			alertSuccess: null,
			taskDone: true,
		});
        setTimeout(() => {
            window.location.reload();
        }, 2000);
		this.props.onHide();
	}

	render() {
		return (
			<Modal show={this.props.show} onHide={this.props.onHide} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
				<Modal.Header closeButton></Modal.Header>
				{this.state.fetching === true ? (
					<div className="text-center">
						<img src={Ball} alt=""></img>
					</div>
				) : (
					<Modal.Body>
						{this.state.itemCase.length > 0 ? (
							<div className="col-lg-12 grid-margin stretch-card ">
								<div className="card custom-card-shadow">
									<div className="card-body">
										<h2 className="card-title">Pilih Soal</h2>
										<p className="card-description">
											Klik soal yang akan digunakan, kemudian klik <b>Simpan</b>
										</p>
										<input type="text" className="form-control" id="inputSearch" onChange={this.searchSoal.bind(this)} placeholder="Cari Nama Soal disini..." title="Cari Nama Soal" />
										<div className="table-responsive ">
											<table id="tabelSoal" className="table table-hover text-center modal-list-case">
												<thead>
													<tr>
														<th scope="col">Nama Soal</th>
														<th scope="col">Tanggal Buat</th>
														<th scope="col">Nama Guru</th>
														<th scope="col">Aksi</th>
													</tr>
												</thead>
												<tbody className="tableBody">
													{this.state.itemCase.map((itemCases, index) => (
														<Cell case={itemCases} onClickHandler={() => this.onClickHandler(itemCases.id)} btnSelected={this.state.btnSelected} index={itemCases.id} key={itemCases.id} cases={this.props.cases} />
													))}
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						) : (
							<h4 className="text-center">Data Soal Tidak Ditemukan ...</h4>
						)}
					</Modal.Body>
				)}

				<Modal.Footer>
					<Button onClick={this.btnCanceled}>Batal</Button>
					<Button onClick={() => this.btnSave(this.props.idschedule, this.props.schoolId, this.state.caseSelected, this.props.isRemedial)}>Simpan</Button>
					{this.state.alertSuccess}
				</Modal.Footer>
			</Modal>
		);
	}
}
const mapStateToProps = (state) => {
	return {
		schoolId: state.User.dataSchool.value,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		changestatusedit: (status) => dispatch(changeStatusEdit(status)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AddModal);
