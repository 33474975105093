import React from "react";
import Question from "./Question.jsx";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
import { connect } from "react-redux";
import {
	setRandomQuestion,
	setRandomAnswer,
	setCaseSensitife,
	setNameCasePart,
	setScoreWeight,
} from "../../../store/actions/index";

class CasePart extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			value: 0,
		};
	}

	setRandomQ(key) {
		var Index = this.props.case_part
			.map(function (item) {
				return item.key;
			})
			.indexOf(key);
		this.props.setRandomQuestion(Index);
	}

	setRandomAns(key) {
		var Index = this.props.case_part
			.map(function (item) {
				return item.key;
			})
			.indexOf(key);
		this.props.setRandomAnswer(Index);
	}
	setSensitife(key) {
		var Index = this.props.case_part
			.map(function (item) {
				return item.key;
			})
			.indexOf(key);
		this.props.setCaseSensitife(Index);
	}

	setNameCasePart(key, event) {
		var Index = this.props.case_part
			.map(function (item) {
				return item.key;
			})
			.indexOf(key);
		let text = event.target.value;
		this.props.setNameCasePart(Index, text);
	}

	handleScoreWeightInput(key, value) {
		var Index = this.props.case_part
			.map(function (item) {
				return item.key;
			})
			.indexOf(key);
		this.props.setScoreWeight(Index, value);
	}

	getIndex = (key) => {
		var Index = this.props.case_part
			.map(function (item) {
				return item.key;
			})
			.indexOf(key);
		return Index;
	};

	render() {
		return (
			<div>
				<div className="mb-3">
					<h3 className="font-weight-bolder section-title d-inline">
						Bagian {this.props.noBab}.{" "}
						{this.props.typeCase === "uraian" ? "Uraian" : this.props.typeCase === "pilihan ganda" ? "Pilihan Ganda" : this.props.typeCase === "pilihan ganda kompleks" ? "Pilihan Ganda Kompleks" :this.props.typeCase === "pilihan ganda benar salah" ? "Pilihan Ganda Benar Salah" :this.props.typeCase === "isian singkat" ? "Isian Singkat" : "Menjodohkan"}
					</h3>
					<button
						type="submit"
						className="btn btn-gradient-danger btn-sm float-right"
						onClick={(e) => {
							this.props.handleDeletePartCase(this.props.keyID);
							e.preventDefault();
						}}
					>
						Hapus
					</button>
				</div>

				<div className="form-group row">
					<h3 className="col-sm-2 col-form-label h4 ">Nama Bab :</h3>
					<div className="col-sm-10">
						<textarea
							className="form-control darkLine"
							id="inputDesc"
							rows="3"
							placeholder="Tulis Nama Bab. Contoh: Bab Perkalian"
							value={this.props.case_part[this.getIndex(this.props.keyID)].name}
							onChange={(event) => {
								this.setNameCasePart(this.props.keyID, event);
							}}
							required
						></textarea>
					</div>
					<h3 className="col-sm-2 col-form-label h4 mt-1">Bobot Nilai :</h3>
					<div className="col-sm-10 row">
						<div className="col-sm-auto mt-4">
							<div style={{ minWidth: "300px", color: "black" }}>
								<InputRange
									maxValue={100}
									minValue={0}
									step={5}
									value={Number(
										this.props.case_part[this.getIndex(this.props.keyID)]
											.score_weight
									)}
									onChange={(value) =>
										this.handleScoreWeightInput(this.props.keyID, value)
									}
								/>
							</div>
						</div>
						<div className="col-sm-6 d-flex align-items-center">
							<i>
								*Total Nilai Maksimal adalah <b>100</b>
							</i>
						</div>
					</div>

					<h3 className="col-sm-2 col-form-label h4 ">PIlih Opsi:</h3>
					<div className="col-sm-10 form-inline mb-4">
						<div className="form-check  mr-5">
							<input
								type="checkbox"
								className="form-check-input"
                                id={`is-random-question-${this.props.keyID}`}
								onChange={() => {
									this.setRandomQ(this.props.keyID);
								}}
								checked={
									this.props.case_part[this.getIndex(this.props.keyID)]
										.random_question_order === true
										? true
										: false
								}
							/>
							<label className="h5" for ={`is-random-question-${this.props.keyID}`}><b>Acak Soal</b></label>
						</div>

						{(this.props.typeCase === "pilihan ganda benar salah" || this.props.typeCase === "menjodohkan" || this.props.typeCase === "pilihan ganda kompleks" || this.props.typeCase === "pilihan ganda")   && (
							<div className="form-check mx-sm-2">
								<input
									type="checkbox"
                                    id={`is-random-choice-${this.props.keyID}`}
									className="form-check-input"
									onChange={() => {
										this.setRandomAns(this.props.keyID);
									}}
									checked={
										this.props.case_part[this.getIndex(this.props.keyID)]
											.random_choice_order === true
											? true
											: false
									}
                                />
								<label className="h5" for ={`is-random-choice-${this.props.keyID}`}><b>Acak Jawaban</b></label>
							</div>
						)}
						{this.props.typeCase === "isian singkat" && (
							<div className="form-check mx-sm-2">
								<input
									type="checkbox"
                                    id={`is-case-sensitive-${this.props.keyID}`}
									className="form-check-input"
									onChange={() => {
										this.setSensitife(this.props.keyID);
									}}
									checked={
										this.props.case_part[this.getIndex(this.props.keyID)]
											.is_case_sensitive === true
											? true
											: false
									}
								/>
								<label for={`is-case-sensitive-${this.props.keyID}`}><b>Case Sensitive</b></label>
							</div>
						)}
					</div>
				</div>
				<Question typeCase={this.props.typeCase} keyID={this.props.keyID} stillEmpty={this.props.stillEmpty} />
			</div>
		);
	}
}

const mapToStateToProps = (state) => {
	return {
		case_part: state.Exam.case_part,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setRandomQuestion: (index) => dispatch(setRandomQuestion(index)),
		setRandomAnswer: (index) => dispatch(setRandomAnswer(index)),
		setCaseSensitife: (index) => dispatch(setCaseSensitife(index)),
		setNameCasePart: (index, text) => dispatch(setNameCasePart(index, text)),
		setScoreWeight: (index, text) => dispatch(setScoreWeight(index, text)),
	};
};

export default connect(mapToStateToProps, mapDispatchToProps)(CasePart);
