import React from "react";
import Footer from "../../footer";
import axiosInstance from "../../../store/services/AxiosService";
import Spinner from "../../../assets/Spinner.gif";
import Ball from "../../../assets/Ball.svg";
import TableListCase from "./TableListCase.jsx";
import { connect } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";

class ListCases extends React.Component {
	constructor(props) {
		super(props);
		this._isMounted = false;
		this.state = {
			listSoal: null,
			alertDelete: null,
			alertSuccess: null,
			errorServer: false,
		};
	}
	componentDidMount() {
		this._isMounted = true;
		if (this._isMounted) {
			this.fetchCase(this.props.userId, this.props.dataSchool.value);
		}
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	fetchCase = (idTeacher, idSchool) => {
		axiosInstance
			.get(`/cases/teacher/${idTeacher}/school/${idSchool}`)
			.then((res) => {
				this.setState({
					listSoal: res.data,
				});
			})
			.catch((error) => {
				console.log(error.message);
				if (error.response.status === 422) {
					this.setState({ listSoal: [], errorServer: true });
				}
			});
	};

	onClickDeleteCase(case_id, status) {
		if (status === true) {
			const getAlert = () => (
				<SweetAlert
					custom
					showCancel
					showCloseButton
					confirmBtnText="Ya"
					cancelBtnText="Tidak"
					confirmBtnBsStyle="primary"
					cancelBtnBsStyle="light"
					title="Apakah Anda Yakin?"
					onConfirm={() => this.deleteCase(case_id)}
					onCancel={() => this.setState({ alertDelete: null })}
				>
					Soal Anda akan dihapus
				</SweetAlert>
			);
			this.setState({
				alertDelete: getAlert(),
			});
		} else {
			this.handleFailureDelete("Soal Sudah Dikerjakan");
		}
	}

	deleteCase(case_id) {
		this.setState({ alertDelete: null });
		const url = `/cases/delete/${case_id}`;
		axiosInstance
			.delete(url)
			.then((response) => {
				this.handleSuccessDelete();
				console.log(response);
			})
			.catch((error) => {
				if (error.response.status !== 200) {
					this.handleFailureDelete("Soal Sudah Dikerjakan");
				}
			});
	}

	handleSuccessDelete() {
		const alertSuccess = () => (
			<SweetAlert
				success
				title="Berhasil!"
				onConfirm={() => this.setState({ alertSuccess: null })}
			>
				Soal berhasil dihapus!
			</SweetAlert>
		);
		this.setState({
			alertSuccess: alertSuccess(),
		});
		this.fetchCase(this.props.userId, this.props.dataSchool.value);
	}

	handleFailureDelete(errorMessage) {
		const alertFailure = () => (
			<SweetAlert
				warning
				title="Proses Gagal"
				onConfirm={() => this.setState({ alertSuccess: null })}
			>
				{errorMessage}
			</SweetAlert>
		);
		this.setState({
			alertSuccess: alertFailure(),
		});
	}

	render() {
		return (
			<div className="main-panel">
				<div className="content-wrapper">
					<div className="page-header">
						<h3 className="page-title">
							<span className="page-title-icon bg-gradient-primary text-white mr-2">
								<i className="mdi mdi-star-circle"></i>
							</span>
							List Soal
						</h3>
						{this.state.errorServer === true ? (
							<nav aria-label="breadcrumb">
								<ul class="breadcrumb">
									<li
										class="breadcrumb-item active"
										aria-current="page"
										style={{ color: "red" }}
									>
										<span></span>Data Tidak Ditemukan Pada Server
										<i class="mdi mdi-alert-circle-outline icon-sm text-primary align-middle"></i>
									</li>
								</ul>
							</nav>
						) : null}
					</div>

					{this.state.listSoal === null ? (
						<div className="text-center">
							<img src={Ball} alt=""></img>
						</div>
					) : (
						<div id="content-wrapper" className="d-flex flex-column">
							<div id="content">
								<div className="container-fluid">
									<div className="card shadow mb-4">
										<div className="card-header py-3">
											<h6 className="m-0 font-weight-bold text-primary">
												{this.props.dataSchool.degree}{" "}
												{this.props.dataSchool.label}
											</h6>
										</div>
										<div className="card-body">
											<div className="table-responsive">
												<TableListCase
													listSoal={this.state.listSoal} //------------------------ Buat jadi global variable, krn dari table ke halaman exam bkn child component
													onClickDeleteCase={this.onClickDeleteCase.bind(this)}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
							{this.state.alertDelete}
							{this.state.alertSuccess}
						</div>
					)}
				</div>
				<Footer />
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		userId: state.User.dataTeacher.user.data_employee.employee_id,
		dataSchool: state.User.dataSchool,
	};
};

export default connect(mapStateToProps, null)(ListCases);
