import ReactDOM from "react-dom";
import React from "react";
import { Dialog } from "evergreen-ui";

const UserConfirmation = (message, callback, setConfirmRemove) => {
	const container = document.createElement("div");
	container.setAttribute("custom-confirmation-navigation", "");
	document.body.appendChild(container);

	const handleConfirm = (callbackState) => {
		// remove detection cheating
		window.removeEventListener('blur', window.onBlurHandler);

		ReactDOM.unmountComponentAtNode(container);
		document.body.removeChild(container);
		setConfirmRemove(true);
		// /files/delete file_name:
		callback(callbackState);
	};
	const handleCancel = (callbackState) => {
		ReactDOM.unmountComponentAtNode(container);
		document.body.removeChild(container);
		setConfirmRemove(false);
		callback(callbackState);
	};

	const textObj = JSON.parse(message);
	ReactDOM.render(
		<Dialog cancelLabel={textObj.cancelText} confirmLabel={textObj.confirmText} isShown={true} onCancel={() => handleCancel(false)} onConfirm={() => handleConfirm(true)} title="Peringatan">
			{textObj.messageText}
		</Dialog>,
		container
	);
};

export default UserConfirmation;
