import React from "react";
import Dashboard from "./dashboard/Index.jsx";
import Penilaian from "./scoring/Index.jsx";
import Exam from "./make_exam/Index.jsx";
import Assessment from "./scoring/Assesment.jsx";
import ListStudent from "./scoring/ListStudent.jsx";
import EditCase from "./edit_case/Index.jsx";
import { BrowserRouter as Router, Route, Link, Switch, NavLink } from "react-router-dom";
import PersonalPicture from "../../assets/img/teacher.svg";
import { connect } from "react-redux";
import userLeaveConfirmation from "../userConfirm/userLeaveConfirmation";
import { deleteScheduleActive, makeNewCase, userLogout } from "../../store/actions/index";
import SweetAlert from "react-bootstrap-sweetalert";
import NotFound from "../userConfirm/NotFound";
import EditExam from "./edit_case/FormEditExam";
import axiosInstance from "../../store/services/AxiosService";

class IndexTeacher extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			alertLogout: null,
			showSidebar: true,
		};
	}

	handleWhenLogout(e) {
		e.preventDefault();
		const getAlert = () => <SweetAlert custom showCancel showCloseButton confirmBtnText="Ya" cancelBtnText="Tidak" confirmBtnBsStyle="primary" cancelBtnBsStyle="light" title="Apakah Anda Ingin Keluar?" onConfirm={() => this.confirmLogout()} onCancel={() => this.hideAlert()}></SweetAlert>;

		this.setState({
			alertLogout: getAlert(),
		});
	}

	hideAlert() {
		this.setState({
			alertLogout: null,
		});
	}

	confirmLogout() {
		this.setState({
			alertLogout: null,
		});
		this.handleDeleteActivity();
		this.props.userLogout();
		this.props.history.push("/");
	}

	sidebar() {
		this.setState({ showSidebar: !this.state.showSidebar });
		if (this.state.showSidebar === true) {
			document.body.classList.remove("sidebar-icon-only");
		} else {
			document.body.classList.add("sidebar-icon-only");
		}
	}
	handleDeleteActivity() {
		this.props.deleteScheduleActive();
		localStorage.removeItem("reduxState");
		localStorage.removeItem("caseWillEdit");
	}
	handleDeleteImage() {
		const url = "/files/delete";
		const dataToDel = { file_name: this.props.listImage };
		axiosInstance
			.post(url, dataToDel)
			.then((r) => {
				console.log(r);
				this.handleDeleteActivity();
			})
			.catch((error) => {
				alert(`Proses delete gagal => ${error.message}`);
			});
	}

	render() {
		const setConfirmRemove = (status) => {
			if (status === true) {
				if (this.props.doingMakeCase === true || this.props.doingEditCase === true) {
					if (this.props.statusSubmitted === false && this.props.listImage !== undefined) {
						if (this.props.listImage.length !== 0) {
							this.handleDeleteImage();
						} else {
							this.props.deleteScheduleActive();
							localStorage.removeItem("caseWillEdit");
						}
					} else {
						this.handleDeleteActivity();
					}
				}
			}
		};

		return (
			<Router
				getUserConfirmation={(message, callback) => {
					return userLeaveConfirmation(message, callback, setConfirmRemove);
				}}
				basename="/teacher"
			>
				<div className="container-scroller">
					<nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 pt-2 pb-1 fixed-top d-flex flex-row">
						<div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
							<Link to="/">
								<span className="navbar-brand brand-logo">
									<b>RuangKelas</b>
								</span>
								<span className="navbar-brand brand-logo-mini">
									<i className="mdi mdi-book-open-page-variant menu-icon"></i>
								</span>
							</Link>
						</div>
						<div className="navbar-menu-wrapper d-flex align-items-stretch">
							<button className="navbar-toggler navbar-toggler align-self-center" type="button" onClick={() => this.sidebar()}>
								<span className="mdi mdi-menu"></span>
							</button>

							<ul className="navbar-nav navbar-nav-right">
								<li className="nav-item mr-3 textGreeting">
									<h4 style={{ color: "#27367f" }}>
										Hai, Selamat Datang di {this.props.dataSchool.degree} {this.props.dataSchool.label}
									</h4>
								</li>
								<li className="nav-item nav-profile dropdown">
									<button className="nav-link dropdown-toggle btnInvisible" id="profileDropdown" data-toggle="dropdown" aria-expanded="false">
										<div className="nav-profile-img">
											<img src={PersonalPicture} alt="" />
											<span className="availability-status online"></span>
										</div>
										<div className="nav-profile-text">
											<p className="mb-1 text-black">{this.props.dataTeacher.user.data_employee.name}</p>
										</div>
									</button>
									<div className="dropdown-menu navbar-dropdown" aria-labelledby="profileDropdown">
										<div className="dropdown-divider"></div>
										<button className="dropdown-item btnInvisible" onClick={(e) => this.handleWhenLogout(e)}>
											<i className="mdi mdi-logout mr-2 text-primary"></i>
											Keluar
										</button>
									</div>
								</li>
							</ul>
							<button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" data-toggle="offcanvas" onClick={() => this.sidebar()}>
                                <span className="mdi mdi-menu"></span>
							</button>
						</div>
					</nav>
					<div className="container-fluid page-body-wrapper">
						<div className="sidebar pt-4 sidebar-offcanvas position-fixed" id="sidebar">
							<ul className="nav">
								<li className="nav-item active ">
									<NavLink className="nav-link" to="/">
										<span className="menu-title">Dashboard</span>
										<i className="mdi mdi-home menu-icon"></i>
									</NavLink>
								</li>

								<li className="nav-item active ">
									<NavLink className="nav-link" to="/assessment">
										<span className="menu-title">Penilaian</span>
										<i className="mdi mdi-star-circle menu-icon"></i>
									</NavLink>
								</li>
								<li className="nav-item active">
									<NavLink className="nav-link" to="/list-soal">
										<span className="menu-title">List Soal</span>
										<i className="mdi mdi-clipboard-outline menu-icon"></i>
									</NavLink>
								</li>
								{this.props.doingMakeCase === false ? (
									<li className="nav-item active">
										<NavLink className="nav-link" to="/exam/newcase" disabled onClick={() => this.props.makeNewCase(this.props.dataTeacher.user.data_employee.employee_id, this.props.dataSchool.value)}>
											<span className="menu-title">Buat Soal Baru</span>
											<i className="mdi mdi-note-plus menu-icon"></i>
										</NavLink>
									</li>
								) : null}

								<li className="nav-item sidebar-actions">
									<span className="nav-link">
										<div className="border-bottom">
											<h6 className="menu-title font-weight-normal mb-3">Buat Soal Baru</h6>
										</div>
										<Link style={{ textDecoration: 'none' }} to="/exam/newcase">
											<button
												className="btn btn-block btn-lg btn-gradient-primary mt-4"
												onClick={() => this.props.makeNewCase(this.props.dataTeacher.user.data_employee.employee_id, this.props.dataSchool.value)} // ------------------ Ini ID Sekolah
												disabled={this.props.doingMakeCase}
											>
												+ Buat Soal
											</button>
										</Link>
									</span>
								</li>
							</ul>
						</div>
						{this.state.alertLogout}
						<div className="dashboard-panel">
							<Switch>
								<Route exact path="/" component={Dashboard} />
								<Route exact path="/assessment" component={Penilaian} />
								<Route exact path="/assessment/:course/:schedule" component={ListStudent} />
								<Route exact path="/assessment/:course/:schedule/assess/:student_id" component={Assessment} />
								<Route path="/exam/:teacher/:class/:course/:schedule" component={Exam} />
								<Route exact path="/exam/newcase" component={Exam} />
								<Route exact path="/list-soal" component={EditCase} />
								<Route exact path="/edit-exam/:course/:case" component={EditExam} />
								<Route path="*" component={NotFound} />
							</Switch>
						</div>
					</div>
				</div>
			</Router>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		doingMakeCase: state.Exam.doingMakeCase,
		dataTeacher: state.User.dataTeacher,
		dataSchool: state.User.dataSchool,
		statusSubmitted: state.Exam.statusSubmitted,
		casePart: state.Exam.case_part,
		listImage: state.Exam.listImg,
		doingEditCase: state.Exam.doingEditCase,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		deleteScheduleActive: () => {
			dispatch(deleteScheduleActive());
		},
		makeNewCase: (userId, schoolId) => {
			dispatch(makeNewCase(userId, schoolId));
		},
		userLogout: () => dispatch(userLogout()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(IndexTeacher);
