import React, { Component } from "react";

export default class TableScore extends Component {
	componentDidMount() {
		const script = document.createElement("script");
		script.src = "assets/js/dataTable-content.js";
		script.async = true;
		document.body.appendChild(script);
	}

	groupingScorePilgan(student) {
		let score = 0;
		Object.values(student.student_answer.case_parts).map((element) => {
			if (element.type_name === "pilihan ganda") {
				score = score + element.part_scores;
			}
			return score;
		});
		return score;
	}
	groupingScorePilganTrueFalse(student) {
		let score = 0;
		Object.values(student.student_answer.case_parts).map((element) => {
			if (element.type_name === "pilihan ganda benar salah") {
				score = score + element.part_scores;
			}
			return score;
		});
		return score;
	}
	groupingScoreEassayShort(student) {
		let score = 0;
		Object.values(student.student_answer.case_parts).map((element) => {
			if (element.type_name === "isian singkat") {
				score = score + element.part_scores;
			}
			return score;
		});
		return score;
	}

	// add value score by type name
	groupingScorePilganKompleks(student) {
		let score = 0;
		Object.values(student.student_answer.case_parts).map((element) => {
			if (element.type_name === "pilihan ganda kompleks") {
				score = score + element.part_scores;
			}
			return score;
		});
		return score;
	}

	groupingScoreEssay(student) {
		let score = 0;
		Object.values(student.student_answer.case_parts).map((element) => {
			if (element.type_name === "uraian") {
				score = score + element.part_scores;
			}
			return score;
		});
		return score;
	}
	groupingScoreMenjodohkan(student) {
		let score = 0;
		Object.values(student.student_answer.case_parts).map((element) => {
			if (element.type_name === "menjodohkan") {
				score = score + element.part_scores;
			}
			return score;
		});
		return score;
	}
	render() {
		console.log('this.props.listScore',this.props.listScore);
		return (
			<table className="table table-bordered text-center nowrap table-hover" id="dt_listScoreStudent" style={{ width: "100%" }}>
				<thead>
					<tr>
						<td className="th-table">Mata Pelajaran</td>
						<td className="th-table">Nama Soal</td>
						<td className="th-table">Tipe</td>
						<td className="th-table">Pilgan</td>
						<td className="th-table">Pilgan B/S</td>
						<td className="th-table">Pilihan Kompleks</td>
						<td className="th-table">Uraian</td>
						<td className="th-table">Isian Singkat</td>
						<td className="th-table">Menjodohkan</td>
						<td className="th-table">Total Nilai</td>
					</tr>
				</thead>
				<tbody>
					{Object.values(this.props.listScore).map((element) => (
						<tr key={element.api_schedule_id}>
							<td className="td-table">{element.student_answer.courses.course_name}</td>
							<td className="td-table">{element.student_answer.name}</td>
							{/* <td>
								<label className="badge badge-gradient-danger">
									{element.schedules.exam_types.exam_type}</label>
							</td> */}
							<td className="td-table">
								<label className="badge badge-gradient-danger">
									{element.schedules === null ? "" : element.schedules.exam_types.exam_type}</label>
							</td>
							<td className="td-table">{parseFloat(this.groupingScorePilgan(element)).toFixed(2)}</td>
							<td className="td-table">{parseFloat(this.groupingScorePilganTrueFalse(element)).toFixed(2)}</td>
							<td className="td-table">{parseFloat(this.groupingScorePilganKompleks(element)).toFixed(2)}</td>
							<td className="td-table">{parseFloat(this.groupingScoreEssay(element)).toFixed(2)}</td>
							<td className="td-table">{parseFloat(this.groupingScoreEassayShort(element)).toFixed(2)}</td>
							<td className="td-table">{parseFloat(this.groupingScoreMenjodohkan(element)).toFixed(2)}</td>
							<td className="td-table">{parseFloat(element.student_answer.student_total_scores).toFixed(2)}</td>
						</tr>
					))}
				</tbody>
			</table>
		);
	}
}
