import * as actionType from "../actions/actionTypes";
// pertanyaan
import update from "react-addons-update";

const initialState = {
  makeCaseBySchedule: false,
  doingMakeCase: false,
};

const ExamReducers = (state = initialState, action) => {
  switch (action.type) {
    case actionType.TEACHER_MAKE_CASE_BY_SCHEDULE:
      return {
        ...state,
        case_part: [],
        listImg: [],
        doingMakeCase: true,
        makeCaseBySchedule: true,
        statusSubmitted: false,
        scheduleSelected: {
          courseName: action.courseName,
          typeCase: action.typeCase,
          date: action.date,
          time: action.time,
          className: action.className,
          is_remedial: action.isRemedial,
        },
        schedules: {
          //for post request
          exam_schedule_id: action.scheduleId,
          school_id: action.schoolId,
          is_remedial: action.isRemedial,
        },
        cases: {
          name: "",
          school_id: action.schoolId,
          course_id: action.courseId,
          teacher_id: action.userId,
        },
        currentItemCase: {
          id: null,
          type_name: "",
          key: "",
          case_id: action.caseId,
          name: "",
          score_weight: "",
          random_question_order: false,
          random_choice_order: false,
          is_case_sensitive: false,
          case_questions: [
            {
              id: null,
              question: "",
              key: Date.now(),
            },
          ],
        },
      };

    case actionType.TEACHER_REMOVE_SCHEDULE_SELECTED:
      return {
        makeCaseBySchedule: false,
        doingMakeCase: false,
        doingEditCase: false,
      };

    case actionType.SET_CASE_NAME:
      return {
        ...state,
        cases: {
          ...state.cases,
          name: action.text,
        },
        currentItemCase: {
          ...state.currentItemCase,
        },
      };

    case actionType.CHOOSE_CASE_PART_PILGAN:
      return {
        ...state,
        currentItemCase: {
          ...state.currentItemCase,
          type_name: action.currentItemCase.type_name,
          key: action.currentItemCase.key,
          case_id: action.currentItemCase.case_id,
          case_questions: [
            {
              id: null,
              ...state.currentItemCase.case_questions[0],
              status_img: 0,
              file_attached: null,
              img_file: null,
              question: null,
              case_choices:
                action.currentItemCase.type_name === "pilihan ganda benar salah"
                  ? [
                      {
                        file_attached: null,
                        status_img: 0,
                        key: Date.now(),
                        id: null,
                        case_question_id: 0,
                        value: null,
                        is_correct: 0,
                        img_file: null,
                      },
                      {
                        file_attached: null,
                        status_img: 0,
                        key: Date.now(),
                        id: null,
                        case_question_id: 0,
                        value: null,
                        is_correct: 0,
                        img_file: null,
                      },
                    ]
                  : [
                      {
                        file_attached: null,
                        status_img: 0,
                        key: Date.now(),
                        id: null,
                        case_question_id: 0,
                        value: null,
                        is_correct: 0,
                        img_file: null,
                      },
                    ],
            },
          ],
        },
      };
    case actionType.CHOOSE_CASE_PART_MENJODOHKAN:
      return {
        ...state,
        currentItemCase: {
          ...state.currentItemCase,
          type_name: action.currentItemCase.type_name,
          key: action.currentItemCase.key,
          case_id: action.currentItemCase.case_id,
          case_questions: [
            {
              id: null,
              ...state.currentItemCase.case_questions[0],
              status_img: 0,
              file_attached: null,
              img_file: null,
              question: null,
              case_choices: [
                {
                  id: null,
                  case_question_id: 0,
                  is_correct: 1,
                  value: null,
                  file_attached: null,
                  status_img: 0,
                  key: Date.now(),
                  img_file: null,
                  order: Date.now(),
                },
              ],
              case_choices_answers: [
                {
                  id: null,
                  case_question_id: 0,
                  case_choice_id: Date.now(),
                  is_correct: 1,
                  value: null,
                  file_attached: null,
                  status_img: 0,
                  key: Date.now(),
                  img_file: null,
                  order: Date.now(),
                },
              ],
            },
          ],
        },
      };

    case actionType.CHOOSE_CASE_PART_ESSAY:
      return {
        ...state,
        currentItemCase: {
          ...state.currentItemCase,
          case_id: action.currentItemCase.case_id,
          type_name: action.currentItemCase.type_name,
          key: action.currentItemCase.key,
          case_questions: [
            {
              id: null,
              key: Date.now(),
              ...state.currentItemCase.case_questions[0],
              status_img: 0,
              case_choices: [],
              file_attached: null,
              img_file: null,
              question: null,
            },
          ],
        },
      };

    case actionType.ADD_CASE_PART:
      return {
        ...state,
        case_part: [...state.case_part, action.casePart],
        currentItemCase: {
          ...state.currentItemCase,
          type_name: action.currentItemCase.type_name,
          key: action.currentItemCase.key,
          case_questions: [
            {
              ...state.case_questions,
              id: null,
              key: Date.now(),
              case_choices: [],
              file_attached: null,
            },
          ],
        },
      };

    case actionType.DELETE_CASE_PART:
      return {
        ...state,
        case_part: [...state.case_part.slice(0, action.indexDeleted), ...state.case_part.slice(action.indexDeleted + 1)],
      };

    case actionType.SET_RANDOM_QUESTION:
      return update(state, {
        case_part: {
          [action.keyIndex]: {
            random_question_order: {
              $set: !state.case_part[action.keyIndex].random_question_order,
            },
          },
        },
      });

    case actionType.SET_RANDOM_ANSWER:
      return update(state, {
        case_part: {
          [action.keyIndex]: {
            random_choice_order: {
              $set: !state.case_part[action.keyIndex].random_choice_order,
            },
          },
        },
      });
    case actionType.SET_CASE_SENSITIVE_ANSWER:
      return update(state, {
        case_part: {
          [action.keyIndex]: {
            is_case_sensitive: {
              $set: !state.case_part[action.keyIndex].is_case_sensitive,
            },
          },
        },
      });

    case actionType.SET_SCORE_WEIGHT:
      return update(state, {
        case_part: {
          [action.keyIndex]: {
            score_weight: {$set: action.text},
          },
        },
      });

    case actionType.SET_CASE_PART_NAME:
      return update(state, {
        case_part: {
          [action.keyIndex]: {
            name: {$set: action.text},
          },
        },
      });

    case actionType.ADD_QUESTION:
      return update(state, {
        case_part: {
          [action.keyIndexPart]: {
            case_questions: {
              id: {$set: null},
              $splice: [[action.keyIndexQues, 0, action.case_questions]],
            },
          },
        },
      });
    case actionType.ADD_QUESTION_MENJODOHKAN:
      return update(state, {
        case_part: {
          [action.keyIndexPart]: {
            case_questions: {
              id: {$set: null},
              $splice: [[action.keyIndexQues, 0, action.case_questions]],
            },
          },
        },
      });

    case actionType.ADD_QUESTION_TRUE_FALSE:
      return update(state, {
        case_part: {
          [action.keyIndexPart]: {
            case_questions: {
              id: {$set: null},
              $splice: [[action.keyIndexQues, 0, action.case_questions]],
            },
          },
        },
      });

    case actionType.DELETE_QUESTION:
      return update(state, {
        case_part: {
          [action.keyIndexPart]: {
            case_questions: {$splice: [[action.keyIndexQues, 1]]},
          },
        },
      });

    case actionType.SET_QUESTION:
      return update(state, {
        case_part: {
          [action.keyIndexPart]: {
            case_questions: {
              [action.keyIndexQues]: {
                question: {$set: action.text},
              },
            },
          },
        },
      });

    case actionType.SET_VIDEO_EMBED:
      return update(state, {
        case_part: {
          [action.keyIndexPart]: {
            case_questions: {
              [action.keyIndexQues]: {
                video_embed: {$set: action.text},
              },
            },
          },
        },
      });

    case actionType.ADD_ANSWEAR:
      return update(state, {
        case_part: {
          [action.keyIndexPart]: {
            case_questions: {
              [action.keyIndexQues]: {
                case_choices: {
                  $splice: [[action.keyIndexAns, 0, action.case_choices]],
                },
              },
            },
          },
        },
      });
    case actionType.ADD_CHOICE_MENJODOHKAN:
      return update(state, {
        case_part: {
          [action.keyIndexPart]: {
            case_questions: {
              [action.keyIndexQues]: {
                case_choices: {
                  $splice: [[action.keyIndexAns, 0, action.case_choices]],
                },
                case_choices_answers: {
                  $splice: [[action.keyIndexAns, 0, action.case_choices_answers]],
                },
              },
            },
          },
        },
      });
    case actionType.ADD_CHOICE_ANSWER_MENJODOHKAN:
      return update(state, {
        case_part: {
          [action.keyIndexPart]: {
            case_questions: {
              [action.keyIndexQues]: {
                case_choices_answers: {
                  $splice: [[action.keyIndexAns, 0, action.case_choices_answers]],
                },
              },
            },
          },
        },
      });
    case actionType.ADD_ANSWEAR_MULTIPLE:
      return update(state, {
        case_part: {
          [action.keyIndexPart]: {
            case_questions: {
              [action.keyIndexQues]: {
                case_choices: {
                  $splice: [[action.keyIndexAns, 0, action.case_choices]],
                },
              },
            },
          },
        },
      });

    case actionType.DELETE_ANSWEAR:
      return update(state, {
        case_part: {
          [action.keyIndexPart]: {
            case_questions: {
              [action.keyIndexQues]: {
                case_choices: {$splice: [[action.keyIndexAns, 1]]},
              },
            },
          },
        },
      });
    case actionType.DELETE_ANSWEAR_MENJODOHKAN:
      return update(state, {
        case_part: {
          [action.keyIndexPart]: {
            case_questions: {
              [action.keyIndexQues]: {
                case_choices: {$set: action.newcase_choices},
                case_choices_answers: {$set: action.newcase_choices_answers},
              },
            },
          },
        },
      });
    case actionType.DELETE_CASE_ANSWEAR_MENJODOHKAN:
      return update(state, {
        case_part: {
          [action.keyIndexPart]: {
            case_questions: {
              [action.keyIndexQues]: {
                case_choices: {$set: action.newcase_case_choices},
                case_choices_answers: {$set: action.newcase_choices_answers},
              },
            },
          },
        },
      });
    case actionType.DELETE_ANSWEAR_MULTIPLE:
      return update(state, {
        case_part: {
          [action.keyIndexPart]: {
            case_questions: {
              [action.keyIndexQues]: {
                case_choices: {$splice: [[action.keyIndexAns, 1]]},
              },
            },
          },
        },
      });

    case actionType.SET_ANSWEAR:
      return update(state, {
        case_part: {
          [action.keyIndexPart]: {
            case_questions: {
              [action.keyIndexQues]: {
                case_choices: {
                  [action.keyIndexAns]: {
                    value: {$set: action.text},
                  },
                },
              },
            },
          },
        },
      });
    case actionType.SET_ANSWEAR_CASE_CHOICES:
      return update(state, {
        case_part: {
          [action.keyIndexPart]: {
            case_questions: {
              [action.keyIndexQues]: {
                case_choices: {$set: action.texcase_choices_answers},
              },
            },
          },
        },
      });
    case actionType.SET_ANSWEAR_CHOICES:
      return update(state, {
        case_part: {
          [action.keyIndexPart]: {
            case_questions: {
              [action.keyIndexQues]: {
                case_choices_answers: {$set: action.texcase_choices_answers},
              },
            },
          },
        },
      });
    // case actionType.SET_ANSWEAR_TRUE_FALSE:
    //   // 1. cek data dari case_questions
    //   // 2. simpan case choices ke dalam variable
    //   // 2. ubah hanya case_coices [] berdasarkan variable
    //   console.log(
    //     "state.case_part[action.keyIndexPart].case_questions[ction.keyIndexQues]"
    //   );
    //   console.log(state.case_part[action.keyIndexPart].case_questions);

    //   let a = state.case_part[action.keyIndexPart].case_questions;
    //   let case_choices = [];

    //   let saveData = [];

    //   a.forEach((data, i) => {
    //     if (i === 0) {
    //       data.case_choices.forEach((d) => {
    //         case_choices.push(d.value);
    //       });
    //     } else {
    //       data.case_choices.forEach((d, x) => {
    //         case_choices.forEach((c, y) => {
    //           if (x === y) {
    //             d.value = c;
    //           }
    //         });
    //       });
    //     }
    //     saveData.push(data);
    //   });

    //   console.log("a");
    //   console.log(saveData);

    //   return update(state, {
    //     case_part: {
    //       [action.keyIndexPart]: {
    //         case_questions: { $set: saveData },
    //       },
    //     },
    //   });

    // case actionType.SET_ANSWEAR_TRUE_FALSE:
    //   return update(state, {
    //     case_part: {
    //       [action.keyIndexPart]: {
    //         case_questions: {
    //           // here change value by
    //           // console.log(action.keyIndexQues)
    //           [action.keyIndexQues]: {
    //             case_choices: {
    //               [action.keyIndexAns]: {
    //                 value: { $set: action.text },
    //               },
    //             },
    //           },
    //         },
    //       },
    //     },
    //   });
    case actionType.SET_ANSWEAR_TRUE_FALSE:
      return update(state, {
        case_part: {
          [action.keyIndexPart]: {
            case_questions: {$set: action.case_questionsss},
          },
        },
      });

    case actionType.SET_ANSWEAR_SHORT:
      return update(state, {
        case_part: {
          [action.keyIndexPart]: {
            case_questions: {
              [action.keyIndexQues]: {
                case_choices: {
                  [action.keyIndexAns]: {
                    value: {$set: action.text},
                  },
                },
              },
            },
          },
        },
      });
    case actionType.SET_ANSWEAR_MULTIPLE:
      return update(state, {
        case_part: {
          [action.keyIndexPart]: {
            case_questions: {
              [action.keyIndexQues]: {
                case_choices: {
                  [action.keyIndexAns]: {
                    value: {$set: action.text},
                  },
                },
              },
            },
          },
        },
      });

    case actionType.SELECT_CORRECT_ANSWER: // pertanyaan
      if (action.keyIndexAnsPre < 0)
        //Bila belum ada jawaban
        return update(state, {
          case_part: {
            [action.keyIndexPart]: {
              case_questions: {
                [action.keyIndexQues]: {
                  case_choices: {
                    [action.keyIndexAns]: {
                      is_correct: {$set: 1},
                    },
                  },
                },
              },
            },
          },
        });
      else
        return update(state, {
          case_part: {
            [action.keyIndexPart]: {
              case_questions: {
                [action.keyIndexQues]: {
                  case_choices: {
                    [action.keyIndexAns]: {
                      is_correct: {$set: 1},
                    },
                    [action.keyIndexAnsPre]: {
                      is_correct: {$set: 0},
                    },
                  },
                },
              },
            },
          },
        });
    case actionType.SELECT_CORRECT_ANSWER_TRUE_FALSE:
      // pertanyaan
      if (action.keyIndexAnsPre < 0)
        return update(state, {
          case_part: {
            [action.keyIndexPart]: {
              case_questions: {
                [action.keyIndexQues]: {
                  case_choices: {
                    [action.keyIndexAns]: {
                      is_correct: {$set: 1},
                    },
                  },
                },
              },
            },
          },
        });
      else
        return update(state, {
          case_part: {
            [action.keyIndexPart]: {
              case_questions: {
                [action.keyIndexQues]: {
                  case_choices: {
                    [action.keyIndexAns]: {
                      is_correct: {$set: 1},
                    },
                    [action.keyIndexAnsPre]: {
                      is_correct: {$set: 0},
                    },
                  },
                },
              },
            },
          },
        });
    case actionType.SELECT_CORRECT_ANSWER_MULTIPLE:
      // pertanyaan
      return update(state, {
        case_part: {
          [action.keyIndexPart]: {
            case_questions: {
              [action.keyIndexQues]: {
                case_choices: {
                  [action.keyIndexAns]: {
                    is_correct: state.case_part[action.keyIndexPart].case_questions[action.keyIndexQues].case_choices[action.keyIndexAns].is_correct === 1 ? {$set: 0} : {$set: 1},
                  },
                },
              },
            },
          },
        },
      });

    case actionType.ADD_TO_LIST_IMG:
      return update(state, {
        listImg: {$push: [action.imageName]},
      });

    case actionType.ADD_IMAGE_QUESTION:
      return update(state, {
        case_part: {
          [action.indexPart]: {
            case_questions: {
              [action.indexQues]: {
                status_img: {$set: 1},
                file_attached: {$set: action.image},
              },
            },
          },
        },
      });

    case actionType.ADD_IMAGE_FILE_QUESTION:
      return update(state, {
        case_part: {
          [action.indexPart]: {
            case_questions: {
              [action.indexQues]: {
                img_file: {$set: action.imageFile},
              },
            },
          },
        },
      });

    case actionType.REMOVE_IMAGE:
      return update(state, {
        case_part: {
          [action.indexPart]: {
            case_questions: {
              [action.indexQues]: {
                file_attached: {$set: null},
                status_img: {$set: 0},
              },
            },
          },
        },
      });

    case actionType.ADD_IMAGE_ANSWER:
      return update(state, {
        case_part: {
          [action.indexPart]: {
            case_questions: {
              [action.indexQues]: {
                case_choices: {
                  [action.indexAns]: {
                    status_img: {$set: 1},
                    file_attached: {$set: action.image},
                  },
                },
              },
            },
          },
        },
      });

    case actionType.ADD_IMAGE_FILE_ANSWER:
      return update(state, {
        case_part: {
          [action.indexPart]: {
            case_questions: {
              [action.indexQues]: {
                case_choices: {
                  [action.indexAns]: {
                    img_file: {$set: action.imageFile},
                  },
                },
              },
            },
          },
        },
      });

    case actionType.REMOVE_IMAGE_ANSWER:
      return update(state, {
        case_part: {
          [action.indexPart]: {
            case_questions: {
              [action.indexQues]: {
                case_choices: {
                  [action.indexAns]: {
                    file_attached: {$set: null},
                    status_img: {$set: 0},
                  },
                },
              },
            },
          },
        },
      });

    case actionType.EDIT_ANSWER_CORRECT: // ini untuk edit jawaban benar editCase
      if (action.indexAnswerOld < 0)
        return update(state, {
          case_part: {
            [action.indexPart]: {
              case_questions: {
                [action.indexQuestion]: {
                  case_choices: {
                    [action.indexAnswer]: {
                      is_correct: {$set: 1},
                    },
                  },
                },
              },
            },
          },
        });
      else
        return update(state, {
          case_part: {
            [action.indexPart]: {
              case_questions: {
                [action.indexQuestion]: {
                  case_choices: {
                    [action.indexAnswer]: {
                      is_correct: {$set: 1},
                    },
                    [action.indexAnswerOld]: {
                      is_correct: {$set: 0},
                    },
                  },
                },
              },
            },
          },
        });
    case actionType.EDIT_ANSWER_CORRECT_TRUE_FALSE: // ini untuk edit jawaban benar editCase
      if (action.indexAnswerOld < 0)
        return update(state, {
          case_part: {
            [action.indexPart]: {
              case_questions: {
                [action.indexQuestion]: {
                  case_choices: {
                    [action.indexAnswer]: {
                      is_correct: {$set: 1},
                    },
                  },
                },
              },
            },
          },
        });
      else
        return update(state, {
          case_part: {
            [action.indexPart]: {
              case_questions: {
                [action.indexQuestion]: {
                  case_choices: {
                    [action.indexAnswer]: {
                      is_correct: {$set: 1},
                    },
                    [action.indexAnswerOld]: {
                      is_correct: {$set: 0},
                    },
                  },
                },
              },
            },
          },
        });
    case actionType.EDIT_ANSWER_CORRECT_MULTIPLE: // ini untuk edit jawaban benar editCase
      return update(state, {
        case_part: {
          [action.indexPart]: {
            case_questions: {
              [action.indexQuestion]: {
                case_choices: {
                  [action.indexAnswer]: {
                    is_correct: state.case_part[action.indexPart].case_questions[action.indexQuestion].case_choices[action.indexAnswer].is_correct === 1 ? {$set: 0} : {$set: 1},
                  },
                },
              },
            },
          },
        },
      });

    case actionType.EDIT_RANDOM_QUESTION:
      return update(state, {
        case_part: {
          [action.indexPart]: {
            random_question_order: {$set: action.newValue},
          },
        },
      });
    case actionType.EDIT_CASE_SENSITIFE:
      return update(state, {
        case_part: {
          [action.indexPart]: {
            is_case_sensitive: {$set: action.newValue},
          },
        },
      });

    case actionType.EDIT_RANDOM_ANSWER:
      return update(state, {
        case_part: {
          [action.indexPart]: {
            random_choice_order: {$set: action.newValue},
          },
        },
      });

    case actionType.TEACHER_MAKE_NEW_CASE:
      return {
        ...state,
        case_part: [],
        listImg: [],
        scheduleSelected: null,
        makeCaseBySchedule: false,
        schedules: null,
        doingMakeCase: true,
        statusSubmitted: false,
        cases: {
          name: "",
          school_id: action.schoolId,
          teacher_id: action.userId,
        },
        currentItemCase: {
          id: null,
          type_name: "",
          key: "",
          case_id: "",
          name: "",
          score_weight: "",
          random_question_order: false,
          random_choice_order: false,
          is_case_sensitive: false,
          case_questions: [
            {
              id: null,
              question: "",
              key: Date.now(),
            },
          ],
        },
      };

    case actionType.REQUEST_SUBMIT_EXAM_SUCCESS:
      return {
        ...state,
        statusSubmitted: action.payload,
      };

    case actionType.SELECT_COURSE_NEW_CASE:
      return {
        ...state,
        cases: {
          ...state.cases,
          course_id: action.courseSelected,
        },
      };
    // ------------------------------------------------------------------- EDIT
    case actionType.LOAD_CASE_EDIT:
      return {
        ...state,
        listImg: [],
        cases: action.cases,
        case_part: action.case_parts,
        currentItemCase: {
          id: null,
          type_name: "",
          key: "",
          case_id: null,
          name: "",
          score_weight: "",
          random_question_order: false,
          random_choice_order: false,
          is_case_sensitive: false,
          case_questions: [
            {
              id: null,
              question: "",
              key: Date.now(),
            },
          ],
        },
      };

    default:
      return state;

    case actionType.TEACHER_EDIT_CASES:
      return {
        ...state,
        doingEditCase: action.status,
        statusSubmitted: false,
      };

    case actionType.EDIT_CASE_FINISHED:
      return {
        // ...state,
        statusSubmitted: action.status,
        doingEditCase: false,
        makeCaseBySchedule: false,
        doingMakeCase: false,
      };
  }
};
export default ExamReducers;
