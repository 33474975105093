import React, { Component } from "react";
import "../../assets/css/login.css";
import { connect } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import LoginTeacher from "./LoginTeacher.jsx";
import LoginStudent from "./LoginStudent.jsx";
import * as landingAnimation from '../../assets/lottie/landing.json'
import lottie from 'lottie-web'

export class index extends Component {
	handleSubmitTeacher = (e) => {
		e.preventDefault();
		if (this.props.user.role === "TEACHER" && this.props.user.status === true) {
			this.props.history.push("/teacher");
		} else {
			this.props.history.push("/login-teacher");
			// this.props.history.push("/teacher");
		}
	};
	handleSubmitStudent = (e) => {
		e.preventDefault();
		if (this.props.user.role === "STUDENT" && this.props.user.status === true) {
			this.props.history.push("/student");
		} else {
			this.props.history.push("/login-student");
		}
	};
	componentDidMount() {
        lottie.loadAnimation({
            container:  document.getElementById('animation'), // the dom element that will contain the animation
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: landingAnimation.default,
        })
    }
	render() {
		return (
			<Router>
				<div className="container">
					<div className="content-wrapper d-flex align-items-center bg-white">
						<div className="row flex-grow justify-content-center">
							<div className="">
                                <div id="animation"></div>
							</div>

							<div className="col-lg-auto mx-auto">
								<div className="text-center">
									<div className="title-login p-3">
										<h4>MyHomeSchool - Exam</h4>
									</div>
									<div className="row">
										<div className="col">
											<button className="btn btn-gradient-primary choose-role" onClick={this.handleSubmitTeacher}>
												Masuk sebagai Guru
											</button>
										</div>
										<div className="col">
											<button className="btn btn-gradient-primary choose-role" onClick={this.handleSubmitStudent}>
												Masuk sebagai Siswa
											</button>
										</div>
									</div>
								</div>

								<Switch>
									<Route exact path="/login-teacher" component={LoginTeacher} />
									{/* <Route exact path="/teacher" component={LoginTeacher} /> */}
									<Route exact path="/login-student" component={LoginStudent} />
								</Switch>
							</div>
						</div>
					</div>
				</div>
			</Router>
		);
	}
}

const mapStateToProps = (state) => ({
	user: state.User,
	isTeacher: state.User.dataTeacher,
	isStudent: state.User.dataStudent,
});

export default connect(mapStateToProps, null)(index);
